import {
  ComponentType,
  useCallback,
  MouseEvent,
  PropsWithChildren,
} from 'react';

import { PointPinProps } from '@InsightViewer/components/PointPin';

import IssueIconBase, {
  ISSUE_ICON_WIDTH,
} from 'src/components/icons/IssueIconBase';

const IssuePointPin: ComponentType<PropsWithChildren<PointPinProps>> = ({
  x,
  y,
  label,
  onEnter,
  onLeave,
  onRemove,
  ...gProps
}) => {
  const onClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      event.preventDefault();

      onRemove();
    },
    [onRemove]
  );

  return (
    <IssueIconBase
      label={label || ''}
      {...gProps}
      transform={`translate(${x - ISSUE_ICON_WIDTH / 2} ${
        y - ISSUE_ICON_WIDTH / 2
      })`}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
      onClick={onClick}
    />
  );
};

export default IssuePointPin;
