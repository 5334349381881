import { useRecoilValue } from 'recoil';

import { jobState } from 'src/states/job';

const NextJobPreloader = (): null => {
  useRecoilValue(jobState.next);
  return null;
};

export default NextJobPreloader;
