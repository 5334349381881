import { Children, PropsWithChildren, useMemo, useState } from 'react';

import { useDrop } from 'react-dnd';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';

import ExpandButton from 'src/components/ExpandButton';
import VisibilityButton from 'src/components/VisibilityButton';
import useAlert from 'src/hooks/useAlert';
import {
  DropItem,
  SetGroupDropResult,
  UnsetGroupDropResult,
} from 'src/interfaces';

interface Props {
  group: string; // finding.group
  groupedViews: string[]; // finding.image in this group
  hidden: boolean;
  viewOnly: boolean;
  onToggle: () => void;
  onClick: () => void;
  selected: boolean;
}

const FindingGroupPanel = ({
  children,
  group,
  groupedViews,
  hidden,
  viewOnly,
  onToggle,
  onClick,
  selected,
}: PropsWithChildren<Props>): JSX.Element => {
  const { open: openAlert } = useAlert();

  const hasMultipleChildren = useMemo(
    () => Children.count(children) >= 2,
    [children]
  );

  const [setDropContext, setDrop] = useDrop(
    () => ({
      accept: 'finding',
      drop: (item: DropItem, monitor): SetGroupDropResult | undefined => {
        // For preventing the event delegation
        if (!monitor.isOver()) {
          return;
        }

        if (item.group !== group && groupedViews?.includes(item.view)) {
          openAlert({
            message: 'Cannot group findings form the same view.',
            type: 'error',
            autoHide: true,
          });
          return;
        }

        const firstLetterOfGroupView = groupedViews?.[0]?.charAt(0);
        const firstLetterOfItemView = item.view?.charAt(0);
        const isItemFromDifferentSideThanGroup =
          firstLetterOfGroupView !== firstLetterOfItemView;
        // e.g. if a group already contains LMLO or LCC, it should not accept RMLO or RCC.
        if (item.group !== group && isItemFromDifferentSideThanGroup) {
          openAlert({
            message: 'Cannot group findings from different sides.',
            type: 'error',
            autoHide: true,
          });
          return;
        }

        return {
          type: 'SET_GROUP',
          group: group || '',
        };
      },
      collect: monitor => ({
        isOver: monitor.isOver({ shallow: true }),
      }),
    }),
    [groupedViews]
  );

  const [unsetDropContext, unsetDrop] = useDrop(
    () => ({
      accept: 'finding',
      drop: (): UnsetGroupDropResult => ({
        type: 'UNSET_GROUP',
      }),
      collect: monitor => ({
        isOver: monitor.isOver({ shallow: true }),
        canDrop:
          monitor.canDrop() &&
          monitor.getItem<DropItem>().group === group &&
          hasMultipleChildren,
      }),
    }),
    [hasMultipleChildren]
  );

  const [expanded, setExpanded] = useState<boolean>(true);

  return (
    <Container
      ref={viewOnly ? undefined : setDrop}
      $isDragOver={!viewOnly && setDropContext.isOver}
      onClick={onClick}
      $isSelected={selected}
    >
      <Collapse in={hasMultipleChildren}>
        <Header>
          {group && <Box sx={{ opacity: 0.5 }}>{group}</Box>}
          <Box>
            <VisibilityButton
              style={{ padding: 4 }}
              hidden={hidden}
              onClick={onToggle}
            />
            <ExpandButton
              style={{ padding: 4 }}
              expanded={expanded}
              onClick={() => setExpanded(prev => !prev)}
            />
          </Box>
        </Header>
      </Collapse>
      <Collapse in={expanded}>
        <Body>{children}</Body>
      </Collapse>
      <Collapse in={unsetDropContext.canDrop}>
        <UnsetDrop ref={unsetDrop} $isDragOver={unsetDropContext.isOver}>
          UNSET GROUP
        </UnsetDrop>
      </Collapse>
    </Container>
  );
};

const Container = styled(Box)<{
  $isDragOver: boolean;
}>(({ theme, $isDragOver, $isSelected }) => {
  const backgroundColor =
    $isDragOver || $isSelected
      ? theme.palette.primary.dark
      : 'var(--ctl-background-color)';

  return `
    padding: ${theme.spacing(1)};
    border-radius: var(--ctl-border-radius);

    transition: background-color 0.2s ease;
    background-color: ${backgroundColor};
  `;
});

const Header = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.spacing(0.75)};
`
);

const Body = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(0.75)};
`
);

const UnsetDrop = styled(Box)<{ $isDragOver: boolean }>(
  ({ theme, $isDragOver }) => `
  margin-top: ${theme.spacing(1)};
  padding: ${theme.spacing(1)};
  border-radius: var(--ctl-border-radius);
  text-align: center;

  transition: background-color 0.2s ease;
  background-color: ${
    $isDragOver ? theme.palette.secondary.main : theme.palette.secondary.dark
  }; 
`
);

export default FindingGroupPanel;
