import { LocalJob } from 'src/interfaces/job';
import { isArray } from 'src/utils/typeHelper';

interface Props {
  job?: LocalJob;
  imageKey: string;
}

const useImagePaths = ({ job, imageKey }: Props): string[] => {
  const jobImages = job?.images;

  if (!jobImages) {
    return [];
  }

  const jobImage = jobImages[imageKey];

  if (
    !jobImage ||
    isArray(jobImage) ||
    jobImage.type !== 'multiple' ||
    !jobImage.paths
  ) {
    return [];
  }

  return jobImage.paths;
};

export default useImagePaths;
