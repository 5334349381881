import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { createRoot } from 'react-dom/client';

import { App } from './app';

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_MSW === 'on'
) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  worker.start();
}

/**
 * Required after updating to react-scripts v5.x for disabling react-error-overlay when testing.
 * @see https://stackoverflow.com/a/76587437
 */
const hideWebpackDevServerErrorOverlayInTestEnv = `#webpack-dev-server-client-overlay {display:${
  process.env.REACT_APP_DEPLOYMENT_PHASE?.includes('test') ? 'none' : 'unset'
};}`;
const style = document.createElement('style');
document.head.appendChild(style);
style.innerHTML = hideWebpackDevServerErrorOverlayInTestEnv;

TimeAgo.addDefaultLocale(en);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.querySelector('#app')!);

root.render(<App />);
