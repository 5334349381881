import {
  PropsWithChildren,
  ReactNode,
  CSSProperties,
  MouseEventHandler,
} from 'react';

import PersonIcon from '@mui/icons-material/Person';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { styled } from '@mui/material/styles';

import IssueIcon from 'src/components/icons/IssueIcon';
import { IssueUpdate } from 'src/interfaces/issue';

export interface IssueItemProps
  extends Pick<IssueUpdate, 'userName' | 'isReviewer' | 'type'> {
  index?: number;
  actionElement: ReactNode;
  activated?: boolean;
  dimmed?: boolean;
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const IssueItem = ({
  index,
  userName,
  actionElement,
  isReviewer,
  children,
  style,
  activated,
  dimmed,
  onClick,
}: PropsWithChildren<IssueItemProps>): JSX.Element => {
  return (
    <ButtonContainer
      role="button"
      aria-label="issue-item-button"
      aria-pressed={activated}
      tabIndex={0}
      style={style}
      $clickable={!!onClick}
      $activated={!!activated}
      onClick={onClick}
    >
      <Header>
        <HeaderItem $dimmed={dimmed}>
          {!!index && <IssueIcon label={`${index}`} activated={!!activated} />}
          {isReviewer ? (
            <PersonSearchIcon fontSize="small" />
          ) : (
            <PersonIcon fontSize="small" />
          )}
          <Name>{userName || '[Deleted]'}</Name>
        </HeaderItem>
        <HeaderItem>{actionElement}</HeaderItem>
      </Header>
      <Content $dimmed={!!dimmed}>{children}</Content>
    </ButtonContainer>
  );
};

export default IssueItem;

type Props = {
  $clickable: boolean;
  $activated: boolean;
};

export const getIssueItemInteractiveStyle = ({
  $activated,
  $clickable,
}: Props): string => `
  transition: background-color var(--ctl-transition-timing);
  cursor: ${$clickable ? 'pointer' : 'default'};
  background-color: ${
    $activated
      ? 'rgba(var(--ctl-brand-color-rgb), .4)!important'
      : 'transparent'
  };

  &:hover {
    background-color: ${
      $clickable ? 'var(--ctl-background-color-lighter)' : 'none'
    };
  };
`;

const ButtonContainer = styled('div')<Props>`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  min-width: auto;
  box-sizing: border-box;
  padding: 0.5rem 0.85rem;
  gap: 0.5rem;
  text-align: left;

  ${({ $clickable, $activated }: Props) =>
    getIssueItemInteractiveStyle({ $activated, $clickable })}
`;

const Header = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
`;

type DimProps = { $dimmed?: boolean };
const HeaderItem = styled('div')<DimProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1rem, auto));
  align-items: center;
  justify-content: flex-start;
  gap: 0.25rem;
  opacity: ${({ $dimmed }: DimProps) => ($dimmed ? '.5' : '1')};
`;

const Content = styled('div')<DimProps>`
  width: 100%;
  opacity: ${({ $dimmed }: DimProps) => ($dimmed ? '.5' : '1')};
`;

const Name = styled('div')`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;
