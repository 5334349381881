import { useCallback } from 'react';

import { useSetRecoilState } from 'recoil';

import { Label } from 'src/interfaces';
import { jobState } from 'src/states/job';
import { taskState } from 'src/states/task';

import { SetLabelFunction } from './useSetFindingLabel';

type Return = SetLabelFunction;

const useSetDecisionLabel = (): Return => {
  const setIsAnnotating = useSetRecoilState(jobState.isAnnotating);
  const setLocalDecisionLabels = useSetRecoilState(
    taskState.localDecisionLabels
  );

  return useCallback(
    (label: Label, index: number) => {
      // if the label is in the list, update its value. If not, add it to the list
      setLocalDecisionLabels(prev => {
        const newLocalDecisionLabels = [...prev];

        if (index >= 0) {
          newLocalDecisionLabels[index] = label;
        }
        if (index === -1) newLocalDecisionLabels.push(label);

        return newLocalDecisionLabels;
      });

      setIsAnnotating(true);
    },
    [setIsAnnotating, setLocalDecisionLabels]
  );
};

export default useSetDecisionLabel;
