import { useRecoilValue, useSetRecoilState } from 'recoil';

import useSyncCurrentJob from 'src/hooks/currentJobId/useSyncCurrentJob';
import useDiscardAnnotations from 'src/hooks/tasks/useDiscardAnnotations';
import useAlert from 'src/hooks/useAlert';
import jobIdListState from 'src/states/jobIdList';

type Return = (jobIndex: number) => Promise<void>;

const useMoveByJobIndex = (): Return => {
  const jobIds = useRecoilValue(jobIdListState.current);
  const setCurrentJobId = useSetRecoilState(jobIdListState.currentJobId);
  const checkDiscard = useDiscardAnnotations();
  const syncCurrentJob = useSyncCurrentJob();

  const { open: openAlert } = useAlert();

  return async (jobIndex: number) => {
    try {
      const jobId = jobIds[jobIndex];
      if (!jobId) {
        throw new Error(`No job is found with given index : ${jobIndex}`);
      }
      // in case of cpc job: user should not be able to move without
      // saving changes in both windows (this & other)
      // this condition ensures for the other window
      const discard = await checkDiscard();
      if (!discard) return;

      await syncCurrentJob(jobId);
      setCurrentJobId(jobId);
    } catch (error) {
      openAlert({
        type: 'error',
        message: (error as Error).message,
      });
    }
  };
};

export default useMoveByJobIndex;
