import { RefObject } from 'react';

import { CornerstoneViewerLike, ViewportTransform } from '@InsightViewer/types';

export function updateViewport(
  viewer: CornerstoneViewerLike | RefObject<CornerstoneViewerLike>,
  update: ViewportTransform
): void {
  let v: CornerstoneViewerLike | null = null;

  if ('updateViewer' in viewer) {
    v = viewer as CornerstoneViewerLike;
  } else if ('current' in viewer && viewer.current) {
    v = viewer.current;
  }

  if (!v) return;

  const patch = update(v.getViewportTransformParams());

  if (patch) {
    v.updateViewport(patch);
  }
}

export const zoomMiddleCenter: (increment: number) => ViewportTransform =
  increment =>
  ({ element, currentViewport, minScale, maxScale }) => {
    if (
      !currentViewport ||
      !currentViewport.scale ||
      !currentViewport.translation
    )
      return;

    const nextScale: number = Math.max(
      minScale,
      Math.min(
        maxScale,
        currentViewport.scale + currentViewport.scale * increment
      )
    );

    if (currentViewport.scale === nextScale) return;

    const distanceX = 0;
    const distanceY = 0;

    const dx: number = (1 - nextScale / currentViewport.scale) * distanceX;
    const dy: number = (1 - nextScale / currentViewport.scale) * distanceY;

    return {
      translation: {
        x: currentViewport.translation.x + dx / nextScale,
        y: currentViewport.translation.y + dy / nextScale,
      },
      scale: nextScale,
    };
  };

export const adjustWindowCenter: (increment: number) => ViewportTransform =
  increment =>
  ({ currentViewport }) => {
    if (!currentViewport || !currentViewport.voi) return;

    const { windowWidth, windowCenter } = currentViewport.voi;

    return {
      voi: {
        windowWidth,
        windowCenter: Math.max(
          Math.floor(windowCenter + windowCenter * increment),
          1
        ),
      },
    };
  };

export const adjustWindowWidth: (increment: number) => ViewportTransform =
  increment =>
  ({ currentViewport }) => {
    if (!currentViewport || !currentViewport.voi) return;

    const { windowWidth, windowCenter } = currentViewport.voi;

    return {
      voi: {
        windowWidth: Math.max(
          Math.floor(windowWidth + windowWidth * increment),
          1
        ),
        windowCenter,
      },
    };
  };
