import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

import { SetLabelFunction } from 'src/hooks/tasks/useSetFindingLabel';
import { Label, Asset as AssetType } from 'src/interfaces';
import { taskState } from 'src/states/task';
import AssetUtils from 'src/utils/asset';

import Asset from './Asset';

interface Props {
  childrenAssets: AssetType[];
  currentLabels: Label[];
  onChangeLabel: SetLabelFunction;
}

const ChildAssetWrapper = ({
  childrenAssets,
  currentLabels,
  onChangeLabel,
}: Props): JSX.Element | null => {
  const assets = useRecoilValue(taskState.assets);

  const representativeAsset = useMemo(() => {
    const [asset] = childrenAssets;
    return asset;
  }, [childrenAssets]);

  const isParentSelected = useMemo(() => {
    if (!representativeAsset) {
      return false;
    }

    return AssetUtils.isParentLabelSelected(
      representativeAsset,
      currentLabels,
      assets
    );
  }, [representativeAsset, currentLabels, assets]);

  const parentAssetTitle: string | undefined = (() => {
    if (!representativeAsset?.parent) {
      return '';
    }
    const { selectedValues } = representativeAsset.parent;
    return selectedValues?.join(', ').toUpperCase();
  })();

  return (
    <Container $isActivated={isParentSelected} data-testid="childLabels">
      {parentAssetTitle && (
        <Title>
          <SubdirectoryArrowRightIcon fontSize="inherit" />
          {parentAssetTitle}
        </Title>
      )}
      {childrenAssets.map(asset => (
        <Asset
          key={asset.name}
          asset={asset}
          currentLabels={currentLabels}
          onChangeLabel={onChangeLabel}
        />
      ))}
    </Container>
  );
};

export default ChildAssetWrapper;

const Container = styled.div<{ $isActivated: boolean }>`
  box-sizing: border-box;
  overflow: hidden;
  padding: 0.2rem 0.6rem;
  margin: 0.6rem 0;
  border-radius: var(--ctl-border-radius);
  transition: ${({ $isActivated }) =>
    $isActivated
      ? 'max-height 1s cubic-bezier(0.5, 0, 1, 0), background-color .5s ease'
      : 'max-height .5s cubic-bezier(0, 1, 0, 1) -.1s, background-color .5s ease'};
  max-height: ${({ $isActivated }) => ($isActivated ? '9999px' : 0)};
  background-color: ${({ $isActivated }) =>
    $isActivated ? 'var(--ctl-brand-color)' : 'var(--ctl-background-color)'};
`;

const Title = styled.div`
  color: var(--ctl-color-activated);
  font-size: 0.875rem;
  margin-top: 0.4rem;
`;
