import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import HeaderLogoIcon from 'src/components/icons/HeaderLogoIcon';
import HorizontalFourViewerIcon from 'src/components/icons/HorizontalFourViewerIcon';
import IssueIcon from 'src/components/icons/IssueIcon';
import LogoIcon from 'src/components/icons/LogoIcon';
import MatrixFourViewerIcon from 'src/components/icons/MatrixFourViewerIcon';

const IconsPage = (): JSX.Element => {
  return (
    <Box sx={{ padding: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="h4">SVG Icon examples</Typography>
      <Paper sx={{ padding: 3 }}>
        <Typography variant="h5" gutterBottom>
          Icons that has fixed sizes
        </Typography>
        <HeaderLogoIcon />
        <IssueIcon label="1" activated />
        <IssueIcon label="2" activated={false} />
      </Paper>

      <Paper sx={{ padding: 3 }}>
        <Typography variant="h5" gutterBottom>
          Icons that needs a wrapper for sizing
        </Typography>
        <Box sx={{ width: 100 }}>
          <LogoIcon />
        </Box>
        <Box sx={{ width: 32 }}>
          <HorizontalFourViewerIcon />
        </Box>
        <Box sx={{ width: 32 }}>
          <MatrixFourViewerIcon />
        </Box>
      </Paper>
    </Box>
  );
};

export default IconsPage;
