import { StrictMode, Suspense } from 'react';

import cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import dicomParser from 'dicom-parser';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { GlobalErrorFallback } from 'src/components/ErrorFallback/GlobalErrorFallback';
import Loading from 'src/components/loading';
import Database from 'src/pages/database';
import LoginPage from 'src/pages/login';
import WorkspacePage from 'src/pages/workspace';

import { CombinedProviders } from './CombinedProvider';
import PrivateRoute from './PrivateRoute';
import NotFound from './pages/404';
import ComponentsPage from './pages/components';
import IconsPage from './pages/icons';

import './style.scss';

cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;

export function App(): JSX.Element {
  return (
    <BrowserRouter>
      <StrictMode>
        <CombinedProviders>
          <Switch>
            <Redirect exact from="/" to="/login" />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/database" component={Database} />
            <PrivateRoute path="/workspace">
              <ErrorBoundary
                key="global"
                FallbackComponent={GlobalErrorFallback}
              >
                <Suspense fallback={<Loading />}>
                  <WorkspacePage />
                </Suspense>
              </ErrorBoundary>
            </PrivateRoute>
            <PrivateRoute path="/prior">
              <ErrorBoundary
                key="global"
                FallbackComponent={GlobalErrorFallback}
              >
                <Suspense fallback={<Loading />}>
                  <WorkspacePage />
                </Suspense>
              </ErrorBoundary>
            </PrivateRoute>
            <Route path="/components" component={ComponentsPage} />
            <Route path="/icons" component={IconsPage} />
            <Route path="/*" component={NotFound} />
          </Switch>
        </CombinedProviders>
      </StrictMode>
    </BrowserRouter>
  );
}
