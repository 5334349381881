import { ComponentType, PropsWithChildren } from 'react';

import { FallbackProps } from 'react-error-boundary';
import styled from 'styled-components';

import Button from '@mui/material/Button';

import useLogout from 'src/hooks/authentications/useLogout';
import { ClientError, ClientErrorCode } from 'src/http/client-error';

import ErrorPanel from './ErrorPanel';

export const GlobalErrorFallback: ComponentType<
  PropsWithChildren<FallbackProps>
> = ({ error }) => {
  const logout = useLogout();
  const clientError = error as ClientError;

  const renderButtons = (): JSX.Element => {
    switch (clientError?.code) {
      case ClientErrorCode.UNAUTHENTICATED:
      case ClientErrorCode.UNAUTHORIZED:
      case ClientErrorCode.NO_PROJECT_ASSIGNED:
        return (
          <Button
            variant="contained"
            color="primary"
            aria-label="logout"
            onClick={logout}
          >
            Go back to login page
          </Button>
        );
      case ClientErrorCode.UNEXPECTED:
      default:
        return (
          <>
            <Button
              variant="contained"
              color="primary"
              aria-label="home"
              href="/"
              style={{ whiteSpace: 'nowrap' }}
            >
              Go back to home
            </Button>
            <Button
              variant="contained"
              color="secondary"
              aria-label="logout"
              onClick={logout}
              style={{ whiteSpace: 'nowrap' }}
            >
              Go back to login page
            </Button>
          </>
        );
    }
  };

  return (
    <Container>
      <ErrorPanel
        title={
          clientError?.code === ClientErrorCode.NO_PROJECT_ASSIGNED
            ? 'Oops! Sorry for inconvenience.'
            : clientError.name
        }
        subTitle1={clientError.message}
      >
        {renderButtons()}
      </ErrorPanel>
    </Container>
  );
};

const Container = styled.div`
  padding: 3rem;
`;
