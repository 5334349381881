import { startAdjustInteraction } from '@InsightViewer/components/interactions/startAdjustInteraction';
import { CornerstoneViewerLike } from '@InsightViewer/types';

export const adjust =
  ({ element }: { element?: HTMLElement | null } = {}) =>
  ({
    getElement,
    getCurrentViewport,
    updateViewport,
    getContentWindow,
  }: CornerstoneViewerLike): (() => void) => {
    if (!element) element = getElement();

    return startAdjustInteraction({
      element,
      getCurrentViewport,
      onMove: (voi: cornerstone.VOI) => updateViewport({ voi }),
      onEnd: () => {},
      contentWindow: getContentWindow(),
    });
  };
