import { useRef, useState } from 'react';

import {
  useRecoilValue,
  useRecoilValue_TRANSITION_SUPPORT_UNSTABLE,
} from 'recoil';

import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Button, { ButtonProps } from '@mui/material/Button';
import ClickAwayListener, {
  ClickAwayListenerProps,
} from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { TransitionProps as TransitionPropsType } from '@mui/material/transitions';

import useAlert from 'src/hooks/useAlert';
import useErrorHandler from 'src/hooks/useErrorHandler';
import { markAllUpdatesAsRead } from 'src/services/issue';
import issuesState, { useRefreshUpdates } from 'src/states/issues';
import { projectState } from 'src/states/project';

import NewIssueList from './NewIssueList';

const NewIssueButton = (): JSX.Element => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const updates = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(
    issuesState.updatesByProject
  );
  const refreshUpdates = useRefreshUpdates();
  const markAllAsRead = useErrorHandler(markAllUpdatesAsRead);
  const { open: openAlert } = useAlert();
  const { id: projectId } = useRecoilValue(projectState.current);

  const handleToggle = () => {
    setOpen(prev => !prev);
  };

  const handleMarkAllRead = async () => {
    try {
      await markAllAsRead(projectId);
      refreshUpdates();
    } catch (error) {
      openAlert({
        type: 'error',
        message: `Failed to toggle read status: ${(error as Error).message}`,
      });
    }
  };

  const handleClose: ClickAwayListenerProps['onClickAway'] = event => {
    if (
      anchorRef.current &&
      event.target &&
      anchorRef.current.contains(event.target as Node)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Container ref={anchorRef}>
      <IconButton onClick={handleToggle}>
        {updates.length > 0 ? (
          <NotificationsActiveIcon />
        ) : (
          <NotificationsIcon />
        )}
      </IconButton>

      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }: { TransitionProps: TransitionPropsType }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'right top',
              backgroundColor: 'var(--ctl-background-color-light)',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <section style={{ width: 400 }}>
                  <div
                    style={{ backgroundColor: 'var(--ctl-background-color)' }}
                  >
                    <NewIssueHeader style={{ padding: '0.8rem' }}>
                      <Typography fontWeight={700}>
                        New Issues/Comments
                      </Typography>

                      <MarkAsReadButton
                        onClick={handleMarkAllRead}
                        size="small"
                        disabled={updates.length === 0}
                      >
                        <Typography>Mark all as read</Typography>
                      </MarkAsReadButton>
                    </NewIssueHeader>

                    <NewIssueList onClose={() => setOpen(false)} />
                  </div>
                </section>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </Container>
  );
};

const Container = styled('div')``;

const NewIssueHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledPopper = styled(Popper)`
  z-index: 100;
`;

const MarkAsReadButton = styled(Button)<ButtonProps>(() => ({
  textTransform: 'capitalize',
  color: '#fff',
}));

export default NewIssueButton;
