import { RefObject, useMemo, useRef, useState, MouseEvent } from 'react';

import { isComplexPolygon } from '@lunit/is-complex-polygon';
import { isPolygonAreaGreaterThanArea } from '@lunit/is-polygon-area-greater-than-area';
import {
  constSelector,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable_TRANSITION_SUPPORT_UNSTABLE,
} from 'recoil';
import styled from 'styled-components';
import useResizeObserver from 'use-resize-observer';

import { ContourDrawer } from '@InsightViewer/components/ContourDrawer';
import { ContourHover } from '@InsightViewer/components/ContourHover';
import { CornerstoneViewer } from '@InsightViewer/components/CornerstoneViewer';
import { InsightViewerContainer } from '@InsightViewer/components/InsightViewerContainer';
import { PointViewer } from '@InsightViewer/components/PointViewer';
import { useInsightViewerSync } from '@InsightViewer/hooks/useInsightViewerSync';
import { useViewerInteractions } from '@InsightViewer/interactions/useViewerInteractions';
import { CornerstoneViewerLike, Point } from '@InsightViewer/types';

import IssuePointPin from 'src/components/Issue/IssuePointPin';
import { ViewportInfoLabel } from 'src/components/opt-components/ViewportInfoLabel';
import { ExpandViewButton } from 'src/components/viewers/MultiView/ExpandViewButton';
import UserContourViewer from 'src/components/viewers/UserContourViewer';
import ViewOnlyContourViewer from 'src/components/viewers/UserContourViewer/ViewOnlyContourViewer';
import useImagePath from 'src/hooks/useImagePath';
import {
  LocalFinding,
  Control,
  FindingContour,
  FindingShape,
} from 'src/interfaces';
import imageState from 'src/states/image';
import issuesState from 'src/states/issues';
import { jobState } from 'src/states/job';
import { taskState } from 'src/states/task';
import FindingUtils from 'src/utils/finding';

interface ViewerProps {
  view: string;
  viewerRef?: RefObject<CornerstoneViewerLike>;
  viewerIndex: number;
  control: Control;
  resetTime: number;
  flip: boolean;
  invert: boolean;
  findings: LocalFinding[];
  addFinding: (polygon: Point[]) => void;
  findingIndex?: number;
  setFindingIndex: (index: number | undefined) => void;
}

function voidFunc() {
  /* empty function */
}

export default function Viewer({
  view,
  viewerRef,
  viewerIndex,
  control,
  resetTime,
  flip,
  invert,
  findings,
  addFinding,
  findingIndex,
  setFindingIndex,
}: ViewerProps): JSX.Element {
  const job = useRecoilValue(jobState.current);
  const imagePath = useImagePath({ job, imageKey: view });
  const image = useRecoilValue(
    !!imagePath
      ? imageState.cornerstoneImage(imagePath)
      : constSelector(undefined)
  );

  const [interactionElement, setInteractionElement] =
    useState<HTMLElement | null>(null);

  const {
    ref,
    width = 500,
    height = 500,
  } = useResizeObserver<HTMLDivElement>();

  const { cornerstoneRenderData, updateCornerstoneRenderData } =
    useInsightViewerSync();
  const interactions = useViewerInteractions(
    ['zoom', control === 'brightness' ? 'adjust' : control],
    {
      element: interactionElement,
    }
  );
  const currentContourRef = useRef<FindingContour | null>(null);

  const [localIssuePosition, setLocalIssuePosition] = useRecoilState(
    issuesState.localIssuePosition
  );
  const issuesLoadable = useRecoilValueLoadable_TRANSITION_SUPPORT_UNSTABLE(
    issuesState.pointList
  );
  const issuePointList =
    issuesLoadable.state === 'hasValue' ? issuesLoadable.contents : [];

  const activatedId = useRecoilValue(issuesState.activatedId);
  const currentGroupName = useRecoilValue(taskState.currentGroupName);
  const focusedIssue =
    issuePointList.find(({ issueId }) => issueId === activatedId) || null;

  const handleAddIssue = (contour: Point[], event: MouseEvent) => {
    event.stopPropagation();
    const [point] = contour;
    if (!point) return;
    setLocalIssuePosition({
      view,
      location: point,
    });
  };

  const imageWidth = cornerstoneRenderData?.image?.width || 0;
  const imageHeight = cornerstoneRenderData?.image?.height || 0;

  const contours = useMemo(() => {
    return findings
      .map(FindingUtils.getContourFromFinding(imageWidth, imageHeight))
      .filter(contour => !contour.hidden);
  }, [findings, imageWidth, imageHeight]);

  const normalContours = useMemo(() => {
    return contours.filter(contour => !contour.viewOnly);
  }, [contours]);

  const viewOnlyContours = useMemo(() => {
    return contours.filter(contour => !!contour.viewOnly);
  }, [contours]);

  const focusedContour =
    contours.find(FindingUtils.isFocused(findingIndex, currentGroupName)) ||
    null;

  const onPolygonDraw = (polygon: Point[]) => {
    //TODO: Maybe there's a better way to determine if it's a simple click
    if (!isPolygonAreaGreaterThanArea(polygon) || isComplexPolygon(polygon)) {
      setFindingIndex(undefined);
      return;
    }
    addFinding(
      polygon.map(([x, y]) => {
        return [y / imageHeight, x / imageWidth];
      })
    );
  };

  /** Pen 모드에서 클릭시 */
  const onContourClick = (contour: FindingContour) => {
    setFindingIndex(contour.id);
  };

  /** Pen 이외 모드에서 클릭시 */
  const onContainerClick = () => {
    const contour = currentContourRef.current;
    setFindingIndex(contour?.id);
  };
  const onContourHover = (contour: FindingContour | null) => {
    currentContourRef.current = contour;
  };

  return (
    <View ref={ref}>
      {image && (
        <InsightViewerContainer
          ref={setInteractionElement}
          width={width}
          height={height}
          onClick={onContainerClick}
        >
          <CornerstoneViewer
            ref={viewerRef as RefObject<CornerstoneViewer>}
            width={width}
            height={height}
            interactions={interactions}
            flip={flip}
            invert={invert}
            resetTime={resetTime}
            image={image}
            cornerstoneRenderData={cornerstoneRenderData}
            updateCornerstoneRenderData={updateCornerstoneRenderData}
          >
            <ViewOnlyContourViewer
              width={width}
              height={height}
              contours={viewOnlyContours}
              focusedContour={focusedContour}
              cornerstoneRenderData={cornerstoneRenderData}
            />
            <UserContourViewer
              width={width}
              height={height}
              contours={normalContours}
              focusedContour={focusedContour}
              cornerstoneRenderData={cornerstoneRenderData}
            />
            <ContourDrawer
              width={width}
              height={height}
              contours={contours}
              draw={
                control === FindingShape.POLYGON ? interactionElement : false
              }
              onFocus={voidFunc}
              onAdd={onPolygonDraw}
              onRemove={onContourClick}
              cornerstoneRenderData={cornerstoneRenderData}
            />
            <ContourHover
              width={width}
              height={height}
              contours={contours}
              hover={interactionElement}
              onFocus={onContourHover}
              cornerstoneRenderData={cornerstoneRenderData}
            />
            <PointViewer
              width={width}
              height={height}
              contours={issuePointList.filter(issue => issue.view === view)}
              focusedContour={focusedIssue}
              cornerstoneRenderData={cornerstoneRenderData}
              interact={false}
              pointPinComponent={IssuePointPin}
            />
            {control === 'issuePoint' && (
              <PointViewer
                width={width}
                height={height}
                contours={
                  localIssuePosition?.view === view
                    ? [
                        {
                          id: issuePointList.length + 1,
                          polygon: [localIssuePosition.location],
                          shape: 'point',
                        },
                      ]
                    : []
                }
                focusedContour={null}
                cornerstoneRenderData={cornerstoneRenderData}
                interact={true}
                onAdd={handleAddIssue}
                pointPinComponent={IssuePointPin}
              />
            )}
          </CornerstoneViewer>
          <RightBottomHolder>
            <ViewportInfoLabel cornerstoneRenderData={cornerstoneRenderData} />
          </RightBottomHolder>
        </InsightViewerContainer>
      )}
      <LeftTopHolder>
        <ExpandViewButton viewIndex={viewerIndex} title={view} />
      </LeftTopHolder>
    </View>
  );
}

const View = styled.div`
  overflow: hidden;
  position: relative;
  height: 100%;
  min-height: 0;
`;
const LeftTopHolder = styled.div`
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
`;

const RightBottomHolder = styled.div`
  user-select: none;
  pointer-events: none;
  position: absolute;
  right: 5px;
  bottom: 5px;
`;
