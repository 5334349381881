import {
  atom,
  atomFamily,
  selector,
  selectorFamily,
  useSetRecoilState,
} from 'recoil';

import { getDefaultJob, LocalJob, ProjectType } from 'src/interfaces';
import { getJob } from 'src/services/job';

import jobIdListState from './jobIdList';
import { operationModeState } from './operationMode';
import { projectState } from './project';

const cachedRequestId = atomFamily({ key: 'job/cachedRequestId', default: 0 });

export const useRefreshCachedJob = (jobId: string | null): (() => void) => {
  const setCachedRequestId = useSetRecoilState(cachedRequestId(jobId));
  return () => {
    setCachedRequestId(requestId => requestId + 1);
  };
};

const cached = selectorFamily<LocalJob, string>({
  key: 'job/cached',
  get:
    jobId =>
    async ({ get }) => {
      get(cachedRequestId(jobId));

      const { claim } = get(projectState.current);

      return getJob({
        claim: claim,
        jobId: jobId,
      });
    },
});

const current = selector<LocalJob>({
  key: 'job/current',
  get: ({ get }) => {
    const currentJobId = get(jobIdListState.currentJobId);
    if (!currentJobId) {
      return getDefaultJob({ type: ProjectType.XRAY, images: [] }, []);
    }

    return get(cached(currentJobId));
  },
});

const next = selector<LocalJob>({
  key: 'job/next',
  get: ({ get }) => {
    const nextJobId = get(jobIdListState.nextJobId);

    if (!nextJobId) {
      return getDefaultJob({ type: ProjectType.XRAY, images: [] }, []);
    }

    return get(cached(nextJobId));
  },
});

const isAnnotating = atom<boolean>({
  key: 'job/isAnnotating',
  default: false,
});

const isOtherCPCWindowAnnotating = atom<boolean>({
  key: 'job/isOtherCPCWindowAnnotating',
  default: false,
});

const canGoNext = selector({
  key: 'job/canGoNext',
  get: ({ get }) => {
    const job = get(current);
    const isLastJobIndex = get(jobIdListState.isLastJobIndex);
    if (job.reported) return !isLastJobIndex;
    return !get(isAnnotating) && !isLastJobIndex;
  },
});

const canGoPrev = selector({
  key: 'job/canGoPrev',
  get: ({ get }) => {
    const job = get(current);
    const currentJobIndex = get(jobIdListState.currentJobIndex);
    return (
      !get(isAnnotating) && (job.reported || job.id) && currentJobIndex !== 0
    );
  },
});

const canSave = selector({
  key: 'job/canSave',
  get: ({ get }) => {
    const job = get(current);
    if (job.reported) {
      return false;
    }
    const canSaveJob = job.id && !job.annotationExists;
    if (!get(operationModeState.current).isEditable) return false;
    return canSaveJob ? true : get(isAnnotating);
  },
});

export const jobState = Object.freeze({
  current,
  next,
  canGoNext,
  canSave,
  canGoPrev,
  isAnnotating,
  isOtherCPCWindowAnnotating,
});
