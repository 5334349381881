import { useCallback } from 'react';

import { ClientError, ClientErrorCode } from 'src/http/client-error';

import useLogout from './authentications/useLogout';
import useAlert from './useAlert';
import useConfirmModal from './useConfirmModal';

/**
 * Custom hook for handling http request error commonly with react lifecycle
 *
 * @param request : Service function which is necessary to handle error commonly
 * @param defaultValue : Default returning value of service function
 * @returns
 */
const useErrorHandler = <T extends Array<unknown>, U>(
  request: (...args: T) => Promise<U>
): ((...args: T) => Promise<U>) => {
  const { getConfirmation } = useConfirmModal();
  const { open: openAlert } = useAlert();
  const logout = useLogout();

  const handleCommonError = useCallback(
    async (error: ClientError) => {
      switch (error.code) {
        case ClientErrorCode.NETWORK_FAILURE: {
          openAlert({
            message: error.message,
            type: 'error',
          });
          throw error;
        }
        case ClientErrorCode.UNAUTHENTICATED:
        case ClientErrorCode.UNAUTHORIZED: {
          const confirmed = await getConfirmation({
            title: 'Authentication Error',
            description: error.message,
            confirmButtonText: 'Login',
          });
          confirmed && logout();
          throw error;
        }

        default: {
          throw error;
        }
      }
    },
    [openAlert, getConfirmation, logout]
  );

  return useCallback(
    async (...args: T) => {
      return request(...args).catch(handleCommonError);
    },
    [request, handleCommonError]
  );
};

export default useErrorHandler;
