import { ComponentProps } from 'react';

import Skeleton from '@mui/material/Skeleton';

import AsideItem from './AsideItem';

type Props = ComponentProps<typeof AsideItem>;

const AsideItemSkeleton = (props: Props): JSX.Element => {
  return (
    <AsideItem {...props}>
      <Skeleton width="100%" />
      <Skeleton width="100%" />
      <Skeleton width="100%" />
      <Skeleton width="50%" />
    </AsideItem>
  );
};

export default AsideItemSkeleton;
