import imageState, {
  DEFAULT_NEXT_DBT_JOB_FRAMES_PRELOADING_QTY,
} from 'src/states/image';

import PreloadingQtySettingItem from './PreloadingQtySettingItem';

export function NextDBTJobFramesPreloadingQty(): JSX.Element {
  return (
    <PreloadingQtySettingItem
      heading="Next Job Frames"
      description="How many 3D DBT frames to preload for the next job."
      recoilState={imageState.nextDBTJobFramesPreloadingQty}
      defaultValue={DEFAULT_NEXT_DBT_JOB_FRAMES_PRELOADING_QTY}
    ></PreloadingQtySettingItem>
  );
}
