const HeaderLogoIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="214.489" height="17.599">
    <g id="Lunit_INSIGHT" transform="translate(0 0)">
      <path
        id="Path_46"
        data-name="Path 46"
        fill="#00c9ea"
        d="M186.724,128.235a3.557,3.557,0,1,1,1.945-6.533l5.262-5.253a3.813,3.813,0,0,1-.541-1.992,3.557,3.557,0,1,1,3.557,3.557,3.816,3.816,0,0,1-1.992-.543l-5.262,5.26a3.557,3.557,0,0,1-2.969,5.5Zm0-5.668a2.11,2.11,0,1,0,1.5.626v-.012h0a2.089,2.089,0,0,0-1.5-.6Zm8.672-6.573h0a2.177,2.177,0,0,0,1.541.567,2.111,2.111,0,1,0-2.111-2.111A2.182,2.182,0,0,0,195.4,116Z"
        transform="translate(-183.163 -110.888)"
      />
      <path
        id="Path_47"
        data-name="Path 47"
        fill="#fff"
        d="M283.91,126.943h-6.355c-1.423,0-1.423-.14-1.423-1.8V111.681a.678.678,0,1,0-1.347,0v14.8a1.7,1.7,0,0,0,1.677,1.807h7.446a.673.673,0,0,0,0-1.347Z"
        transform="translate(-253.054 -110.905)"
      />
      <path
        id="Path_48"
        data-name="Path 48"
        fill="#fff"
        d="M438.464,130.19a.685.685,0,0,0-.673.673v11.326a.673.673,0,1,0,1.347,0V130.856A.685.685,0,0,0,438.464,130.19Z"
        transform="translate(-377.408 -125.603)"
      />
      <path
        id="Path_49"
        data-name="Path 49"
        fill="#fff"
        d="M437.3,110.85a1.036,1.036,0,1,0,1.036,1.036A1.036,1.036,0,0,0,437.3,110.85Z"
        transform="translate(-376.241 -110.849)"
      />
      <path
        id="Path_50"
        data-name="Path 50"
        fill="#fff"
        d="M463.366,126.855h-.422c-1.423,0-1.423-.138-1.423-1.8v-8.475h1.9a.6.6,0,1,0,0-1.186h-1.9V111.6a.678.678,0,1,0-1.349,0v3.794h-1.527a.6.6,0,1,0,0,1.186h1.527V126.4a1.7,1.7,0,0,0,1.679,1.807h1.511a.676.676,0,0,0,0-1.349Z"
        transform="translate(-392.823 -110.848)"
      />
      <path
        id="Path_51"
        data-name="Path 51"
        fill="#fff"
        d="M386.329,129.64a6.094,6.094,0,0,0-4.437,1.982v-1.088a.664.664,0,1,0-1.326,0V141.68a.664.664,0,1,0,1.326,0v-8.445a5.553,5.553,0,0,1,4-2.4c1.786,0,2.983.711,2.983,3.083v7.754a.664.664,0,1,0,1.328,0v-8.032C390.206,130.864,388.828,129.64,386.329,129.64Z"
        transform="translate(-333.753 -125.184)"
      />
      <path
        id="Path_52"
        data-name="Path 52"
        fill="#fff"
        d="M334.118,129.64a.676.676,0,0,0-.664.664v8.433a5.553,5.553,0,0,1-4,2.4c-1.786,0-2.983-.711-2.983-3.083V130.3a.664.664,0,1,0-1.328,0v8.032c0,2.779,1.378,4.005,3.877,4.005a6.072,6.072,0,0,0,4.437-1.982v1.088a.664.664,0,1,0,1.326,0V130.3A.676.676,0,0,0,334.118,129.64Z"
        transform="translate(-291.472 -125.184)"
      />
      <path
        id="Path_56"
        data-name="Path 56"
        fill="#fff"
        d="M519.17,136.8V123.822a.614.614,0,0,1,.614-.612.6.6,0,0,1,.59.612V136.8a.609.609,0,0,1-.59.612A.628.628,0,0,1,519.17,136.8Z"
        transform="translate(-439.49 -120.278)"
      />
      <path
        id="Path_57"
        data-name="Path 57"
        fill="#fff"
        d="M552.459,137.1l-8.677-11.9V136.8a.607.607,0,0,1-.59.612.629.629,0,0,1-.612-.612V124.012a.785.785,0,0,1,.78-.8.813.813,0,0,1,.655.337l8.625,11.708V123.822a.6.6,0,0,1,.591-.612.614.614,0,0,1,.612.612v12.834a.766.766,0,0,1-.737.759A.747.747,0,0,1,552.459,137.1Z"
        transform="translate(-457.348 -120.278)"
      />
      <path
        id="Path_58"
        data-name="Path 58"
        fill="#fff"
        d="M605.77,134.74a.609.609,0,0,1,.59-.612.685.685,0,0,1,.422.147,5.627,5.627,0,0,0,3.989,1.66c2.893,0,3.716-1.6,3.716-2.805,0-1.985-1.878-2.533-3.863-3.061-2.215-.612-4.579-1.245-4.579-3.842,0-2.3,2.068-3.756,4.686-3.756a6.1,6.1,0,0,1,4.347,1.624.545.545,0,0,1,.237.443.607.607,0,0,1-.612.59.474.474,0,0,1-.379-.147,5.018,5.018,0,0,0-3.652-1.423c-1.942,0-3.355,1.077-3.355,2.594,0,1.66,1.75,2.153,3.671,2.661,2.281.654,4.771,1.371,4.771,4.221,0,1.814-1.2,3.989-5,3.989a6.7,6.7,0,0,1-4.793-1.857A.55.55,0,0,1,605.77,134.74Z"
        transform="translate(-505.554 -119.714)"
      />
      <path
        id="Path_59"
        data-name="Path 59"
        fill="#fff"
        d="M663.62,136.8V123.822a.614.614,0,0,1,.612-.612.6.6,0,0,1,.59.612V136.8a.609.609,0,0,1-.59.612A.628.628,0,0,1,663.62,136.8Z"
        transform="translate(-549.685 -120.278)"
      />
      <path
        id="Path_60"
        data-name="Path 60"
        fill="#fff"
        d="M697.274,130.469v3.778a.949.949,0,0,1-.337.8,7.236,7.236,0,1,1-5-12.559,6.678,6.678,0,0,1,4.918,1.964.474.474,0,0,1,.149.379.543.543,0,0,1-.572.569.571.571,0,0,1-.379-.147,6.077,6.077,0,1,0-4.117,10.728,5.981,5.981,0,0,0,4.138-1.71v-3.384h-4.854a.538.538,0,0,1,0-1.077h5.4a.591.591,0,0,1,.655.654Z"
        transform="translate(-565.843 -119.728)"
      />
      <path
        id="Path_61"
        data-name="Path 61"
        fill="#fff"
        d="M764,136.8v-6.142h-8.928V136.8a.609.609,0,0,1-.59.612.626.626,0,0,1-.612-.612V123.822a.612.612,0,0,1,.612-.612.6.6,0,0,1,.591.612v5.741H764v-5.741a.612.612,0,0,1,1.224,0V136.8a.612.612,0,1,1-1.224,0Z"
        transform="translate(-618.534 -120.278)"
      />
      <path
        id="Path_62"
        data-name="Path 62"
        fill="#fff"
        d="M820,137V124.574h-4.114a.55.55,0,1,1,0-1.1h9.486a.55.55,0,0,1,0,1.1h-4.148V137A.612.612,0,0,1,820,137Z"
        transform="translate(-665.394 -120.481)"
      />
    </g>
  </svg>
);
export default HeaderLogoIcon;
