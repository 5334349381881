import { useRecoilValue } from 'recoil';

import useErrorHandler from 'src/hooks/useErrorHandler';
import { syncProjectCurrJobId as syncProjectCurrJobIdService } from 'src/services/project';
import { projectState } from 'src/states/project';

type Return = (jobId: string | null) => Promise<void>;

const useSyncCurrentJob = (): Return => {
  const project = useRecoilValue(projectState.current);

  const syncProjectCurrJobId = useErrorHandler(syncProjectCurrJobIdService);

  return async jobId => {
    if (!jobId) {
      return;
    }
    await syncProjectCurrJobId({
      projectId: project.id,
      jobId,
    });
  };
};

export default useSyncCurrentJob;
