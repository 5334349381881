import { useState } from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import FrameNavigator from 'src/components/FrameNavigator';
import FindingGroupPanel from 'src/pages/workspace/task/FindingPanel/FindingGroupPanel';

const ComponentsPage = (): JSX.Element => {
  const [isMultipleFindings, setIsMultipleFindings] = useState(true);
  const [hidden, setHidden] = useState(false);

  return (
    <Box sx={{ padding: 4, display: 'flex', flexDirection: 'column', gap: 4 }}>
      <Typography variant="h4">UI component examples</Typography>

      <Section>
        <Typography variant="h5" gutterBottom>
          {'<FrameNavigator />'}
        </Typography>
        <FrameNavigator value={40} totalFrames={100} onChange={() => {}} />
        <FrameNavigator value={[0, 50]} totalFrames={80} onChange={() => {}} />
        <FrameNavigator value={[20, 30]} totalFrames={64} onChange={() => {}} />
      </Section>

      <Section>
        <Typography variant="h5" gutterBottom>
          {'<FindingGroupPanel />'}
        </Typography>

        <FormControlLabel
          control={<Switch />}
          label="Multiple items"
          checked={isMultipleFindings}
          onChange={() => setIsMultipleFindings(prev => !prev)}
        />

        <Box sx={{ width: 320 }}>
          <DndProvider backend={HTML5Backend}>
            <FindingGroupPanel
              group="The name of group"
              groupedViews={[]}
              hidden={hidden}
              viewOnly={false}
              onToggle={() => setHidden(prev => !prev)}
              onClick={() => {
                window.alert('Group Clicked!');
              }}
              selected={true}
            >
              {[...Array(isMultipleFindings ? 4 : 1).keys()].map(item => (
                <DummyFinding key={item}>Finding {item + 1}</DummyFinding>
              ))}
            </FindingGroupPanel>
          </DndProvider>
        </Box>
      </Section>
    </Box>
  );
};

export default ComponentsPage;

const Section = styled(Paper)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(2)};
  gap: ${theme.spacing(1)};
  background-color: var(--ctl-background-color-light);
`
);

const DummyFinding = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
  background-color: var(--ctl-background-color-light);
`
);
