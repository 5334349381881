const LogoIcon = (): JSX.Element => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 150 100">
    <g id="menu">
      <g>
        <g>
          <g>
            <path
              fill="#00C9EA"
              d="M66.5,44.9c-1.2,0-2.4-0.5-3.3-1.4c-0.9-0.9-1.4-2-1.4-3.3c0-2.6,2.1-4.7,4.7-4.7c0.9,0,1.8,0.3,2.5,0.8
					l6.9-6.9c-0.5-0.7-0.7-1.7-0.7-2.6c0-2.6,2.1-4.7,4.7-4.7s4.7,2.1,4.7,4.7s-2.1,4.7-4.7,4.7c-1,0-1.9-0.3-2.6-0.7l-6.9,6.9
					c0.5,0.8,0.8,1.6,0.8,2.6C71.1,42.8,69,44.9,66.5,44.9z M66.5,37.5c-1.5,0-2.8,1.2-2.8,2.8c0,0.7,0.3,1.4,0.8,2
					c0.5,0.5,1.2,0.8,2,0.8c1.5,0,2.8-1.2,2.8-2.8c0-0.7-0.3-1.4-0.8-1.9l0,0l0,0C67.9,37.7,67.2,37.5,66.5,37.5z M77.8,28.8
					C77.8,28.8,77.8,28.9,77.8,28.8c0.5,0.5,1.2,0.8,2,0.8c1.5,0,2.8-1.2,2.8-2.8c0-1.5-1.2-2.8-2.8-2.8c-1.5,0-2.8,1.2-2.8,2.8
					C77.1,27.6,77.3,28.4,77.8,28.8z"
            />
          </g>
        </g>
        <g>
          <path
            fill="#fff"
            d="M5.6,91.4V71c0-0.5,0.4-1,1-1s0.9,0.4,0.9,1v20.4c0,0.5-0.4,1-0.9,1S5.6,91.9,5.6,91.4z"
          />
          <path
            fill="#fff"
            d="M31.8,91.9L18.2,73.1v18.3c0,0.5-0.4,1-0.9,1s-1-0.5-1-1V71.3c0-0.7,0.5-1.3,1.2-1.3c0.4,0,0.8,0.2,1,0.5
				L32.1,89V71c0-0.5,0.4-1,0.9-1s1,0.4,1,1v20.2c0,0.6-0.5,1.2-1.2,1.2C32.4,92.4,32,92.2,31.8,91.9z"
          />
          <path
            fill="#fff"
            d="M41.9,89.1c0-0.5,0.4-1,0.9-1c0.3,0,0.5,0.1,0.7,0.2c1.5,1.4,3.6,2.6,6.3,2.6c4.6,0,5.8-2.5,5.8-4.4
				c0-3.1-3-4-6.1-4.8c-3.5-1-7.2-2-7.2-6c0-3.6,3.3-5.9,7.4-5.9c2.9,0,5.1,0.9,6.8,2.6c0.2,0.1,0.4,0.4,0.4,0.7
				c0,0.5-0.4,0.9-1,0.9c-0.2,0-0.5-0.1-0.6-0.2c-1.6-1.6-3.6-2.2-5.7-2.2c-3.1,0-5.3,1.7-5.3,4.1c0,2.6,2.8,3.4,5.8,4.2
				c3.6,1,7.5,2.2,7.5,6.6c0,2.9-1.9,6.3-7.9,6.3c-3.2,0-5.8-1.2-7.5-2.9C42,89.6,41.9,89.4,41.9,89.1z"
          />
          <path
            fill="#fff"
            d="M65.4,91.4V71c0-0.5,0.4-1,1-1c0.5,0,0.9,0.4,0.9,1v20.4c0,0.5-0.4,1-0.9,1S65.4,91.9,65.4,91.4z"
          />
          <path
            fill="#fff"
            d="M95,82.3v5.9c0,0.5-0.1,0.9-0.5,1.3c-1.9,1.9-4.7,3.2-7.9,3.2c-6.2,0-11.2-4.7-11.2-11.5s5-11.5,11.2-11.5
				c3.2,0,5.7,1.1,7.7,3.1c0.2,0.1,0.2,0.4,0.2,0.6c0,0.5-0.4,0.9-0.9,0.9c-0.2,0-0.5-0.1-0.6-0.2c-1.6-1.6-4-2.6-6.5-2.6
				c-5.2,0-9.2,4-9.2,9.7s4,9.8,9.2,9.8c3,0,5.3-1.5,6.5-2.7V83h-7.6c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.8,0.9-0.8h8.5
				C94.6,81.3,95,81.7,95,82.3z"
          />
          <path
            fill="#fff"
            d="M119,91.4v-9.7h-14v9.7c0,0.5-0.4,1-0.9,1s-1-0.5-1-1V71c0-0.5,0.4-1,1-1s0.9,0.4,0.9,1v9h14v-9
				c0-0.5,0.4-1,1-1c0.5,0,1,0.4,1,1v20.4c0,0.5-0.4,1-1,1C119.5,92.4,119,91.9,119,91.4z"
          />
          <path
            fill="#fff"
            d="M135.4,91.4V71.9h-6.5c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h14.9c0.5,0,0.9,0.4,0.9,0.9s-0.4,0.9-0.9,0.9
				h-6.5v19.6c0,0.5-0.4,1-1,1C135.9,92.4,135.4,91.9,135.4,91.4z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default LogoIcon;
