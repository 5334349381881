import { useRecoilValue, useSetRecoilState } from 'recoil';

import useSyncCurrentJob from 'src/hooks/currentJobId/useSyncCurrentJob';
import useDiscardAnnotations from 'src/hooks/tasks/useDiscardAnnotations';
import useAlert from 'src/hooks/useAlert';
import { ClientError, ClientErrorCode } from 'src/http/client-error';
import jobIdListState from 'src/states/jobIdList';

type Return = () => Promise<void>;

const useMoveToFirstJob = (): Return => {
  const jobIds = useRecoilValue(jobIdListState.current);
  const setCurrentJobId = useSetRecoilState(jobIdListState.currentJobId);
  const checkDiscard = useDiscardAnnotations();
  const syncCurrentJob = useSyncCurrentJob();

  const { open: openAlert } = useAlert();

  return async () => {
    try {
      const [firstJobId] = jobIds;
      if (!firstJobId) {
        throw new ClientError({
          code: ClientErrorCode.INVALID_FIRST_JOB_ID,
        });
      }
      // in case of cpc job: user should not be able to move without
      // saving changes in both windows (this & other)
      // this condition ensures for the other window
      const discard = await checkDiscard();
      if (!discard) return;
      await syncCurrentJob(firstJobId);
      setCurrentJobId(firstJobId);
    } catch (error) {
      openAlert({
        type: 'error',
        message: `Failed move to first job : ${(error as ClientError).message}`,
      });
    }
  };
};

export default useMoveToFirstJob;
