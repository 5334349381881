import { ViewPresetIconColor, ViewPresetIconProps } from 'src/interfaces/ui';

const MatrixFourViewerIcon = ({
  status = 'ENABLED',
}: ViewPresetIconProps): JSX.Element => (
  <svg viewBox="0 0 32 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.895431 24 2 24H30C31.1046 24 32 23.1046 32 22V2C32 0.895431 31.1046 0 30 0H2ZM3 3H15H17H29V11V13V21H17H15H3V13V11V3ZM27 5V11H17V5H27ZM15 5V11H5V5H15ZM17 13H27V19H17V13ZM15 13V19H5V13H15Z"
      fill={ViewPresetIconColor[status]}
    />
  </svg>
);

export default MatrixFourViewerIcon;
