import { useCallback } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Label } from 'src/interfaces';
import { jobState } from 'src/states/job';
import { taskState } from 'src/states/task';
import FindingUtils from 'src/utils/finding';

import useConfirmFinding from './useConfirmFinding';

export type SetLabelFunction = (label: Label, index: number) => void;

const useSetFindingLabel = (): SetLabelFunction => {
  const confirmFinding = useConfirmFinding();

  const currentFindingAssets = useRecoilValue(taskState.currentFindingAssets);
  const currentFindingIndex = useRecoilValue(taskState.findingIndex);
  const setLocalFindings = useSetRecoilState(taskState.localFindings);
  const setIsAnnotating = useSetRecoilState(jobState.isAnnotating);

  return useCallback(
    (newLabel: Label, newLabelIndex: number) => {
      if (currentFindingIndex === undefined) {
        return;
      }

      setLocalFindings(prev => {
        const array = [...prev];
        const targetFinding = prev.find(
          ({ index }) => index === currentFindingIndex
        );
        const targetAsset = currentFindingAssets.find(
          ({ name }) => name === newLabel.name
        );
        const normalizedValue = (() => {
          if (typeof newLabel.value === 'object') {
            return Object.keys(newLabel.value).filter(
              key => !!(newLabel.value as Record<string, boolean>)[key]
            );
          }
          return [newLabel.value];
        })();

        if (!targetFinding?.labels || !targetAsset) {
          return prev;
        }

        const newLabels = (() => {
          const labels = [...targetFinding.labels];
          if (newLabelIndex >= 0) {
            // Replace label with newly selected one.
            labels[newLabelIndex] = newLabel;
          } else {
            labels.push(newLabel);
          }
          return labels;
        })()
          // For resetting value of the children labels in different parent
          // with the currently selected label.
          .map(label => {
            // Don't reset if currently selected label is child label.
            if (targetAsset.parent) {
              return label;
            }
            // Find matched asset with the current label.
            const matchedAsset = currentFindingAssets.find(
              ({ name }) => name === label.name
            );
            // Don't reset if current label is not a child asset.
            if (!matchedAsset || !matchedAsset.parent) {
              return label;
            }
            // Don't reset if current label is child of the current selected label.
            if (
              JSON.stringify(matchedAsset.parent.selectedValues) ===
              JSON.stringify(normalizedValue)
            ) {
              return label;
            }
            // Reset the value.
            return {
              ...label,
              value: FindingUtils.getDefaultLabelValue(matchedAsset),
            };
          });

        array[currentFindingIndex - 1] = {
          ...targetFinding,
          labels: newLabels,
        };

        return array;
      });

      // Make finding unconfirmed when label has changed.
      confirmFinding(currentFindingIndex, false);
      setIsAnnotating(true);
    },
    [
      confirmFinding,
      currentFindingAssets,
      currentFindingIndex,
      setIsAnnotating,
      setLocalFindings,
    ]
  );
};

export default useSetFindingLabel;
