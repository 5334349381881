import {
  ClaimAnnotationResponse,
  ClaimCategorySchema,
  SelectorAttributes,
  FindingSelectorLabel,
  FindingTexterLabel,
  FindingTogglerLabel,
  Finding as RemoteFinding,
} from '@lunit-io/radiology-data-interface';
import { nanoid } from 'nanoid';

import { dataApi } from 'src/http';
import { ClientError, ClientErrorCode } from 'src/http/client-error';
import {
  Claim,
  RemoteJob,
  LocalFinding,
  Label,
  ListJobResponse,
  Asset,
  SelectorAsset,
  LocalJob,
  getDefaultJob,
  CaseAPIResponse,
} from 'src/interfaces';
import { IssueReadSchema } from 'src/interfaces/issue';
import FindingUtils from 'src/utils/finding';
import { ensure } from 'src/utils/typeHelper';

type AnnotationAPIResponse = Pick<
  ClaimAnnotationResponse,
  'findings' | 'labels'
>;

type JobProps = {
  claim: Claim;
  jobIndex?: number; // do we need it
  jobId: string;
};

/**
 * Get case in a certain job
 *
 * @param jobId string
 * @returns case CaseAPIResponse
 */
const getJobCase = async (jobId: string): Promise<CaseAPIResponse> => {
  const { data } = await dataApi.get<CaseAPIResponse>(`jobs/${jobId}/case/`);

  return data;
};

/**
 * Get annotation in a certain job
 *
 * @param jobId string
 * @returns annotation AnnotationAPIResponse
 */
const getJobAnnotation = async (
  jobId: string,
  claim: Claim
): Promise<AnnotationAPIResponse> => {
  const response = await dataApi.get<AnnotationAPIResponse>(
    `jobs/${jobId}/annotation/`
  );
  const { data } = response;
  const savedFindings = data?.findings;
  if (!savedFindings) return data;

  /**
   * This adds default labels to saved findings to allow proper label syncing. It's a temporary solution
   * until we start using the ontology system. For background, see below or RAD-4654:
   * When BE returns saved findings, they don't include ALL the possible labels. They only include
   * the labels that were saved by the annotator. This causes issues when syncing findings
   * because syncing requires both findings to have labels with matching names.
   */
  const savedFindingsWithDefaultLabelsAdded = savedFindings.map(finding => {
    const defaultLabels = FindingUtils.getDefaultLabels(
      finding.shape,
      claim.assets
    );

    const updatedLabels = defaultLabels.map(defaultLabel => {
      if (finding.labels === undefined) return defaultLabel;

      const savedLabel = finding.labels.find(
        ({ name }) => name === defaultLabel.name
      );
      return savedLabel || defaultLabel;
    });
    return { ...finding, labels: updatedLabels };
  });
  return { ...data, findings: savedFindingsWithDefaultLabelsAdded };
};

/**
 *
 * @param assets (of claim)
 * @param label (can be annotation label or prediction label)
 * @returns alias for a selector finding (polygon)
 */
export const getAliasForFinding = (
  assets: Asset[],
  label: FindingTogglerLabel | FindingTexterLabel | FindingSelectorLabel
): string => {
  // !info: make sure label always exist
  if (!label) {
    return '';
  }

  const selectorAssets = assets.filter(
    asset =>
      !!((asset as SelectorAsset).formAttributes as SelectorAttributes)
        ?.categories
  ) as SelectorAsset[];

  const categories: ClaimCategorySchema[] =
    selectorAssets.flatMap<ClaimCategorySchema>(
      ({ formAttributes }) => (formAttributes as SelectorAttributes).categories
    );

  if (!categories.length) return '';

  const [findingType] = Object.keys(label.value).filter(
    type => typeof label.value === 'object' && label.value[type] === true
  );

  const finding = categories.find(
    cat => cat.name === findingType
  ) as ClaimCategorySchema;

  return finding?.alias || '';
};

/**
 * Add additional fields and values in the LocalFinding to the RemoteFinding
 */
const setLocalFindingFields =
  (claim: Claim) =>
  (finding: RemoteFinding, i: number): LocalFinding => {
    const nextIndex = i + 1;
    const { assets } = claim;
    const label = finding.labels?.[0];

    return {
      ...finding,
      index: nextIndex,
      group: !!finding.group ? finding.group : nanoid(5),
      alias: !!label ? getAliasForFinding(assets, label) : '',
    };
  };

interface EnqueueJobProps {
  projectId: string;
  /**
   * Call focus API after enqueue. This is useful when annotator start the initial project,
   * which means no assigned job at all. Basically, setFocus determines whether to set or
   * not `currentJobId` on the project (most of the time it is true)
   */
  setFocus?: boolean;
}

/**
 * happens in multiple scenarios:
 *  - when user hits the last completed job and tries to get next job (if he still has assigned jobs)
 *  - when user has assigned jobs and he accesses the first time
 *
 * @returns Last Enqueued Job
 */
export const enqueueSingleJob = async ({
  projectId,
  setFocus = false,
}: EnqueueJobProps): Promise<RemoteJob> => {
  const response = await dataApi.patch<ListJobResponse>(
    `projects/${projectId}/jobs/enqueue/`,
    { count: 1 },
    { params: { set_focus: setFocus } }
  );
  if (!response || response.data === null || !response.data.success[0]) {
    throw new ClientError({ code: ClientErrorCode.NO_JOBS_TO_ENQUEUE });
  }

  return response.data.success[0];
};

/**
 * Get Job
 *
 * @returns getJob Function
 */
export const getJob = async ({ claim, jobId }: JobProps): Promise<LocalJob> => {
  // TODO: fix get return type as T | null
  const { data: apiJob } = await dataApi.get<RemoteJob>(`jobs/${jobId}/`);

  if (!apiJob) {
    throw new ClientError({
      code: ClientErrorCode.INVALID_JOB,
      message: `No job has been found with given ID : ${jobId}`,
    });
  }

  // Step 2: If it is reported, throw error for not to request more.
  if (apiJob.reported) {
    return {
      ...getDefaultJob({ type: claim.viewer.type, images: [] }, []),
      reported: apiJob.reported,
    };
  }

  // Step 3: Get detail information of the job
  const [caseResponse, annotationResponse] = await Promise.all([
    getJobCase(apiJob.id),
    getJobAnnotation(apiJob.id, claim),
  ]);

  // TODO: add explanation when this happens under which circumstances
  claim.modes.forEach(mode => {
    if (!caseResponse.findings[mode.name]) {
      throw new ClientError({
        code: ClientErrorCode.INVALID_CASE,
        message:
          'Incomplete case response given in labels. Not all modes are filled.',
      });
    }
  });

  // Step 4: Fetch server's labels response to client's labels
  // modes: [annotation, prediction]
  const labels = claim.modes.reduce((acc: Record<string, Label[]>, mode) => {
    acc[mode.name] =
      mode.isEditable && !!annotationResponse
        ? annotationResponse.labels
        : ensure(caseResponse.labels[mode.name]);
    return acc;
  }, {});
  // final result: { annotation: [...], prediction: [...], }

  // Step 5: Fetch server's findings response to client's findings
  const findings = claim.modes.reduce(
    (acc: { [k: string]: LocalFinding[] }, mode) => {
      acc[mode.name] = (
        mode.isEditable && !!annotationResponse
          ? annotationResponse.findings.map(setLocalFindingFields(claim))
          : ensure(caseResponse.findings[mode.name]).map(
              setLocalFindingFields(claim)
            )
      ).map(FindingUtils.addConfirmedField);
      return acc;
    },
    {}
  );

  // in case of cpc project: this can be prior job if accessed on `/prior` route
  return {
    id: apiJob.id,
    images: caseResponse.images,
    metadata: caseResponse.metadata,
    findings,
    labels,
    report: caseResponse.report,
    annotationExists: !!annotationResponse,
    reported: apiJob.reported,
    patientId: caseResponse.patientId,
    studyDate: caseResponse.studyDate,
  };
};

export type JobListItem = {
  id: string;
  reported: boolean;
  pairJob?: string;
  pairType?: 'PairIndex' | 'PairPrior' | 'Normal';
  reading?: string;
  flagged?: boolean;
  findingCounts?: {
    box: number;
    point: number;
    line: number;
    polygon: number;
    multiFramePolygon: number;
  };
  issues?: IssueReadSchema[];
  completed: boolean;
};

export type PairedJobListItem = {
  index: JobListItem;
  prior: JobListItem | null; // exist only for CPC jobs
};
interface RemotePairedJobs {
  jobs: PairedJobListItem[];
}
interface RemoteJobs {
  jobs: JobListItem[];
}

export type GetAllJobIdsByProjectProps = {
  projectId: string;
  annotatorId: string;
};

const commonParamsOfJobList = {
  page_size: 1000000, // yee we need to provide this workaround due to BE request
  page: 1,
  fields: [
    'id',
    'reported',
    'reading',
    'flagged',
    'findingCounts',
    'issues',
    'completed',
  ],
  states: ['queued', 'completed', 'reported'],
  sort: ['+queuedAt', '+createdAt'],
};

export const getPairedJobList = async ({
  projectId,
  annotatorId,
}: GetAllJobIdsByProjectProps): Promise<PairedJobListItem[]> => {
  const response = await dataApi.get<RemotePairedJobs>(
    `/v2/projects/${projectId}/pair-jobs/`,
    {
      params: {
        ...commonParamsOfJobList,
        annotator: annotatorId,
      },
    }
  );

  return response?.data.jobs;
};

export const getJobList = async ({
  projectId,
  annotatorId,
}: GetAllJobIdsByProjectProps): Promise<JobListItem[]> => {
  const response = await dataApi.get<RemoteJobs>(
    `/v2/projects/${projectId}/jobs/`,
    {
      params: {
        ...commonParamsOfJobList,
        annotator: annotatorId,
      },
    }
  );

  return response?.data.jobs;
};

export const changeJobFlag = async ({
  jobId,
  flag,
}: {
  jobId?: string;
  flag: boolean;
}): Promise<void> => {
  if (!jobId) return;
  if (flag) {
    await dataApi.put(`jobs/${jobId}/flag`, {});
  } else {
    await dataApi.delete(`jobs/${jobId}/flag`);
  }
};
