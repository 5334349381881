import queryString from 'query-string';

import { HOSTS } from 'src/configs/hosts';
import { HTTP_METHODS } from 'src/http';

interface PostLogParam {
  '@timestamp'?: string; // Timestamp of the event in RFC 3339 format
  timestamp?: number;
  environment?: 'dev' | 'stg' | 'prod' | 'unknown';
  level_name: 'DEBUG' | 'INFO' | 'WARN' | 'ERROR';
  app_name?: 'ctl-frontend';
  logger?: string;
  message?: string;
  http_version?: string;
  method?: HTTP_METHODS;
  path?: string;
  status_code?: number;
  status_phrase?: string;
  user_id?: string;
  username?: string;
  request_body?: string;
  response_body?: string;
  header?: Record<string, unknown>;
  query?: Record<string, unknown>;
  error_message?: string;
  exc_info?: string;
  process_time_seconds?: number;
  path_name?: string;
  line_number?: number;
  function_name?: string;
  client_addr?: string;
  request_id?: string;
}

/**
 * postLog
 * @see https://lunit-confluence.atlassian.net/wiki/spaces/AOP/pages/2090500399/ES+Endpoint+for+Frontend
 * @param params PostLogParam
 */
export const postLog = async (params: PostLogParam): Promise<void> => {
  if (HOSTS.CTL_ELASTIC_SEARCH === '') {
    return;
  }

  const environment = ((): 'dev' | 'stg' | 'prod' | 'unknown' => {
    switch (process.env.REACT_APP_DEPLOYMENT_PHASE) {
      case 'production':
        return 'prod';
      case 'stage':
        return 'stg';
      case 'development':
        return 'dev';
      default:
        return 'unknown';
    }
  })();

  const date = new Date();

  // Remove authorization header for security
  const header = (() => {
    if (!params.header) {
      return {};
    }
    return Object.keys(params.header)
      .filter(key => key !== 'Authorization')
      .reduce((prev, cur) => {
        return {
          ...prev,
          [cur]: params.header?.[cur],
        };
      }, {});
  })();

  // 1. Remove queries from path
  // 2. Make query object from removed queries
  const { path, query } = (() => {
    const splitPath = params.path?.split('?');
    const path = splitPath?.[0] ?? params.path;
    const lastPathParam = splitPath?.[splitPath.length - 1];
    const query = !lastPathParam
      ? {}
      : queryString.parse(lastPathParam, {
          arrayFormat: 'comma',
          parseNumbers: true,
          parseBooleans: true,
        });

    return { path, query };
  })();

  const body: PostLogParam = {
    ...params,
    environment,
    '@timestamp': date.toISOString(),
    app_name: 'ctl-frontend',
    header,
    path,
    query,
  };

  const url = `${HOSTS.CTL_ELASTIC_SEARCH}/ctl-logs/_doc`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: process.env.REACT_APP_ES_API_KEY || '',
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    console.error('Post FE logging API has failed.', response.json() || '');
  }
};
