import { useState } from 'react';

import { CornerstoneRenderData } from '@InsightViewer/types';

export interface InsightViewerGuestProps {
  cornerstoneRenderData: CornerstoneRenderData | null;
}

export interface InsightViewerSyncProps {
  cornerstoneRenderData: CornerstoneRenderData | null;
  updateCornerstoneRenderData: (eventData: CornerstoneRenderData) => void;
}

export function useInsightViewerSync(): InsightViewerSyncProps {
  const [cornerstoneRenderData, setCornerstoneRenderData] =
    useState<CornerstoneRenderData | null>(null);

  return {
    cornerstoneRenderData,
    updateCornerstoneRenderData: setCornerstoneRenderData,
  };
}
