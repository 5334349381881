import { PropsWithChildren } from 'react';

import { createPortal } from 'react-dom';

export const getRightBottomHolderPortalId = (source: number | string): string =>
  `dbt-viewer-right-bottom-holder-${source}`;

interface Props {
  view: string;
}

const RightBottomHolderPortal = ({
  view,
  children,
}: PropsWithChildren<Props>): JSX.Element | null => {
  const element = document.getElementById(getRightBottomHolderPortalId(view));
  return element ? createPortal(children, element) : null;
};

export default RightBottomHolderPortal;
