/* eslint-disable @typescript-eslint/no-var-requires */
export enum Host {
  CTL_AUTHENTICATOR_SERVER = 'CTL_AUTHENTICATOR_SERVER',
  CTL_DATA_SERVER = 'CTL_DATA_SERVER',
  CTL_IMAGE_SERVER = 'CTL_IMAGE_SERVER',
  CTL_ELASTIC_SEARCH = 'CTL_ELASTIC_SEARCH',
}

export type Hosts = Record<Host, string>;

export const HOSTS: Hosts =
  require(`./${process.env.REACT_APP_DEPLOYMENT_PHASE}.ts`).default;
