import { isTouchDevice } from '@lunit/is-touch-device';
import { Viewport, vec2 } from 'cornerstone-core';

interface ZoomInteractionParams {
  element: HTMLElement;
  getMinMaxScale: () => [number, number];
  getCurrentViewport: () => Viewport | null;
  onZoom: (patch: { translation: vec2; scale: number }) => void;
  contentWindow: Window;
}

export function startZoomClickAndDragInteraction({
  element,
  getMinMaxScale,
  getCurrentViewport,
  onZoom,
  contentWindow,
}: ZoomInteractionParams): () => void {
  let yDirection: 'up' | 'down';
  let oldY: number;

  function startTrigger() {
    element.addEventListener('mousedown', mouseStart);
    if (isTouchDevice()) {
      element.addEventListener('touchstart', touchStart);
    }
  }

  function stopTrigger() {
    element.removeEventListener('mousedown', mouseStart);
    element.removeEventListener('touchstart', touchStart);
  }

  // ---------------------------------------------
  // touch handler
  // ---------------------------------------------
  function touchStart(event: TouchEvent) {
    if (event.targetTouches.length > 1) {
      contentWindow.removeEventListener('touchmove', touchMove);
      contentWindow.removeEventListener('touchend', touchEnd);
      contentWindow.removeEventListener('touchcancel', touchEnd);

      startTrigger();

      return;
    }

    if (event.targetTouches.length !== 1) return;

    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();

    const viewport = getCurrentViewport();
    if (!viewport || !event.targetTouches[0]) return;

    stopTrigger();

    contentWindow.addEventListener('touchmove', touchMove);
    contentWindow.addEventListener('touchend', touchEnd);
    contentWindow.addEventListener('touchcancel', touchEnd);
  }

  function touchMove(event: TouchEvent) {
    if (event.targetTouches.length !== 1 || event.changedTouches.length !== 1) {
      contentWindow.removeEventListener('touchmove', touchMove);
      contentWindow.removeEventListener('touchend', touchEnd);
      contentWindow.removeEventListener('touchcancel', touchEnd);

      startTrigger();

      return;
    }

    event.stopPropagation();
    event.stopImmediatePropagation();

    const viewport = getCurrentViewport();
    if (
      !viewport ||
      !viewport.scale ||
      !viewport.translation ||
      !event.targetTouches[0]
    ) {
      return;
    }

    const [minScale, maxScale] = getMinMaxScale();

    if (oldY < event.targetTouches[0].pageY) {
      yDirection = 'up';
    } else {
      yDirection = 'down';
    }
    oldY = event.targetTouches[0].pageY;

    const delta = 0.005 * (yDirection === 'up' ? 1 : -1);

    const nextScale = Math.max(
      minScale,
      Math.min(maxScale, viewport.scale - delta)
    );

    if (viewport.scale === nextScale) return;

    const { top, left, width, height } = element.getBoundingClientRect();

    const distanceX: number = event.targetTouches[0].pageX - (left + width / 2);
    const distanceY: number = event.targetTouches[0].pageY - (top + height / 2);
    const dx: number = (1 - nextScale / viewport.scale) * distanceX;
    const dy: number = (1 - nextScale / viewport.scale) * distanceY;

    onZoom({
      translation: {
        x: viewport.translation.x + dx / nextScale,
        y: viewport.translation.y + dy / nextScale,
      },
      scale: nextScale,
    });
  }

  function touchEnd(event: TouchEvent) {
    contentWindow.removeEventListener('touchmove', touchMove);
    contentWindow.removeEventListener('touchend', touchEnd);
    contentWindow.removeEventListener('touchcancel', touchEnd);

    startTrigger();
  }

  // ---------------------------------------------
  // mouse handler
  // ---------------------------------------------
  function mouseStart(event: MouseEvent) {
    if (event.button !== 0) return;

    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();

    const viewport = getCurrentViewport();

    if (!viewport || !viewport.scale || !viewport.translation) {
      return;
    }

    stopTrigger();

    contentWindow.addEventListener('mousemove', mouseMove);
    contentWindow.addEventListener('mouseup', mouseEnd);
    element.addEventListener('mouseleave', mouseEnd);
  }

  function mouseMove(event: MouseEvent) {
    event.stopPropagation();
    event.stopImmediatePropagation();

    const viewport = getCurrentViewport();

    if (!viewport || !viewport.scale || !viewport.translation) {
      return;
    }

    const [minScale, maxScale] = getMinMaxScale();

    if (oldY < event.pageY) {
      yDirection = 'up';
    } else {
      yDirection = 'down';
    }
    oldY = event.pageY;

    const delta = 0.005 * (yDirection === 'up' ? 1 : -1);

    const nextScale = Math.max(
      minScale,
      Math.min(maxScale, viewport.scale - delta)
    );

    if (viewport.scale === nextScale) return;

    const { top, left, width, height } = element.getBoundingClientRect();

    const distanceX: number = event.pageX - (left + width / 2);
    const distanceY: number = event.pageY - (top + height / 2);
    const dx: number = (1 - nextScale / viewport.scale) * distanceX;
    const dy: number = (1 - nextScale / viewport.scale) * distanceY;

    onZoom({
      translation: {
        x: viewport.translation.x + dx / nextScale,
        y: viewport.translation.y + dy / nextScale,
      },
      scale: nextScale,
    });
  }

  function mouseEnd(event: MouseEvent) {
    if (event.button !== 0) return;

    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();

    contentWindow.removeEventListener('mousemove', mouseMove);
    contentWindow.removeEventListener('mouseup', mouseEnd);
    element.removeEventListener('mouseleave', mouseEnd);

    startTrigger();
  }

  // ---------------------------------------------
  // start
  // ---------------------------------------------
  startTrigger();

  // ---------------------------------------------
  // end
  // ---------------------------------------------
  return () => {
    element.removeEventListener('mousedown', mouseStart);
    element.removeEventListener('touchstart', touchStart);

    contentWindow.removeEventListener('mousemove', mouseMove);
    contentWindow.removeEventListener('mouseup', mouseEnd);
    element.removeEventListener('mouseleave', mouseEnd);

    contentWindow.removeEventListener('touchmove', touchMove);
    contentWindow.removeEventListener('touchend', touchEnd);
    contentWindow.removeEventListener('touchcancel', touchEnd);
  };
}
