import { useCallback } from 'react';

import { useSetRecoilState } from 'recoil';

import { CPCBroadcastChannel } from 'src/hooks/cpc/useSyncCPCState';
import { ConfirmModalState, TRANSITION_DURATION } from 'src/interfaces';
import { confirmModalState } from 'src/states/confirmModal';
import { DEFAULT_CONFIRM_MODAL_STATE } from 'src/states/defaults';
import { thisCPCWindow } from 'src/utils/cpc';

type CallbackFunc = (confirmation: boolean) => void;
type GetConfirmationFuncProps = Pick<
  ConfirmModalState,
  'title' | 'description' | 'confirmButtonText'
>;
type GetConfirmationFunc = (
  props: GetConfirmationFuncProps
) => Promise<boolean>;

interface Return {
  handleConfirm: () => void;
  handleCancel: () => void;
  getConfirmation: GetConfirmationFunc;
}

let callback: CallbackFunc = () => {};

/**
 * Asynchronous confirmation hook
 *
 * @see https://dev.to/metamodal/control-a-dialog-box-asynchronously-using-react-hooks-4ik7
 * @returns getConfirmation
 */
const useConfirmModal = (): Return => {
  const setState = useSetRecoilState(confirmModalState);

  const reset = () => {
    setState(DEFAULT_CONFIRM_MODAL_STATE);
    CPCBroadcastChannel.postMessage({
      type: 'requestStateUpdateFromOtherCPCCase',
      origin: thisCPCWindow.name,
      payload: 'checkForDuplicateCPCWindow',
    });
    callback = () => {};
  };

  const open = useCallback(
    (state: GetConfirmationFuncProps, callbackFunc: CallbackFunc) => {
      setState({
        ...DEFAULT_CONFIRM_MODAL_STATE,
        ...state,
        visibility: true,
      });
      callback = callbackFunc;
    },
    [setState]
  );

  const close = () => {
    setState(prev => ({
      ...prev,
      visibility: false,
    }));
    setTimeout(reset, TRANSITION_DURATION);
  };

  const handleConfirm = () => {
    close();
    callback(true);
  };

  const handleCancel = () => {
    close();
    callback(false);
  };

  const getConfirmation = useCallback<GetConfirmationFunc>(
    state =>
      new Promise<boolean>(callback => {
        open(state, callback);
      }),
    [open]
  );

  return {
    handleConfirm,
    handleCancel,
    getConfirmation,
  };
};

export default useConfirmModal;
