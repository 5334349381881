import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

import { CPCCaseChip } from 'src/components/viewers/cpc/CPCCaseChip';
import { OpenOtherCPCCaseButton } from 'src/components/viewers/cpc/OpenOtherCPCCaseButton';

export function CPCViewerHeader(): JSX.Element {
  return (
    <>
      <StyledCPCViewerHeader>
        <CPCCaseChip />
        <OpenOtherCPCCaseButton />
      </StyledCPCViewerHeader>
      <Divider />
    </>
  );
}

const StyledCPCViewerHeader = styled('div')`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  position: relative;
  padding: 8px;
  justify-content: space-between;
`;
