import { AlertProps } from '@mui/material/Alert';

import { ControlName } from './project';

export declare type Control = 'none' | ControlName;

export const TRANSITION_DURATION = 200;

export interface AlertState {
  visibility: boolean;
  message: string;
  autoHide?: boolean;
  type: AlertProps['severity'];
}

export interface ConfirmModalState {
  visibility: boolean;
  title: string;
  description: string;
  confirmButtonText?: string;
}

export interface Offset {
  x: number;
  y: number;
}

export enum ViewPresetIconColor {
  DISABLED = '#242E3E',
  ACTIVE = '#00C9EA',
  ENABLED = '#FFF',
}

export type ViewPresetIconStatus = keyof typeof ViewPresetIconColor;

export interface ViewPresetIconProps {
  status?: ViewPresetIconStatus;
}
