import { useCallback } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { CPCBroadcastChannel } from 'src/hooks/cpc/useSyncCPCState';
import { LocalJob } from 'src/interfaces';
import { operationModeState } from 'src/states/operationMode';
import { projectState } from 'src/states/project';
import { taskState } from 'src/states/task';
import { thisCPCWindow } from 'src/utils/cpc';

const useInitTask = (): ((job: LocalJob) => void) => {
  const setLocalFindings = useSetRecoilState(taskState.localFindings);
  const setLocalDecisionLabels = useSetRecoilState(
    taskState.localDecisionLabels
  );
  const isCPCProject = useRecoilValue(projectState.isCPC);

  // Some cache for modes
  const editableMode = useRecoilValue(operationModeState.editable);
  const setShowFindings = useSetRecoilState(taskState.showFindings);

  const initTask = useCallback(
    (job: LocalJob) => {
      const findings = job.findings[editableMode.name] || [];
      const labels = job.labels[editableMode.name] || [];
      setShowFindings(true);
      setLocalFindings(findings);
      setLocalDecisionLabels(labels);

      if (isCPCProject) {
        CPCBroadcastChannel.postMessage({
          type: 'requestStateUpdateFromOtherCPCCase',
          origin: thisCPCWindow.name,
          payload: 'isAnnotating',
        });
      }
    },
    [
      editableMode.name,
      setShowFindings,
      setLocalFindings,
      setLocalDecisionLabels,
      isCPCProject,
    ]
  );

  return initTask;
};

export default useInitTask;
