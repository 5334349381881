import { useRecoilValue } from 'recoil';

import OpenInNew from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';

import useDiscardAnnotations from 'src/hooks/tasks/useDiscardAnnotations';
import { jobState } from 'src/states/job';
import { otherCPCWindow } from 'src/utils/cpc';

export function OpenOtherCPCCaseButton(): JSX.Element {
  const isOtherCPCWindowAnnotating = useRecoilValue(
    jobState.isOtherCPCWindowAnnotating
  );
  const checkDiscard = useDiscardAnnotations();

  const handleOpenCPCWindow = async () => {
    const openOtherCPCWindow = () =>
      window.open(otherCPCWindow.pathname, otherCPCWindow.name);

    if (!isOtherCPCWindowAnnotating) return openOtherCPCWindow();

    const discard = await checkDiscard();
    if (discard) return openOtherCPCWindow();
  };

  return (
    <Button
      variant="outlined"
      size="small"
      onClick={handleOpenCPCWindow}
      endIcon={<OpenInNew />}
      sx={{ lineHeight: 'unset' }}
    >
      Open {otherCPCWindow.label}
    </Button>
  );
}
