import { useRef, useState, MouseEventHandler } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Button from '@mui/material/Button';
import ClickAwayListener, {
  ClickAwayListenerProps,
} from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { TransitionProps as TransitionPropsType } from '@mui/material/transitions';

interface Props {
  onEdit?: () => void;
  onDelete?: () => void;
  onCloseIssue?: () => void;
  deleted?: boolean;
  closed?: boolean;
  disableEdit?: boolean;
  disableDelete?: boolean;
  isFirstUpdate?: boolean;
}

const options = ['Edit', 'Delete'];

const IssueActions = ({
  onDelete = () => {},
  onEdit = () => {},
  onCloseIssue = () => {},
  deleted,
  closed,
  disableEdit,
  disableDelete,
  isFirstUpdate,
}: Props): JSX.Element => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleToggle: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    setOpen(prev => !prev);
  };

  const handleClose: ClickAwayListenerProps['onClickAway'] = event => {
    if (
      anchorRef.current &&
      event.target &&
      anchorRef.current.contains(event.target as Node)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick: (
    option: string
  ) => MouseEventHandler<HTMLLIElement> = (option: string) => e => {
    e.stopPropagation();
    switch (option) {
      case 'Edit':
        onEdit();
        break;
      case 'Delete':
        onDelete();
        break;
      default:
        break;
    }
    setOpen(false);
  };

  const getDisabled = (option: string): boolean => {
    switch (option) {
      case 'Edit':
        return !!disableEdit;
      case 'Delete':
        return !!isFirstUpdate || !!disableDelete;
      default:
        return false;
    }
  };

  const disabled = deleted || (disableEdit && disableDelete);

  const availableOptions = options.filter(
    opt => !(opt === 'Close' && !isFirstUpdate)
  );

  const handleToggleClose: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    onCloseIssue();
  };

  return (
    <Container ref={anchorRef}>
      {isFirstUpdate && (
        <StyledButton value="close" onClick={handleToggleClose}>
          {closed ? (
            <Tooltip title="Reopen issue">
              <CheckBoxIcon fontSize="small" />
            </Tooltip>
          ) : (
            <Tooltip title="Close issue">
              <CheckBoxOutlineBlankIcon fontSize="small" />
            </Tooltip>
          )}
        </StyledButton>
      )}

      <StyledButton
        size="small"
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={handleToggle}
        variant="text"
        disabled={disabled}
      >
        <MoreHorizIcon fontSize="small" />
      </StyledButton>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }: { TransitionProps: TransitionPropsType }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'right top',
              backgroundColor: 'var(--ctl-background-color-light)',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {availableOptions.map(option => (
                    <MenuItem
                      key={option}
                      onClick={handleMenuItemClick(option)}
                      disabled={getDisabled(option)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </Container>
  );
};

export default IssueActions;

const Container = styled('div')``;

const StyledButton = styled(Button)`
  color: var(--ctl-color-active);
  min-width: auto;
`;

const StyledPopper = styled(Popper)`
  z-index: 1;
`;
