import { useRecoilValueLoadable } from 'recoil';

import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import useMoveToPrevJob from 'src/hooks/currentJobId/useMoveToPrevJob';
import { jobState } from 'src/states/job';

interface Props {
  editIndexMode: boolean;
}

const GoToPrevJobButton = ({ editIndexMode }: Props): JSX.Element => {
  const moveToPrevJob = useMoveToPrevJob();

  const canGoPrevJobLoadable = useRecoilValueLoadable(jobState.canGoPrev);
  const canGoPrevJob =
    canGoPrevJobLoadable.state === 'hasValue' && canGoPrevJobLoadable.contents;

  return (
    <Tooltip title="Prev">
      <span>
        <IconButton
          disabled={!canGoPrevJob || editIndexMode}
          onClick={moveToPrevJob}
          size="large"
          data-test-id="btn-prev-job"
        >
          <ArrowBackIos />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default GoToPrevJobButton;
