import { RecoilState, useRecoilState } from 'recoil';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

type PreloadingQtySettingItemProps = {
  heading: string;
  description: string;
  recoilState: RecoilState<number>;
  defaultValue: number;
};

const PreloadingQtySettingItem = ({
  heading,
  description,
  recoilState,
  defaultValue,
}: PreloadingQtySettingItemProps): JSX.Element => {
  const [preloadQty, setPreloadQty] = useRecoilState(recoilState);

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      return;
    }
    setPreloadQty(newValue);
  };

  const handleResetPreloadQty = () => setPreloadQty(defaultValue);

  return (
    <Container>
      <StyledHeading>{heading}</StyledHeading>
      <StyledDescription>{description}</StyledDescription>
      <>
        <Slider
          size="small"
          valueLabelDisplay="on"
          value={preloadQty}
          min={0}
          max={100}
          onChange={handleChange}
        />
        <StyledButton variant="outlined" onClick={handleResetPreloadQty}>
          Reset
        </StyledButton>
      </>
    </Container>
  );
};

export default PreloadingQtySettingItem;

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '10px 0px',
}));

const StyledHeading = styled(Typography)(() => ({
  color: 'var(--ctl-color)',
}));

const StyledDescription = styled(Typography)(() => ({
  margin: '10px 0px 30px 0px',
}));

const StyledButton = styled(Button)(() => ({
  margin: '10px 0px',
}));
