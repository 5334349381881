import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import Progress, { ProgressProps } from './Progress';

const LoadingViewer = ({ ...rest }: ProgressProps): JSX.Element => {
  const { step } = rest;

  return (
    <Container>
      <Wrapper>
        <Text>LOADING {step} ...</Text>
        <Progress {...rest} />
      </Wrapper>
    </Container>
  );
};

const Container = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
`;

const Wrapper = styled('div')`
  flex-direction: column;
  width: 40%;
  min-width: '10rem';
`;

const Text = styled(Typography)`
  margin-bottom: 0.5rem;
  opacity: 0.5;
`;

export default LoadingViewer;
