import { useRecoilValue } from 'recoil';

import LabelPanel from 'src/components/LabelPanel';
import useSetDecisionLabel from 'src/hooks/tasks/useSetDecisionLabel';
import { taskState } from 'src/states/task';

const DecisionLabelPanel = (): JSX.Element => {
  const decisionAssets = useRecoilValue(taskState.decisionAssets);
  const decisionLabels = useRecoilValue(taskState.decisionLabels);
  const handleChangeDecisionLabel = useSetDecisionLabel();

  return (
    <LabelPanel
      title="Labels for Current Job"
      assets={decisionAssets}
      labels={decisionLabels}
      onChangeLabel={handleChangeDecisionLabel}
      isCollapsible={true}
      isDraggable={false}
    />
  );
};

export default DecisionLabelPanel;
