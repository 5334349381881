import { Resizable } from 're-resizable';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { taskState } from 'src/states/task';

import {
  ScrollContainer,
  ScrollContainerProps,
} from './opt-components/ScrollContainer';

export interface SideBarProps extends ScrollContainerProps {
  className?: string;
}

export function SideBarBase({
  ref,
  children,
  css,
  ...scrollContainerProps
}: SideBarProps): JSX.Element {
  const [sidebarWidth, setSidebarWidth] = useRecoilState(
    taskState.sidebarWidth
  );
  return (
    <Resizable
      defaultSize={{ width: sidebarWidth }}
      minWidth="20%"
      maxWidth="50%"
      onResizeStop={(e, dir, ref, d) => {
        setSidebarWidth(ref.offsetWidth);
      }}
      handleStyles={{
        left: { height: '100%' },
      }}
      enable={{
        left: true,
      }}
    >
      <ScrollContainer {...scrollContainerProps}>{children}</ScrollContainer>
    </Resizable>
  );
}

export const SideBar = styled(SideBarBase)`
  position: relative;
  background-color: var(--ctl-background-color-light);
  padding-bottom: 20px;
`;
