import { memo, ReactNode } from 'react';

import AsideItem from 'src/components/aside/AsideItem';

interface Props {
  children?: ReactNode;
  title?: string;
  isDraggable: boolean;
  isCollapsible?: boolean;
}

const ExpandableCategoryLabelPanel = ({
  children,
  title,
  isDraggable,
  isCollapsible,
}: Props): JSX.Element => {
  return (
    <AsideItem
      label={title || ''}
      isDraggable={isDraggable}
      isCollapsible={isCollapsible}
      isCategory
    >
      {children}
    </AsideItem>
  );
};

export default memo(ExpandableCategoryLabelPanel);
