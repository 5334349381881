import { selector } from 'recoil';

import { getUsers } from 'src/services/user';

const users = selector({
  key: 'userState/users',
  get: async () => {
    return getUsers();
  },
});

const userState = Object.freeze({
  users,
});

export default userState;
