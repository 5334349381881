import { useCallback } from 'react';

import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';

import { OperationMode } from 'src/interfaces';
import { jobState } from 'src/states/job';
import { operationModeState } from 'src/states/operationMode';
import { taskState } from 'src/states/task';
import FindingUtils from 'src/utils/finding';

const useSetOperationMode = (): ((mode: OperationMode) => void) => {
  const setCurrentMode = useSetRecoilState(operationModeState.current);
  const setFindingIndex = useSetRecoilState(taskState.findingIndex);
  const localFindings = useRecoilValue(taskState.localFindings);

  const currentFinding = useRecoilValueLoadable(taskState.currentFinding);
  const jobLoadable = useRecoilValueLoadable(jobState.current);

  const setOperationMode = useCallback(
    (mode: OperationMode) => {
      if (currentFinding.state === 'hasValue' && currentFinding.contents) {
        const findings = (() => {
          if (mode.isEditable || jobLoadable.state !== 'hasValue') {
            return localFindings;
          }

          return jobLoadable.contents.findings[mode.name] || [];
        })();

        const findingInNewMode = FindingUtils.getSameFinding(
          currentFinding.contents,
          findings
        );

        if (findingInNewMode) setFindingIndex(findingInNewMode.index);
        else setFindingIndex(undefined);
      } else setFindingIndex(undefined);

      setCurrentMode(mode);
    },
    [
      currentFinding.contents,
      currentFinding.state,
      jobLoadable.contents.findings,
      jobLoadable.state,
      localFindings,
      setCurrentMode,
      setFindingIndex,
    ]
  );

  return setOperationMode;
};

export default useSetOperationMode;
