import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { ControlName, FindingShape } from 'src/interfaces';
import { taskState } from 'src/states/task';

import CollapsedControlButton from './CollapsedControlButton';
import useControlButton from './useControlButton';

const CollapsedControls = ({
  disabled = false,
}: {
  disabled?: boolean;
}): JSX.Element => {
  const controlButtons = useControlButton();
  const showDBT3D = useRecoilValue(taskState.showDBT3D);
  const conditionalDBTControlDisable = (controlName: ControlName) =>
    (controlName === FindingShape.MULTI_FRAME_POLYGON && !showDBT3D) ||
    (controlName === FindingShape.POLYGON && showDBT3D);

  return (
    <Container>
      {controlButtons.map((group, index) => {
        return group.length === 0 ? null : (
          <ItemGroup key={index}>
            {group.map(({ selected, onClick, icon, label, controlName }) => (
              <ItemWrap key={label}>
                <CollapsedControlButton
                  aria-selected={selected}
                  title={label}
                  disabled={
                    disabled || conditionalDBTControlDisable(controlName)
                  }
                  onClick={onClick}
                >
                  {icon}
                </CollapsedControlButton>
              </ItemWrap>
            ))}
          </ItemGroup>
        );
      })}
    </Container>
  );
};

export default CollapsedControls;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const ItemGroup = styled.div`
  display: flex;
  font-size: 1.25rem;
  margin: 0 1px;
  gap: 2px;
`;

const ItemWrap = styled.div`
  display: flex;
`;
