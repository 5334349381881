import List, { ListProps } from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import { styled } from '@mui/material/styles';

interface Props extends ListProps {
  assetText?: string;
  assetAllowEmpty?: boolean;
}

const AssetPanel = (props: Props): JSX.Element => {
  const { children, assetText, assetAllowEmpty = true, ...rest } = props;
  return (
    <StyledList
      {...rest}
      disablePadding
      aria-labelledby="nested-list-subheader"
      subheader={
        (assetText && (
          <AssetPanelHeader id="nested-list-subheader" disableSticky={true}>
            {assetText} {assetAllowEmpty ? '' : '* (required)'}
          </AssetPanelHeader>
        )) ||
        undefined
      }
    >
      {children}
    </StyledList>
  );
};

const StyledList = styled(List)({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '8px',
  margin: '.4rem 0',
  backgroundColor: 'var(--ctl-background-color)',
  borderRadius: 'var(--ctl-border-radius)',
});

const AssetPanelHeader = styled(ListSubheader)({
  boxSizing: 'content-box',
  width: '100%',
  paddingTop: 0,
  paddingBottom: 8,
  lineHeight: 'initial',
  color: 'var(--ctl-color)',
  paddingLeft: 2,
  paddingRight: 2,
});

export default AssetPanel;
