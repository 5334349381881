import cornerstone from 'cornerstone-core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ParallelImageLoader } from './ParallelImageLoader';
import {
  CornerstoneImage,
  getProgressEventDetail,
  ImageLoader,
  ProgressEventDetail,
} from './types';
import { wadoImageLoaderXHRLoader } from './wadoImageLoaderXHRLoader';

interface Options {
  timeout?: number;
  unload?: (imageId: string) => void;
  cancelTokenName?: string;
  loader?: ImageLoader;
  headers?: { [key: string]: string };
}

const defaultLoader: ImageLoader = new ParallelImageLoader();

export class CornerstoneSingleImage implements CornerstoneImage {
  private readonly _imageSubject: BehaviorSubject<cornerstone.Image | null>;
  private readonly _progressSubject: BehaviorSubject<number>;
  private _failedImageLoadAttempts: BehaviorSubject<number>;
  private readonly _cancel: (() => void)[] = [];
  private readonly _loader: ImageLoader;
  private readonly _headers: { [key: string]: string };
  private _destoyed = false;

  constructor(
    private readonly imageId: string,
    private readonly options: Options = {}
  ) {
    this._imageSubject = new BehaviorSubject<cornerstone.Image | null>(null);
    this._progressSubject = new BehaviorSubject(0);
    this._failedImageLoadAttempts = new BehaviorSubject(0);
    this._loader = options.loader || defaultLoader;
    this._headers = options.headers || {};

    cornerstone.events.addEventListener(
      'cornerstoneimageloadprogress',
      this.onProgress as EventListener
    );
    this.loadImage(imageId);
  }

  get image(): Observable<cornerstone.Image | null> {
    return this._imageSubject.asObservable();
  }

  get progress(): Observable<number> {
    return this._progressSubject.asObservable();
  }

  get failedImageLoadAttempts(): Observable<number> {
    return this._failedImageLoadAttempts.asObservable();
  }

  destroy = (): void => {
    if (this.options && typeof this.options.unload === 'function') {
      this.options.unload(this.imageId);
    }

    cornerstone.events.removeEventListener(
      'cornerstoneimageloadprogress',
      this.onProgress as EventListener
    );

    this._cancel.forEach(cancel => cancel());

    this._destoyed = true;
  };

  private onProgress = (event: CustomEvent) => {
    const eventDetail: ProgressEventDetail | undefined =
      getProgressEventDetail(event);

    if (eventDetail && eventDetail.imageId === this.imageId) {
      this._progressSubject.next(
        Math.min(eventDetail.loaded / eventDetail.total, 0.99)
      );
    }
  };

  private loadImage = async (imageId: string) => {
    try {
      const image = await this._loader.loadImage({
        imageId,
        options: {
          loader: wadoImageLoaderXHRLoader({
            getCancel: cancel => this._cancel.push(cancel),
            beforeSend: xhr => {
              const headers = this._headers;
              Object.keys(headers).forEach(function (key: string) {
                xhr.setRequestHeader(key, headers[key] || '');
              });
            },
          }),
        },
      });

      cornerstone.events.removeEventListener(
        'cornerstoneimageloadprogress',
        this.onProgress as EventListener
      );

      if (!this._destoyed) {
        this._imageSubject.next(image);
        this._progressSubject.next(1);
      }
    } catch (error) {
      if (!this._destoyed) {
        this._failedImageLoadAttempts.next(
          this._failedImageLoadAttempts.value + 1
        );
        if (this._failedImageLoadAttempts.value > 2) {
          // eslint-disable-next-line no-console
          console.warn(
            `Failed to load image with image path: ${imageId}`,
            error
          );
          return;
        }

        // eslint-disable-next-line no-console
        console.warn(`Retrying loadImage(${imageId}):`, error);
        this.loadImage(imageId);
      }
    }
  };
}
