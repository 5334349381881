import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import AsideItem from 'src/components/aside/AsideItem';
import useDiscardAnnotations from 'src/hooks/tasks/useDiscardAnnotations';
import { jobState } from 'src/states/job';
import { projectState } from 'src/states/project';

const AnnotatorPanel = (): JSX.Element | null => {
  const jobLoadable = useRecoilValueLoadable(jobState.current);
  const project = useRecoilValue(projectState.current);
  const currentAnnotatorId = useRecoilValue(projectState.currentAnnotatorId);

  const setCurrentAssociateId = useSetRecoilState(
    projectState.currentAssociateId
  );

  const checkDiscard = useDiscardAnnotations();

  const handleChangeAnnotator = async (event: SelectChangeEvent<string>) => {
    const annotatorId = String(event.target.value);
    if (!project.isReviewer) {
      return;
    }
    const discard = await checkDiscard();
    if (!discard) return;
    setCurrentAssociateId(annotatorId);
  };

  return (
    (project.isReviewer && (
      <AsideItem label="Annotator" isCollapsible>
        <FormControl variant="standard" size="small" fullWidth>
          <Select
            id="select-associate"
            value={currentAnnotatorId}
            defaultValue=""
            onChange={handleChangeAnnotator}
            disabled={jobLoadable.state === 'loading'}
          >
            {project.associates.map(associate => (
              <MenuItem key={associate.id} value={associate.id}>
                {associate.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </AsideItem>
    )) ||
    null
  );
};

export default AnnotatorPanel;
