import { useRecoilValue } from 'recoil';

import Restore from '@mui/icons-material/Restore';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import useResetAnnotations from 'src/hooks/tasks/useResetAnnotations';
import { jobState } from 'src/states/job';
import { operationModeState } from 'src/states/operationMode';

const ResetAnnotationsButton = (): JSX.Element => {
  const resetAnnotations = useResetAnnotations();

  const operationMode = useRecoilValue(operationModeState.current);
  const isAnnotating = useRecoilValue(jobState.isAnnotating);

  return (
    <Tooltip title="Reset">
      <span>
        <IconButton
          onClick={resetAnnotations}
          disabled={!operationMode?.isEditable || !isAnnotating}
          size="large"
          data-test-id="btn-reset-annotations"
        >
          <Restore />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ResetAnnotationsButton;
