import { useEffect } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { jobState } from 'src/states/job';
import jobIdListState from 'src/states/jobIdList';
import { projectState } from 'src/states/project';
import { taskState } from 'src/states/task';

const useResetFindings = (): void => {
  const setFindingIndex = useSetRecoilState(taskState.findingIndex);
  const setIsAnnotating = useSetRecoilState(jobState.isAnnotating);
  const setLocalFindings = useSetRecoilState(taskState.localFindings);
  const jodId = useRecoilValue(jobIdListState.currentJobId);
  const annotatorId = useRecoilValue(projectState.currentAnnotatorId);
  const projectId = useRecoilValue(projectState.currentId);

  useEffect(() => {
    setFindingIndex(undefined);
    setIsAnnotating(false);
    setLocalFindings([]);
  }, [
    setFindingIndex,
    setIsAnnotating,
    setLocalFindings,
    jodId,
    annotatorId,
    projectId,
  ]);
};

export default useResetFindings;
