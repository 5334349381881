import { startZoomClickAndDragInteraction } from '@InsightViewer/components/interactions/startZoomClickAndDragInteraction';
import { startZoomWheelInteraction } from '@InsightViewer/components/interactions/startZoomWheelInteraction';
import { CornerstoneViewerLike } from '@InsightViewer/types';

export const zoomWheel =
  ({ element }: { element?: HTMLElement | null } = {}) =>
  ({
    getElement,
    updateViewport,
    getCurrentViewport,
    getContentWindow,
    getMinScale,
    getMaxScale,
  }: CornerstoneViewerLike): (() => void) => {
    if (!element) element = getElement();

    return startZoomWheelInteraction({
      element,
      getMinMaxScale: () => [getMinScale(), getMaxScale()],
      getCurrentViewport,
      onZoom: patch => updateViewport(patch),
      contentWindow: getContentWindow(),
    });
  };

export const zoomClickAndDrag =
  ({ element }: { element?: HTMLElement | null } = {}) =>
  ({
    getElement,
    updateViewport,
    getCurrentViewport,
    getContentWindow,
    getMinScale,
    getMaxScale,
  }: CornerstoneViewerLike): (() => void) => {
    if (!element) element = getElement();

    return startZoomClickAndDragInteraction({
      element,
      getMinMaxScale: () => [getMinScale(), getMaxScale()],
      getCurrentViewport,
      onZoom: patch => updateViewport(patch),
      contentWindow: getContentWindow(),
    });
  };
