import { MouseEvent, ReactNode, useMemo } from 'react';

import {
  AdjustIcon,
  FlipIcon,
  InvertIcon,
  PanIcon,
  ResetIcon,
} from '@lunit/opt-control-icons';
import groupBy from 'lodash.groupby';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

import LineIcon from 'src/components/icons/LineIcon';
import MultiFramePolygonIcon from 'src/components/icons/MultiFramePolygonIcon';
import PointIcon from 'src/components/icons/PointIcon';
import PolygonIcon from 'src/components/icons/PolygonIcon';
import useResetControls from 'src/hooks/useResetControls';
import { ClientError, ClientErrorCode } from 'src/http/client-error';
import { FindingShape } from 'src/interfaces';
import { ControlName } from 'src/interfaces/project';
import controlState from 'src/states/control';
import { projectState } from 'src/states/project';

type ClickHandler = <T>(event: MouseEvent<T>) => void;

const getControlLabel = (name: string): string => {
  switch (name) {
    case FindingShape.POINT:
      return 'Draw Point';
    case FindingShape.LINE:
      return 'Draw Line';
    case FindingShape.POLYGON:
      return 'Draw Polygon';
    case FindingShape.MULTI_FRAME_POLYGON:
      return 'Draw Multi Frame Polygon';
    case 'navigateFrames':
      return 'Navigate Frames';
    case 'pan':
      return 'Pan';
    case 'brightness':
      return 'Adjust';
    case 'zoom':
      return 'Zoom';
    case 'flip':
      return 'Flip';
    case 'invert':
      return 'Invert';
    case 'fitToWindow':
      return 'Fit to Window';
    case 'reset':
      return 'Reset';
    default:
      return 'Error';
  }
};

interface ControlButton {
  controlName: ControlName;
  selected: boolean;
  onClick: ClickHandler;
  icon: ReactNode;
  label: string;
}

type Return = ControlButton[][];

const useControlButton = (): Return => {
  const {
    claim: { controls },
  } = useRecoilValue(projectState.current);
  const isConfirmedProject = useRecoilValue(projectState.isConfirmed);

  const [control, setControl] = useRecoilState(controlState.current);
  const [flip, setFlip] = useRecoilState(controlState.flip);
  const [invert, setInvert] = useRecoilState(controlState.invert);
  const setResetTime = useSetRecoilState(controlState.resetTime);
  const resetControls = useResetControls();

  const getClickHandler =
    (callback: () => void): ClickHandler =>
    event => {
      event.stopPropagation();
      callback();
    };

  const buttons: ControlButton[] = useMemo(
    () => [
      {
        controlName: FindingShape.POINT,
        onClick: getClickHandler(() => setControl(FindingShape.POINT)),
        icon: <PointIcon />,
        selected: control === FindingShape.POINT,
        label: getControlLabel(FindingShape.POINT),
      },
      {
        controlName: FindingShape.LINE,
        onClick: getClickHandler(() => setControl(FindingShape.LINE)),
        icon: <LineIcon />,
        selected: control === FindingShape.LINE,
        label: getControlLabel(FindingShape.LINE),
      },
      {
        controlName: FindingShape.POLYGON,
        onClick: getClickHandler(() => setControl(FindingShape.POLYGON)),
        icon: <PolygonIcon />,
        selected: control === FindingShape.POLYGON,
        label: getControlLabel(FindingShape.POLYGON),
      },
      {
        controlName: FindingShape.MULTI_FRAME_POLYGON,
        onClick: getClickHandler(() =>
          setControl(FindingShape.MULTI_FRAME_POLYGON)
        ),
        icon: <MultiFramePolygonIcon />,
        selected: control === FindingShape.MULTI_FRAME_POLYGON,
        label: getControlLabel(FindingShape.MULTI_FRAME_POLYGON),
      },
      {
        controlName: 'navigateFrames',
        onClick: getClickHandler(() => setControl('navigateFrames')),
        icon: <AutoAwesomeMotionIcon fontSize="small" />,
        selected: control === 'navigateFrames',
        label: getControlLabel('navigateFrames'),
      },
      {
        controlName: 'pan',
        onClick: getClickHandler(() => setControl('pan')),
        icon: <PanIcon fontSize="small" />,
        selected: control === 'pan',
        label: getControlLabel('pan'),
      },
      {
        controlName: 'brightness',
        onClick: getClickHandler(() => setControl('brightness')),
        icon: <AdjustIcon fontSize="small" />,
        selected: control === 'brightness',
        label: getControlLabel('brightness'),
      },
      {
        controlName: 'zoom',
        onClick: getClickHandler(() => setControl('zoom')),
        icon: <ZoomInIcon fontSize="small" />,
        selected: control === 'zoom',
        label: getControlLabel('zoom'),
      },
      {
        controlName: 'flip',
        onClick: getClickHandler(() => setFlip(prev => !prev)),
        icon: <FlipIcon fontSize="small" />,
        selected: flip,
        label: getControlLabel('flip'),
      },
      {
        controlName: 'invert',
        onClick: getClickHandler(() => setInvert(prev => !invert)),
        icon: <InvertIcon fontSize="small" />,
        selected: invert,
        label: getControlLabel('invert'),
      },
      {
        controlName: 'fitToWindow',
        onClick: getClickHandler(() => {
          setResetTime(Date.now());
        }),
        icon: <ZoomOutMapIcon fontSize="small" />,
        selected: false,
        label: getControlLabel('fitToWindow'),
      },
      {
        controlName: 'reset',
        onClick: getClickHandler(resetControls),
        icon: <ResetIcon fontSize="small" />,
        selected: false,
        label: getControlLabel('reset'),
      },
    ],
    [
      control,
      flip,
      invert,
      resetControls,
      setControl,
      setFlip,
      setInvert,
      setResetTime,
    ]
  );

  const mappedButtons = useMemo<ControlButton[][]>(() => {
    if (!controls || !controls.length) {
      return [];
    }

    const nonDrawControls = controls.filter(
      control => control.group !== 'draw'
    );

    const conditionalControls = isConfirmedProject ? nonDrawControls : controls;

    const groupedControls = groupBy(conditionalControls, 'group');

    return Object.values(groupedControls).map(controls =>
      controls.map(control => {
        const button = buttons.find(
          button => button.controlName === control.name
        );
        if (!button) {
          throw new ClientError({
            code: ClientErrorCode.INVALID_PROJECT,
            message: `ControlButton for control "${control.name}" need to be defined.`,
          });
        }
        return button;
      })
    );
  }, [buttons, controls, isConfirmedProject]);

  return mappedButtons;
};

export default useControlButton;
