import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';

const StyledDialogActions = styled(DialogActions)({
  padding: '8px 16px',
  justifyContent: 'flex-end',
  borderTop: '1px dashed var(--ctl-border-color-light)',
  marginTop: '8px',
});

export default StyledDialogActions;
