import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

export interface ProgressProps {
  step: 'JOB' | 'IMAGE';
  enqueued?: boolean;
}

const Progress = ({ step, enqueued }: ProgressProps): JSX.Element => {
  return step === 'IMAGE' ? (
    <StyledLinearProgress
      key="IMAGE"
      color="success"
      data-test-id={`${enqueued ? 'enqueued' : 'image'}-loading-indicator`}
    />
  ) : (
    <StyledLinearProgress
      key="JOB"
      color="info"
      data-test-id="job-loading-indicator"
    />
  );
};

export default Progress;

const StyledLinearProgress = styled(LinearProgress)`
  height: 5px;

  .MuiLinearProgress-bar {
    transition: none;
  }
`;
