import styled from 'styled-components';
import { CubeGrid } from 'styled-spinkit';

interface Props {
  flexible?: boolean;
}

const Loading = ({ flexible = false }: Props): JSX.Element => {
  return (
    <Container $flexible={flexible} data-test-id="cube-loading-indicator">
      <CubeGrid color="#00a5c8" size={40} />
    </Container>
  );
};

const Container = styled.div<{ $flexible?: boolean }>`
  width: ${({ $flexible }) => ($flexible ? '100%' : '100vw')};
  height: ${({ $flexible }) => ($flexible ? '100%' : '100vh')};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $flexible }) => ($flexible ? 'transparent' : 'black')};
`;

export default Loading;
