import { CSSProperties, MouseEventHandler, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { styled } from '@mui/material/styles';

import DisabledIssueItem from 'src/components/Issue/DisabledIssueItem';
import IssueItem from 'src/components/Issue/IssueItem';
import { IssueUpdate } from 'src/interfaces/issue';
import authState from 'src/states/auth';
import IssueUtils from 'src/utils/issue';

import NewIssueAction from './NewIssueAction';

interface Props {
  update: IssueUpdate;
  style?: CSSProperties;
  onClick: MouseEventHandler<HTMLDivElement>;
}

const NewIssueItem = ({ update, style, onClick }: Props): JSX.Element => {
  const myId = useRecoilValue(authState.myId);

  const read = useMemo(
    () => update.readUsers.includes(myId),
    [update.readUsers, myId]
  );

  const { id, type, userName, createdAt, readUsers } = update;

  return (
    <div
      style={{
        ...style,
        borderTop: '1px solid var(--ctl-background-color-light)',
        opacity: read ? 0.65 : 1,
      }}
    >
      {IssueUtils.getDisabledType({ type }) ? (
        <DisabledIssueItem
          type={type}
          userName={userName}
          onClick={onClick}
          actionElement={
            <NewIssueAction
              createdAt={createdAt}
              id={id}
              readUsers={readUsers}
            />
          }
        />
      ) : (
        <IssueItem
          type={type}
          userName={userName}
          isReviewer={false}
          onClick={onClick}
          actionElement={
            <NewIssueAction
              createdAt={createdAt}
              id={id}
              readUsers={readUsers}
            />
          }
        >
          <Content>{update.text}</Content>
        </IssueItem>
      )}
    </div>
  );
};

export default NewIssueItem;

const Content = styled('div')`
  word-break: break-word;
`;
