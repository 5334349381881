import { IssueUpdate } from 'src/interfaces/issue';

const getDeleted = ({
  text,
  userId,
}: Pick<IssueUpdate, 'text' | 'userId'>): boolean =>
  text === null && userId === null;

const getDisabledType = ({ type }: Pick<IssueUpdate, 'type'>): boolean =>
  ['REOPEN', 'CLOSE'].includes(type);

const getDisabledMessage = ({
  type,
  userName,
}: Pick<IssueUpdate, 'type' | 'userName'>): string =>
  (() => {
    switch (type) {
      case 'CLOSE':
        return `Closed by "${userName}"`;
      case 'REOPEN':
        return `Reopened by "${userName}"`;
      default:
        return '';
    }
  })();

const IssueUtils = Object.freeze({
  getDeleted,
  getDisabledType,
  getDisabledMessage,
});

export default IssueUtils;
