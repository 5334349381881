import { Suspense, useState } from 'react';

import GoToFirstJobButton from './GoToFirstJobButton';
import GoToLastJobButton from './GoToLastJobButton';
import GoToNextJobButton from './GoToNextJobButton';
import GoToNextJobButtonFallback from './GoToNextJobButton/GoToNextJobButtonFallback';
import GoToPrevJobButton from './GoToPrevJobButton';
import JobNavigator from './JobNavigator';
import ResetAnnotationsButton from './ResetAnnotationsButton';
import SaveJobButton from './SaveJobButton';

const Toolbar = (): JSX.Element => {
  const [editIndexMode, setEditIndexMode] = useState(false);

  return (
    <>
      <ResetAnnotationsButton />
      <GoToFirstJobButton editIndexMode={editIndexMode} />
      <GoToPrevJobButton editIndexMode={editIndexMode} />
      <JobNavigator
        editIndexMode={editIndexMode}
        setEditIndexMode={setEditIndexMode}
      />
      <Suspense fallback={<GoToNextJobButtonFallback />}>
        <GoToNextJobButton editIndexMode={editIndexMode} />
      </Suspense>
      <GoToLastJobButton editIndexMode={editIndexMode} />
      <SaveJobButton setEditIndexMode={setEditIndexMode} />
    </>
  );
};

export default Toolbar;
