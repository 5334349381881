import { useRef, useMemo, useCallback, Suspense, createRef } from 'react';

import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import {
  adjustWindowCenter,
  adjustWindowWidth,
  updateViewport,
  zoomMiddleCenter,
} from '@InsightViewer/behaviors/updateViewport';
import { CornerstoneViewerLike, Point } from '@InsightViewer/types';

import { MultiView } from 'src/components/viewers/MultiView';
import LoadingViewer from 'src/components/viewers/loading';
import useShortcuts from 'src/hooks/useShortcuts';
import { FindingShape, LocalFinding } from 'src/interfaces';
import { ShortcutDefinition } from 'src/interfaces/shortcut';
import controlState from 'src/states/control';
import { projectState } from 'src/states/project';

import Viewer from './Viewer';

export interface MammographyViewerProps {
  findings: LocalFinding[];
  findingIndex?: number;
  setFindingIndex: (x?: number) => void;
  addFinding: (finding: LocalFinding) => void;
}

export function MammographyViewer({
  findings,
  findingIndex,
  setFindingIndex,
  addFinding,
}: MammographyViewerProps): JSX.Element {
  const {
    claim: {
      viewer: { images },
    },
  } = useRecoilValue(projectState.current);
  const control = useRecoilValue(controlState.current);
  const resetTime = useRecoilValue(controlState.resetTime);
  const invert = useRecoilValue(controlState.invert);
  const flip = useRecoilValue(controlState.flip);

  const elementsRef = useRef(
    images.map(() => createRef<CornerstoneViewerLike>())
  );

  const shortcuts = useMemo<ShortcutDefinition[]>(
    () => [
      {
        shortcut: 'zoomIn',
        callback: () => {
          elementsRef.current.map(ref =>
            updateViewport(ref, zoomMiddleCenter(0.25))
          );
        },
      },
      {
        shortcut: 'zoomOut',
        callback: () => {
          elementsRef.current.map(ref =>
            updateViewport(ref, zoomMiddleCenter(-0.25))
          );
        },
      },
      {
        shortcut: 'adjustWindowCenterUp',
        callback: () => {
          elementsRef.current.map(ref =>
            updateViewport(ref, adjustWindowCenter(0.05))
          );
        },
      },
      {
        shortcut: 'adjustWindowCenterDown',
        callback: () => {
          elementsRef.current.map(ref =>
            updateViewport(ref, adjustWindowCenter(-0.05))
          );
        },
      },
      {
        shortcut: 'adjustWindowWidthUp',
        callback: () => {
          elementsRef.current.map(ref =>
            updateViewport(ref, adjustWindowWidth(0.05))
          );
        },
      },
      {
        shortcut: 'adjustWindowWidthDown',
        callback: () => {
          elementsRef.current.map(ref =>
            updateViewport(ref, adjustWindowWidth(-0.05))
          );
        },
      },
    ],
    []
  );
  useShortcuts(shortcuts);

  const getAddFinding = useCallback(
    (imageKey: string) => (points: Point[]) => {
      addFinding({
        index: -1,
        image: imageKey,
        points,
        shape: FindingShape.POLYGON,
        confirmed: false,
      });
    },
    [addFinding]
  );

  return (
    <Container>
      <MultiViewContainer>
        {images.map(({ name }, index) => {
          return (
            <Suspense fallback={<LoadingViewer step={'IMAGE'} />} key={name}>
              <Viewer
                view={name}
                control={control}
                resetTime={resetTime}
                flip={flip}
                invert={invert}
                viewerRef={elementsRef.current[index]}
                viewerIndex={index}
                findings={findings.filter(f => f.image === name)}
                addFinding={getAddFinding(name)}
                findingIndex={findingIndex}
                setFindingIndex={setFindingIndex}
              />
            </Suspense>
          );
        })}
      </MultiViewContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const MultiViewContainer = styled(MultiView)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;
