import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import AsideItem from 'src/components/aside/AsideItem';
import useDiscardAnnotations from 'src/hooks/tasks/useDiscardAnnotations';
import jobIdListState from 'src/states/jobIdList';
import { projectState } from 'src/states/project';
import localStore from 'src/utils/localStore';
import ProjectUtils from 'src/utils/project';

const ProjectPanel = (): JSX.Element | null => {
  const projects = useRecoilValue(projectState.projects);
  const currentProjectId = useRecoilValue(projectState.currentId);

  const setProjectId = useSetRecoilState(projectState.currentId);
  const resetCurrentJobId = useResetRecoilState(jobIdListState.currentJobId);

  const checkDiscard = useDiscardAnnotations();

  const handleChangeProject = async (event: SelectChangeEvent<string>) => {
    const discard = await checkDiscard();
    if (!discard) return;
    resetCurrentJobId();
    const currProjectId = String(event.target.value);
    setProjectId(currProjectId);
    localStore.setCurrProjectId(currProjectId);
  };

  return (
    (projects.length !== 0 && (
      <AsideItem label="Project" isCollapsible>
        <FormControl variant="standard" size="small" fullWidth>
          <Select
            value={ProjectUtils.getProjectId({ currentProjectId, projects })}
            defaultValue=""
            onChange={handleChangeProject}
            data-test-id="select-project"
          >
            {projects.map(({ id, name }) => (
              <MenuItem key={id} value={id} data-test-id="project-list-item">
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </AsideItem>
    )) ||
    null
  );
};

export default ProjectPanel;
