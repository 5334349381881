import { RemoteProject } from 'src/interfaces';
import localStore from 'src/utils/localStore';

interface GetProjectIdProps {
  currentProjectId?: string;
  projects: RemoteProject[];
}

const getProjectId = ({
  currentProjectId,
  projects,
}: GetProjectIdProps): string | undefined => {
  const id = currentProjectId || localStore.getCurrProjectId();
  const isExist = projects.find(project => project.id === id);
  return isExist ? id : projects[0]?.id || undefined;
};

const ProjectUtils = Object.freeze({
  getProjectId,
});

export default ProjectUtils;
