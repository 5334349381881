import { AtomEffect } from 'recoil';

import { ViewPresetType } from 'src/interfaces';

const CURRENT_PROJECT = '__ctl_active_project';
const ASIDE_VISIBILITY = '__ctl_aside_visibility';
const FILTER_AREA_VISIBILITY = '__ctl_filter_area_visibility';
const VIEWER_PRESET_NAME = '__ctl_viewer_preset_name';
export const NEXT_DBT_JOB_FRAMES_PRELOADING_QTY =
  '__ctl_viewer_next_dbt_job_frames_preloading_qty';
export const CURRENT_DBT_FRAME_NEIGHBORS_PRELOADING_QTY =
  '__ctl_viewer_current_dbt_frame_neighbors_preloading_qty';
export const SIDEBAR_WIDTH = '__ctl_viewer_sidebar_width';

const ASIDE_AREA_PREFIX = '__ctl_aside';
const ASIDE_AREA_SUFFIX = 'area_visibility';

export const localStorageEffect: (key: string) => AtomEffect<number> =
  (key: string) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue !== null) {
      setSelf(parseInt(savedValue));
    }

    onSet((newValue, _, isReset) => {
      if (isReset) return localStorage.removeItem(key);

      return localStorage.setItem(key, newValue.toString());
    });
  };

const underscorify = (label: string) => {
  return label.split(' ').join('_').toLowerCase();
};

const localStore = {
  getCurrProjectId(): string {
    return localStorage.getItem(CURRENT_PROJECT) || '';
  },
  setCurrProjectId(value: string): void {
    try {
      localStorage.setItem(CURRENT_PROJECT, value);
    } catch {}
  },
  clearCurrProjectId(): void {
    localStorage.removeItem(CURRENT_PROJECT);
  },
  getAsideVisibility(): boolean {
    const value = localStorage.getItem(ASIDE_VISIBILITY);
    return value === 'true' ? true : false;
  },
  setAsideVisibility(value: boolean): void {
    localStorage.setItem(ASIDE_VISIBILITY, value ? 'true' : 'false');
  },
  getFilterAreaVisibility(): boolean {
    const value = localStorage.getItem(FILTER_AREA_VISIBILITY);
    return value === 'true' ? true : false;
  },
  setFilterAreaVisibility(value: boolean): void {
    try {
      localStorage.setItem(FILTER_AREA_VISIBILITY, value ? 'true' : 'false');
    } catch {}
  },

  getAsidePanelVisibility(key: string): boolean {
    const fullKey = `${ASIDE_AREA_PREFIX}_${underscorify(
      key
    )}_${ASIDE_AREA_SUFFIX}`;
    const value = localStorage.getItem(fullKey);

    // initial value should be true in order to show expanded view
    if (value === null) {
      return true;
    }
    return value === 'true' ? true : false;
  },
  setAsidePanelVisibility(key: string, value: boolean): void {
    const fullKey = `${ASIDE_AREA_PREFIX}_${underscorify(
      key
    )}_${ASIDE_AREA_SUFFIX}`;

    try {
      localStorage.setItem(fullKey, value ? 'true' : 'false');
    } catch {}
  },

  getViewerPresetName(): string | null {
    return localStorage.getItem(VIEWER_PRESET_NAME);
  },
  setViewerPresetName(value: ViewPresetType): void {
    try {
      localStorage.setItem(VIEWER_PRESET_NAME, value);
    } catch {}
  },
};

export default localStore;
