import { imageApi } from 'src/http';

interface GoogleStorageAPIResponse {
  signed_url: string;
}

/**
 * Get Signed URL
 *
 * @param imagePath string
 * @returns data.signed_url string
 */
export const getSignedURL = async (
  imagePath: string
): Promise<string | undefined> => {
  if (!imagePath) return;

  const { data } = await imageApi.get<GoogleStorageAPIResponse>('img', {
    params: {
      path: imagePath,
    },
  });

  return data.signed_url;
};
