import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { CurrentDBTFrameNeighborsPreloadingQty } from 'src/components/modals/DBTPreloadSettingsModal/CurrentDBTFrameNeighborsPreloadingQty';
import { NextDBTJobFramesPreloadingQty } from 'src/components/modals/DBTPreloadSettingsModal/NextDBTJobFramesPreloadingQty';
import StyledDialog from 'src/components/modals/StyledDialog';
import StyledDialogContent from 'src/components/modals/StyledDialogContent';

interface DBTPreloadSettingsModalProps {
  visibility: boolean;
  onClose: () => void;
}

const DBTPreloadSettingsModal = ({
  visibility,
  onClose,
}: DBTPreloadSettingsModalProps): JSX.Element => {
  return (
    <StyledDialog open={visibility} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">
        DBT Frame Preload Settings
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: '8px',
          top: '8px',
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <StyledDialogContent>
        <Typography variant="body1" color="error">
          Caution: High values may cause lag.
        </Typography>
        <Container>
          <Divider sx={{ width: '100%' }} />
          <CurrentDBTFrameNeighborsPreloadingQty />
          <Divider sx={{ width: '100%' }} />
          <NextDBTJobFramesPreloadingQty />
        </Container>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default DBTPreloadSettingsModal;

const Container = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  width: '300px',
  margin: '0px',
  marginTop: '10px',
}));
