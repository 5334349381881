import { atom, useRecoilState } from 'recoil';

import localStore from 'src/utils/localStore';

const PANELS_KEY = 'ALL';

const isAsideVisible = atom({
  key: 'layoutState/isAsideVisible',
  default: localStore.getAsideVisibility(),
});

const isPanelsVisible = atom({
  key: 'layoutState/isPanelsVisible',
  default: localStore.getAsidePanelVisibility(PANELS_KEY),
});

const layoutState = Object.freeze({
  isAsideVisible,
  isPanelsVisible,
});

interface Return {
  isAsideVisible: boolean;
  isPanelsVisible: boolean;
  isFullScreen: boolean;
  toggleAside: () => void;
  toggleFullScreen: () => void;
}

export const useLayoutState = (): Return => {
  const [isAsideVisible, setIsAsideVisible] = useRecoilState(
    layoutState.isAsideVisible
  );
  const [isPanelsVisible, setIsPanelsVisible] = useRecoilState(
    layoutState.isPanelsVisible
  );

  const isFullScreen = !isAsideVisible && !isPanelsVisible;

  const toggleAside = () => {
    setIsAsideVisible(prev => {
      localStore.setAsideVisibility(!prev);
      return !prev;
    });
  };

  const togglePanels = () => {
    setIsPanelsVisible(prev => {
      localStore.setAsidePanelVisibility(PANELS_KEY, !prev);
      return !prev;
    });
  };

  const toggleFullScreen = () => {
    if (
      (isAsideVisible && isPanelsVisible) ||
      (!isAsideVisible && !isPanelsVisible)
    ) {
      toggleAside();
      togglePanels();
    } else {
      isAsideVisible && toggleAside();
      isPanelsVisible && togglePanels();
    }
  };

  return {
    isAsideVisible,
    isPanelsVisible,
    isFullScreen,
    toggleAside,
    toggleFullScreen,
  };
};
