import { useCallback } from 'react';

import { useRecoilState } from 'recoil';

import { AlertState, TRANSITION_DURATION } from 'src/interfaces/ui';
import { alertState } from 'src/states/alert';
import { DEFAULT_ALERT_STATE } from 'src/states/defaults';

type OpenFunction = (
  state: Pick<AlertState, 'message' | 'autoHide' | 'type'>
) => void;

interface Return {
  open: OpenFunction;
  close: () => void;
}

const useAlert = (): Return => {
  const [{ visibility }, setAlert] = useRecoilState(alertState);

  const open = useCallback<OpenFunction>(
    state => {
      setAlert({
        ...state,
        visibility: true,
      });
    },
    [setAlert]
  );

  const close = () => {
    if (!visibility) {
      return;
    }

    setAlert(prev => ({
      ...prev,
      visibility: false,
    }));

    setTimeout(() => {
      setAlert(DEFAULT_ALERT_STATE);
    }, TRANSITION_DURATION);
  };

  return { open, close };
};

export default useAlert;
