import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';

import useSyncCurrentJob from 'src/hooks/currentJobId/useSyncCurrentJob';
import useDiscardAnnotations from 'src/hooks/tasks/useDiscardAnnotations';
import useAlert from 'src/hooks/useAlert';
import { ClientError, ClientErrorCode } from 'src/http/client-error';
import jobIdListState from 'src/states/jobIdList';

type Return = () => Promise<void>;

const useSetPrevJob = (): Return => {
  const setCurrentJobId = useSetRecoilState(jobIdListState.currentJobId);
  const checkDiscard = useDiscardAnnotations();
  const prevJobIdLoadable = useRecoilValueLoadable(jobIdListState.prevJobId);
  const prevJobId =
    (prevJobIdLoadable.state === 'hasValue' && prevJobIdLoadable.contents) ||
    null;

  const syncCurrentJob = useSyncCurrentJob();
  const { open: openAlert } = useAlert();

  return async () => {
    try {
      if (!prevJobId) {
        throw new ClientError({ code: ClientErrorCode.INVALID_PREV_JOB_ID });
      }
      // in case of cpc job: user should not be able to move without
      // saving changes in both windows (this & other)
      // this condition ensures for the other window
      const discard = await checkDiscard();
      if (!discard) return;

      await syncCurrentJob(prevJobId);
      setCurrentJobId(prevJobId);
    } catch (error) {
      openAlert({
        type: 'error',
        message: `Failed move to previous job : ${
          (error as ClientError).message
        }`,
      });
    }
  };
};

export default useSetPrevJob;
