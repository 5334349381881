import { useRecoilValue } from 'recoil';

import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';

import useAlert from 'src/hooks/useAlert';
import { TRANSITION_DURATION } from 'src/interfaces';
import { alertState } from 'src/states/alert';

export function Alert(): JSX.Element {
  const { visibility, message, type, autoHide } = useRecoilValue(alertState);

  const { close } = useAlert();
  const duration = autoHide ? 4000 : null;

  return (
    <StyledSnackbar
      open={visibility}
      autoHideDuration={duration}
      transitionDuration={TRANSITION_DURATION}
      onClose={close}
    >
      <MuiAlert
        onClose={close}
        severity={type}
        sx={{ pointerEvents: 'all' }}
        data-test-id="snackbar"
      >
        {message}
      </MuiAlert>
    </StyledSnackbar>
  );
}

const StyledSnackbar = styled(Snackbar)({
  display: 'flex',
  justifyContent: 'center',
  bottom: 70,
  left: 0,
  right: 0,
  pointerEvents: 'none',
});
