const priorWindowInfo = {
  pathname: '/prior',
  name: 'priorCase',
  color: '#7E41DA',
  label: 'Prior Case',
};
const indexWindowInfo = {
  pathname: '/workspace',
  name: 'indexCase',
  color: '#4589FF',
  label: 'Index Case',
};

export const isCPCPriorCaseWindow = window.location.pathname === '/prior';

// index window: thisCPCWindow = indexWindowInfo and otherCPCWindow = priorWindowInfo
// prior window: thisCPCWindow = priorWindowInfo and otherCPCWindow = indexWindowInfo
export const thisCPCWindow = isCPCPriorCaseWindow
  ? priorWindowInfo
  : indexWindowInfo;

export const otherCPCWindow = isCPCPriorCaseWindow
  ? indexWindowInfo
  : priorWindowInfo;
