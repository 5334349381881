import {
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
  MouseEvent,
  useEffect,
} from 'react';

import { isComplexPolygon } from '@lunit/is-complex-polygon';
import { isPolygonAreaGreaterThanArea } from '@lunit/is-polygon-area-greater-than-area';
import {
  constSelector,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable_TRANSITION_SUPPORT_UNSTABLE,
  useSetRecoilState,
} from 'recoil';

import { ContourDrawer } from '@InsightViewer/components/ContourDrawer';
import { ContourHover } from '@InsightViewer/components/ContourHover';
import { CornerstoneViewer } from '@InsightViewer/components/CornerstoneViewer';
import { InsightViewerContainer } from '@InsightViewer/components/InsightViewerContainer';
import { PointViewer } from '@InsightViewer/components/PointViewer';
import { useInsightViewerSync } from '@InsightViewer/hooks/useInsightViewerSync';
import { useViewerInteractions } from '@InsightViewer/interactions/useViewerInteractions';
import { zoomClickAndDrag } from '@InsightViewer/interactions/zoom';
import { CornerstoneViewerLike, Point } from '@InsightViewer/types';

import IssuePointPin from 'src/components/Issue/IssuePointPin';
import { ViewportInfoLabel } from 'src/components/opt-components/ViewportInfoLabel';
import UserContourViewer from 'src/components/viewers/UserContourViewer';
import ViewOnlyContourViewer from 'src/components/viewers/UserContourViewer/ViewOnlyContourViewer';
import useAddFinding from 'src/hooks/tasks/useAddFinding';
import { LocalFinding, FindingContour, FindingShape } from 'src/interfaces/job';
import controlState from 'src/states/control';
import imageState from 'src/states/image';
import issuesState from 'src/states/issues';
import { taskState } from 'src/states/task';
import FindingUtils from 'src/utils/finding';

import RightBottomHolderPortal from './RightBottomHolderPortal';

interface Props {
  view: string;
  viewerRef?: RefObject<CornerstoneViewerLike>;
  currentFrame: number;
  currentImagePath: string;
  totalFrames: number;
  width: number;
  height: number;
}

const DBTViewer3D = ({
  view,
  viewerRef,
  currentFrame,
  currentImagePath,
  totalFrames,
  width,
  height,
}: Props): JSX.Element | null => {
  const image = useRecoilValue(
    !!currentImagePath
      ? imageState.cornerstoneImage(currentImagePath)
      : constSelector(undefined)
  );

  const control = useRecoilValue(controlState.current);
  const resetTime = useRecoilValue(controlState.resetTime);
  const invert = useRecoilValue(controlState.invert);
  const flip = useRecoilValue(controlState.flip);
  const findings = useRecoilValue(taskState.findings);
  const currentGroupName = useRecoilValue(taskState.currentGroupName);
  const findingsInCurrentGroup = useRecoilValue(
    taskState.findingsInCurrentGroup
  );
  const setCurrentFrame = useSetRecoilState(taskState.currentFrame(view));
  const [findingIndex, setFindingIndex] = useRecoilState(
    taskState.findingIndex
  );
  const [localIssuePosition, setLocalIssuePosition] = useRecoilState(
    issuesState.localIssuePosition
  );
  const issuesLoadable = useRecoilValueLoadable_TRANSITION_SUPPORT_UNSTABLE(
    issuesState.pointList
  );
  const issuePointList =
    issuesLoadable.state === 'hasValue' ? issuesLoadable.contents : [];
  const activatedId = useRecoilValue(issuesState.activatedId);
  const focusedIssue =
    issuePointList.find(({ issueId }) => issueId === activatedId) || null;

  const [interactionElement, setInteractionElement] =
    useState<HTMLElement | null>(null);

  const findingInThisViewInCurrentGroup: LocalFinding | undefined = useMemo(
    () => findingsInCurrentGroup.find(f => f.image === view),
    [findingsInCurrentGroup, view]
  );

  /**
   * If a group or grouped finding in this view is selected, ensure it's visible.
   * If it's not visible on the `currentFrame`, `setCurrentFrame` to `finding.startFrame`
   */
  useEffect(() => {
    if (findingInThisViewInCurrentGroup === undefined) return;

    const { startFrame, endFrame } = findingInThisViewInCurrentGroup || {};
    if (startFrame === undefined || endFrame === undefined) return;

    if (currentFrame < startFrame || currentFrame > endFrame) {
      setCurrentFrame(startFrame);
    }
  }, [currentFrame, findingInThisViewInCurrentGroup, setCurrentFrame]);

  const addFinding = useAddFinding();
  const interactions = useViewerInteractions(
    [control === 'brightness' ? 'adjust' : control],
    {
      element: interactionElement,
      zoomInteraction: zoomClickAndDrag,
    }
  );
  const { cornerstoneRenderData, updateCornerstoneRenderData } =
    useInsightViewerSync();

  const imageWidth = cornerstoneRenderData?.image?.width || 0;
  const imageHeight = cornerstoneRenderData?.image?.height || 0;

  const contours = useMemo(() => {
    const isFindingOnCurrentFrame = (finding: LocalFinding) => {
      if (finding.shape !== FindingShape.MULTI_FRAME_POLYGON) return false;
      if (finding.startFrame === undefined) return false;
      if (finding.endFrame === undefined) return false;
      return (
        currentFrame >= finding.startFrame && currentFrame <= finding.endFrame
      );
    };

    return findings
      .filter(f => f.image === view && isFindingOnCurrentFrame(f))
      .map(FindingUtils.getContourFromFinding(imageWidth, imageHeight))
      .filter(contour => !contour.hidden);
  }, [findings, imageWidth, imageHeight, currentFrame, view]);

  const normalContours = useMemo(() => {
    return contours.filter(contour => !contour.viewOnly);
  }, [contours]);

  const viewOnlyContours = useMemo(() => {
    return contours.filter(contour => !!contour.viewOnly);
  }, [contours]);

  const focusedContour =
    contours.find(FindingUtils.isFocused(findingIndex, currentGroupName)) ||
    null;

  const onPolygonDraw = (polygon: Point[]) => {
    if (!isPolygonAreaGreaterThanArea(polygon) || isComplexPolygon(polygon)) {
      setFindingIndex(undefined);
      return;
    }

    const lastFrameIndex = totalFrames - 1;

    addFinding({
      index: -1,
      image: view,
      points: polygon.map(([x, y]) => [y / imageHeight, x / imageWidth]),
      shape: FindingShape.MULTI_FRAME_POLYGON,
      confirmed: false,
      startFrame: currentFrame, // set current frame as start frame
      endFrame: Math.min(lastFrameIndex, currentFrame + 10), // set end frame with default range
      primaryFrame: currentFrame,
    });
  };

  const onContourClick = (contour: FindingContour) => {
    setFindingIndex(contour.id);
  };

  /**
   * Pass the currently rendered viewport status to the
   * "CornerstoneViewer.defaultViewportTransforms"
   * for keeping them to the next rendering.
   */
  const getViewport = useCallback(():
    | Partial<cornerstone.Viewport>
    | undefined => {
    if (!cornerstoneRenderData) {
      return undefined;
    }

    // Included statuses are handled separately in the CornerstoneViewer.
    const { invert, hflip, ...status } = cornerstoneRenderData.viewport;
    return status;
  }, [cornerstoneRenderData]);

  // For contour click behavior when not in drawing mode
  const currentContourRef = useRef<FindingContour | null>(null);
  const onContainerClick = () => {
    const contour = currentContourRef.current;
    setFindingIndex(contour?.id);
  };
  const onContourHover = (contour: FindingContour | null) => {
    currentContourRef.current = contour;
  };

  const handleAddIssue = ([issueLocation]: Point[], event: MouseEvent) => {
    event.stopPropagation();
    if (!issueLocation) return;
    setLocalIssuePosition({
      view,
      location: issueLocation,
    });
  };

  return !image ? null : (
    <InsightViewerContainer
      ref={setInteractionElement}
      width={width}
      height={height}
      onClick={onContainerClick}
    >
      <CornerstoneViewer
        ref={viewerRef as RefObject<CornerstoneViewer>}
        width={width}
        height={height}
        flip={flip}
        invert={invert}
        resetTime={resetTime}
        interactions={interactions}
        image={image}
        cornerstoneRenderData={cornerstoneRenderData}
        updateCornerstoneRenderData={updateCornerstoneRenderData}
        defaultViewportTransforms={[getViewport]}
      >
        <ViewOnlyContourViewer
          width={width}
          height={height}
          contours={viewOnlyContours}
          focusedContour={focusedContour}
          cornerstoneRenderData={cornerstoneRenderData}
        />
        <UserContourViewer
          width={width}
          height={height}
          contours={normalContours}
          focusedContour={focusedContour}
          cornerstoneRenderData={cornerstoneRenderData}
        />
        <ContourDrawer
          width={width}
          height={height}
          contours={contours}
          draw={
            control === FindingShape.MULTI_FRAME_POLYGON
              ? interactionElement
              : false
          }
          onFocus={() => {}}
          onRemove={onContourClick}
          onAdd={onPolygonDraw}
          cornerstoneRenderData={cornerstoneRenderData}
        />
        <ContourHover
          width={width}
          height={height}
          contours={contours}
          hover={interactionElement}
          onFocus={onContourHover}
          cornerstoneRenderData={cornerstoneRenderData}
        />
        <PointViewer
          width={width}
          height={height}
          contours={issuePointList.filter(issue => issue.view === view)}
          focusedContour={focusedIssue}
          cornerstoneRenderData={cornerstoneRenderData}
          interact={false}
          pointPinComponent={IssuePointPin}
        />
        {control === 'issuePoint' && (
          <PointViewer
            width={width}
            height={height}
            contours={
              localIssuePosition?.view === view
                ? [
                    {
                      id: issuePointList.length + 1,
                      polygon: [localIssuePosition.location],
                      shape: 'point',
                    },
                  ]
                : []
            }
            focusedContour={null}
            cornerstoneRenderData={cornerstoneRenderData}
            interact={true}
            onAdd={handleAddIssue}
            pointPinComponent={IssuePointPin}
          />
        )}
      </CornerstoneViewer>
      <RightBottomHolderPortal view={view}>
        <ViewportInfoLabel cornerstoneRenderData={cornerstoneRenderData} />
      </RightBottomHolderPortal>
    </InsightViewerContainer>
  );
};

export default DBTViewer3D;
