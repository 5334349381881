import { Shortcut } from 'src/interfaces/shortcut';

/**
 * curried function (HOC)
 * @param key
 * @returns function
 */
export const keyTester =
  (key: string) =>
  (event: KeyboardEvent): boolean => {
    return (
      !(event.target instanceof HTMLTextAreaElement) &&
      !(event.target instanceof HTMLInputElement) &&
      event.key.toLowerCase() === key.toLowerCase()
    );
  };

const shortcuts: Record<Shortcut, string> = {
  polygon: 'q',
  multiFramePolygon: 'd',
  point: 't',
  line: 'y',
  pan: 's',
  brightness: 'w',
  flip: 'f',
  invert: 'e',
  reset: '0',
  resetFinding: 'r',
  adjustWindowCenterDown: 'h',
  adjustWindowCenterUp: 'j',
  adjustWindowWidthDown: 'k',
  adjustWindowWidthUp: 'l',
  deleteSelectedFinding: 'Delete',
  toggleMode: '`',
  selectFinding1: '1',
  selectFinding2: '2',
  selectFinding3: '3',
  selectFinding4: '4',
  selectFinding5: '5',
  selectFinding6: '6',
  selectFinding7: '7',
  selectFinding8: '8',
  selectFinding9: '9',
  expandPane1: 'c',
  expandPane2: 'v',
  expandPane3: 'b',
  expandPane4: 'n',
  next: 'ArrowRight',
  prev: 'ArrowLeft',
  saveData: ' ',
  toggleShowFinding: 'a',
  zoomIn: 'z',
  zoomOut: 'x',
  toggleFullScreen: '/',
};

export default Object.freeze(shortcuts);

export const ShortcutUtils = (() => {
  const descriptions: Record<Shortcut, string> = {
    polygon: 'Draw Polygons',
    multiFramePolygon: 'Draw Multi Frame Polygons',
    point: 'Draw Points',
    line: 'Draw Lines',
    pan: 'Pan',
    brightness: 'Brightness/Contrast',
    flip: 'Flip',
    invert: 'Invert',
    reset: 'Reset',
    resetFinding: 'Reset Findings',
    adjustWindowCenterDown: 'Lighten Viewer',
    adjustWindowCenterUp: 'Darken Viewer',
    adjustWindowWidthDown: 'More Contrast',
    adjustWindowWidthUp: 'Less Contrast',
    deleteSelectedFinding: 'Delete Selected Finding',
    toggleMode: 'Toggle Mode',
    selectFinding1: 'Select 1st Finding',
    selectFinding2: 'Select 2nd Finding',
    selectFinding3: 'Select 3rd Finding',
    selectFinding4: 'Select 4th Finding',
    selectFinding5: 'Select 5th Finding',
    selectFinding6: 'Select 6th Finding',
    selectFinding7: 'Select 7th Finding',
    selectFinding8: 'Select 8th Finding',
    selectFinding9: 'Select 9th Finding',
    expandPane1: 'Expand 1st Panel',
    expandPane2: 'Expand 2nd Panel',
    expandPane3: 'Expand 3rd Panel',
    expandPane4: 'Expand 4th Panel',
    next: 'Go to Next Job',
    prev: 'Go to Previous Job',
    saveData: 'Save Job',
    toggleShowFinding: 'Toggle Findings',
    zoomIn: 'Zoom In',
    zoomOut: 'Zoom Out',
    toggleFullScreen: 'Toggle Full Screen',
  };

  const entities: Partial<Record<Shortcut, string>> = {
    saveData: 'Space Bar',
    next: '→',
    prev: '←',
  };

  const getKey = (shortcut: Shortcut): string => shortcuts[shortcut] || '';

  const getDescription = (shortcut: Shortcut): string =>
    descriptions[shortcut] || '';

  const getKeyEntity = (shortcut: Shortcut): string =>
    entities[shortcut] || getKey(shortcut);

  const getShortcuts = (): Shortcut[] => Object.keys(shortcuts) as Shortcut[];

  const getKeys = (): string[] => Object.values(shortcuts);

  const getShortcutByKey = (key: string): Shortcut | undefined =>
    (Object.keys(shortcuts) as Shortcut[]).find(
      shortcut => getKey(shortcut) === key
    );

  return Object.freeze({
    getDescription,
    getKeyEntity,
    getShortcuts,
    getKey,
    getKeys,
    getShortcutByKey,
  });
})();
