import { useRecoilState, useSetRecoilState } from 'recoil';

import { FindingShape } from 'src/interfaces';
import controlState from 'src/states/control';
import { taskState } from 'src/states/task';

const useSetShowDBT3DImages = (): ((willShowDBT3D: boolean) => void) => {
  const setShowDBT3D = useSetRecoilState(taskState.showDBT3D);
  const [currentControl, setCurrentControl] = useRecoilState(
    controlState.current
  );

  const setShowDBT3DImages = (willShowDBT3D: boolean) => {
    if (willShowDBT3D && currentControl === FindingShape.POLYGON) {
      setCurrentControl(FindingShape.MULTI_FRAME_POLYGON);
    }
    if (!willShowDBT3D && currentControl === FindingShape.MULTI_FRAME_POLYGON) {
      setCurrentControl(FindingShape.POLYGON);
    }
    setShowDBT3D(willShowDBT3D);
  };
  return setShowDBT3DImages;
};

export default useSetShowDBT3DImages;
