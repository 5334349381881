import { ComponentType, PropsWithChildren } from 'react';

import { styled } from '@mui/material/styles';

import {
  ContourViewerProps,
  ContourViewer,
} from '@InsightViewer/components/ContourViewer';

import { FindingContour } from 'src/interfaces';

import { contourColors } from '.';

const ViewOnlyContourViewer = styled<
  ComponentType<PropsWithChildren<ContourViewerProps<FindingContour>>>
>(ContourViewer)`
  polygon {
    stroke: var(--viewonly-stroke-color);
    stroke-width: 3px;
  }

  text {
    fill: var(--viewonly-stroke-color);
  }
  ${contourColors}
`;

export default ViewOnlyContourViewer;
