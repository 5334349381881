import { ReactNode } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FlagIcon from '@mui/icons-material/Flag';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import LineIcon from 'src/components/icons/LineIcon';
import MultiFramePolygonIcon from 'src/components/icons/MultiFramePolygonIcon';
import PointIcon from 'src/components/icons/PointIcon';
import PolygonIcon from 'src/components/icons/PolygonIcon';

export const statusMenu = ['All', 'To do', 'Completed', 'Reported'] as const;
export const flagMenu = ['All', 'Flagged', 'Unflagged'] as const;
export const issueMenu = ['All', 'New issue', 'No new issue'] as const;

export type StatusMenu = typeof statusMenu[number];
export type FlagMenu = typeof flagMenu[number];
export type IssueMenu = typeof issueMenu[number];

export const JobListIcons = Object.freeze({
  getAnnotationType: (annotationType: string): ReactNode => {
    switch (annotationType) {
      case 'Polygon': {
        return <PolygonIcon />;
      }
      case 'Line': {
        return <LineIcon />;
      }
      case 'Point': {
        return <PointIcon />;
      }
      case 'Multi Frame Polygon': {
        return <MultiFramePolygonIcon />;
      }
      case 'Bounding Box': {
        return <HighlightAltIcon fontSize="inherit" />;
      }
      default: {
        return 'All';
      }
    }
  },
  getFlag: (value: FlagMenu): ReactNode => {
    switch (value) {
      case 'Flagged': {
        return <FlagIcon fontSize="small" />;
      }
      case 'Unflagged': {
        return <OutlinedFlagIcon fontSize="small" />;
      }
      default: {
        return 'All';
      }
    }
  },
  getJobStatus: (value: StatusMenu): ReactNode => {
    switch (value) {
      case 'To do': {
        return <RadioButtonUncheckedIcon fontSize="small" />;
      }
      case 'Completed': {
        return <CheckCircleOutlineIcon fontSize="small" />;
      }

      case 'Reported': {
        return <ErrorOutlineIcon fontSize="small" color="error" />;
      }
      default: {
        return 'All';
      }
    }
  },
  getIssue: (value: IssueMenu): ReactNode => {
    switch (value) {
      case 'New issue': {
        return <NotificationsActiveIcon fontSize="small" />;
      }
      case 'No new issue': {
        return <NotificationsNoneIcon fontSize="small" />;
      }
      default: {
        return 'All';
      }
    }
  },
});
