import {
  RefObject,
  Suspense,
  useLayoutEffect,
  useMemo,
  useState,
  WheelEventHandler,
} from 'react';

import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue,
  noWait,
} from 'recoil';
import useResizeObserver from 'use-resize-observer';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { CornerstoneViewerLike } from '@InsightViewer/types';

import FrameNavigator from 'src/components/FrameNavigator';
import { ExpandViewButton } from 'src/components/viewers/MultiView/ExpandViewButton';
import LoadingViewer from 'src/components/viewers/loading';
import useImagePaths from 'src/hooks/useImagePaths';
import controlState from 'src/states/control';
import imageState from 'src/states/image';
import { jobState } from 'src/states/job';
import { taskState } from 'src/states/task';
import FindingUtils from 'src/utils/finding';

import DBTViewer3D from './DBTViewer3D';
import { getRightBottomHolderPortalId } from './RightBottomHolderPortal';

interface Props {
  view: string;
  viewerRef?: RefObject<CornerstoneViewerLike>;
  viewerIndex: number;
}

const DBTViewer3DContainer = ({
  view,
  viewerRef,
  viewerIndex,
}: Props): JSX.Element => {
  const {
    ref,
    width = 500,
    height = 500,
  } = useResizeObserver<HTMLDivElement>();
  const job = useRecoilValue(jobState.current);
  const currentDBTFrameNeighborsPreloadingQty = useRecoilValue(
    imageState.currentDBTFrameNeighborsPreloadingQty
  );
  const [localJob, setLocalJob] = useState(job);
  const imagePaths = useImagePaths({ job, imageKey: view });
  const [currentFrame, setCurrentFrame] = useRecoilState(
    taskState.currentFrame(view)
  );
  const setResetTime = useSetRecoilState(controlState.resetTime);

  /** Reset DBTViewerContainer's `currentFrame` and viewer status (zoom, pan, adjustments)
   * ONLY when changing jobs OR changing 2D/3D modes. `Flip` and `Invert` always persist.
   * */
  useLayoutEffect(() => {
    if (job.id === localJob.id) return;

    setLocalJob(job);
    setCurrentFrame(0);
    setResetTime(Date.now());
  }, [job, localJob, setCurrentFrame, setResetTime]);

  const totalFrames = imagePaths.length;

  const imagePath = useMemo<string>(
    () => imagePaths[currentFrame] as string,
    [currentFrame, imagePaths]
  );

  const preloadImagePaths = useMemo<string[]>(() => {
    return [
      ...imagePaths.slice(
        currentFrame + 1,
        currentFrame + 1 + currentDBTFrameNeighborsPreloadingQty
      ),
      ...imagePaths.slice(
        currentFrame - currentDBTFrameNeighborsPreloadingQty,
        currentFrame
      ),
    ];
  }, [currentFrame, imagePaths, currentDBTFrameNeighborsPreloadingQty]);

  useRecoilValue(noWait(imageState.imagesPreloading(preloadImagePaths)));

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      return;
    }
    setCurrentFrame(newValue);
  };

  const handleMouseWheelToSetCurrentFrame: WheelEventHandler<
    HTMLDivElement
  > = ({ deltaY }) => {
    const maxFrame = totalFrames - 1;
    const clampedNewCurrentFrame = FindingUtils.newCurrentFrameFromMouseWheel(
      deltaY,
      currentFrame,
      [0, maxFrame]
    );
    setCurrentFrame(clampedNewCurrentFrame);
  };

  return (
    <View ref={ref} onWheel={handleMouseWheelToSetCurrentFrame}>
      <Suspense fallback={<LoadingViewer step={'IMAGE'} />}>
        <DBTViewer3D
          view={view}
          viewerRef={viewerRef}
          currentFrame={currentFrame}
          currentImagePath={imagePath}
          totalFrames={totalFrames}
          width={width}
          height={height}
        />
      </Suspense>
      <LeftTopHolder>
        <ExpandViewButton viewIndex={viewerIndex} title={view} />
      </LeftTopHolder>
      <RightBottomHolder>
        <Box sx={{ width: '226px' }}>
          <FrameNavigator
            value={currentFrame}
            totalFrames={totalFrames}
            onChange={handleSliderChange}
          />
        </Box>
        <Box
          sx={{ paddingBottom: 0.5, marginTop: -1.5 }}
          id={getRightBottomHolderPortalId(view)}
        />
      </RightBottomHolder>
    </View>
  );
};

export default DBTViewer3DContainer;

const View = styled('div')`
  background-color: #000;
  overflow: hidden;
  position: relative;
  height: 100%;
  min-height: 0;
`;

const LeftTopHolder = styled('div')`
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 11;
`;

const RightBottomHolder = styled('div')`
  user-select: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-right: 12px;
  z-index: 11;
  pointer-events: none;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
`;
