import { useMemo, memo } from 'react';

import groupBy from 'lodash.groupby';

import AsideItem from 'src/components/aside/AsideItem';
import { SetLabelFunction } from 'src/hooks/tasks/useSetFindingLabel';
import { Label, Asset as AssetType } from 'src/interfaces';

import Asset from './Asset';
import ChildAssetWrapper from './ChildAssetWrapper';

interface Props {
  title?: string;
  assets: AssetType[];
  labels: Label[];
  onChangeLabel: SetLabelFunction;
  isDraggable: boolean;
  isCollapsible?: boolean;
}

const LabelPanel = ({
  title,
  assets,
  labels,
  onChangeLabel,
  isDraggable,
  isCollapsible,
}: Props): JSX.Element => {
  const groupedChildren = useMemo<Record<string, AssetType[][]>>(() => {
    const grouped = groupBy(assets, ({ parent }) => parent?.id);

    // Map grouped children by parent's selectedValues
    const mappedByValue = Object.keys(grouped)
      .filter(key => key !== 'undefined')
      .reduce((prev, key) => {
        const assets: Record<string, AssetType[]> = groupBy(
          grouped[key],
          ({ parent }) => JSON.stringify(parent?.selectedValues)
        );
        return {
          ...prev,
          [key]: Object.values(assets),
        };
      }, {});

    return mappedByValue;
  }, [assets]);

  /**
   * To map the assets as a visualized structure like:
   *
   * [
   *   NormalAsset,               // Normal asset which can be parent of following children groups.
   *   [childAsset, childAsset],  // Children asset grouped by the same selected value.
   *   [childAsset],              // Children asset grouped by the same selected value.
   *   NormalAsset,               // Normal asset which doesn't have following children groups.
   * ]
   */
  const mappedAsset = useMemo<(AssetType | AssetType[])[]>(() => {
    const result: (AssetType | AssetType[])[] = [];

    if (!Object.keys(groupedChildren).length) {
      return assets;
    }

    assets
      // Filter the normal assets
      .filter(asset => !asset.parent)
      .forEach(asset => {
        // Append the normal asset to the result array.
        result.push(asset);
        // Find out there are grouped children assets for this normal asset.
        if (!asset.id) {
          return;
        }
        const childrenAsset = groupedChildren[asset.id];
        if (!childrenAsset) {
          return;
        }
        result.push(...childrenAsset);
      });

    return result;
  }, [assets, groupedChildren]);

  return (
    <AsideItem
      label={title || ''}
      isDraggable={isDraggable}
      isCollapsible={isCollapsible}
    >
      {mappedAsset.map((item, index) => {
        if (Array.isArray(item)) {
          return (
            <ChildAssetWrapper
              key={index}
              childrenAssets={item}
              currentLabels={labels}
              onChangeLabel={onChangeLabel}
            />
          );
        } else {
          return (
            <Asset
              key={`${index}-${item.name}`}
              asset={item}
              currentLabels={labels}
              onChangeLabel={onChangeLabel}
              testId="nonChildLabel"
            />
          );
        }
      })}
    </AsideItem>
  );
};

export default memo(LabelPanel);
