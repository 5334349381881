import { useMemo } from 'react';

import groupBy from 'lodash.groupby';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import VisibilityButton from 'src/components/VisibilityButton';
import AsideItem from 'src/components/aside/AsideItem';
import { withErrorShake } from 'src/components/withErrorShake';
import useSetFindingIndex from 'src/hooks/tasks/useSetFindingIndex';
import useToggleFindings from 'src/hooks/tasks/useToggleFindings';
import { errorFindingIdxState } from 'src/states/finding';
import { jobState } from 'src/states/job';
import { operationModeState } from 'src/states/operationMode';
import { taskState } from 'src/states/task';

import FindingGroupPanel from './FindingGroupPanel';
import FindingItem from './FindingItem';

const ShakableFindingItem = withErrorShake(FindingItem);

export function FindingPanel(): JSX.Element {
  const jobLoadable = useRecoilValueLoadable(jobState.current);
  const findings = useRecoilValue(taskState.findings);

  const findingsByGroup = useMemo(() => groupBy(findings, 'group'), [findings]);

  const findingIndex = useRecoilValue(taskState.findingIndex);
  const currentGroupName = useRecoilValue(taskState.currentGroupName);
  const operationMode = useRecoilValue(operationModeState.current);
  const showFindings = useRecoilValue(taskState.showFindings);
  const [errorFindingIdx, setErrorFindingIdx] =
    useRecoilState(errorFindingIdxState);

  const setFindingIndex = useSetFindingIndex();
  const toggleFindings = useToggleFindings();

  const iconButton = (
    <Tooltip title="Toggle finding visibility" placement="top-end">
      <span>
        <VisibilityButton
          disabled={!operationMode.isEditable}
          hidden={!showFindings || findings.some(f => f.hidden)}
          onClick={toggleFindings()}
        />
      </span>
    </Tooltip>
  );

  const handleClickFinding = (index: number) => () => {
    setFindingIndex(index);
  };

  const handleClickGroup = (group: string) => () => {
    const clickedGroup = findingsByGroup[group];
    if (!clickedGroup) return;

    setFindingIndex(clickedGroup[0].index);
  };

  return (
    <AsideItem label="Findings" iconButtons={[iconButton]} isCollapsible>
      <DndProvider backend={HTML5Backend}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
          }}
        >
          {Object.keys(findingsByGroup).map(group => {
            const findingsInCurrentGroup = findingsByGroup[group];
            return (
              <FindingGroupPanel
                key={group}
                group={group}
                groupedViews={
                  findingsInCurrentGroup?.map(({ image }) => image) || []
                }
                hidden={!!findingsInCurrentGroup?.some(f => f.hidden)}
                viewOnly={!!findingsInCurrentGroup?.some(f => f.viewOnly)}
                onToggle={toggleFindings(group)}
                onClick={handleClickGroup(group)}
                selected={group === currentGroupName}
              >
                {findingsInCurrentGroup?.map(findingInCurrentGroup => (
                  <ShakableFindingItem
                    key={findingInCurrentGroup.index}
                    shake={errorFindingIdx === findingInCurrentGroup.index}
                    onShakeFinish={() => {
                      setErrorFindingIdx(undefined);
                    }}
                    selected={findingInCurrentGroup.index === findingIndex}
                    disabled={jobLoadable.state === 'loading'}
                    finding={findingInCurrentGroup}
                    onClick={handleClickFinding(findingInCurrentGroup.index)}
                  />
                ))}
              </FindingGroupPanel>
            );
          })}
        </Box>
      </DndProvider>
    </AsideItem>
  );
}
