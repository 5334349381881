import { useEffect } from 'react';

import { useErrorHandler } from 'react-error-boundary';
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import { ClientError, ClientErrorCode } from 'src/http/client-error';
import initialJobIdState from 'src/states/initialJobId';
import jobIdListState from 'src/states/jobIdList';
import { projectState } from 'src/states/project';
import assetValidator from 'src/utils/AssetValidator';
import confirmNoAssetValidationMismatch from 'src/utils/AssetValidator/assetMismatchHandler';

type Props = {
  shouldThrowError: boolean;
};

const useInitProject = (props?: Props): void => {
  const project = useRecoilValue(projectState.current);
  const setCurrentJobId = useSetRecoilState(jobIdListState.currentJobId);
  const resetCurrentJobId = useResetRecoilState(jobIdListState.currentJobId);

  const initialAnnotatorJobIdLoadable = useRecoilValueLoadable(
    initialJobIdState.annotator
  );
  const initialReviewerJobIdLoadable = useRecoilValueLoadable(
    initialJobIdState.reviewer
  );

  const handleError = useErrorHandler();

  /**
   * Initialize currentJobId with initialJobId when
   * - Annotator/Reviewer change the project
   * - Reviewer change the associate
   */
  useEffect(() => {
    const { state, contents } = project.isReviewer
      ? initialReviewerJobIdLoadable
      : initialAnnotatorJobIdLoadable;

    if (state === 'hasError') {
      handleError(contents);
    }

    if (state === 'hasValue') {
      if (contents === null && (!props || props.shouldThrowError)) {
        handleError(
          new ClientError({
            code: ClientErrorCode.NO_JOB_ASSIGNED,
          })
        );
      }
      setCurrentJobId(contents);
    }
  }, [
    props,
    handleError,
    initialAnnotatorJobIdLoadable,
    initialReviewerJobIdLoadable,
    project.isReviewer,
    resetCurrentJobId,
    setCurrentJobId,
  ]);

  /**
   * Validate assets of claim object when project is changed
   */
  useEffect(() => {
    try {
      // initialize asset validator
      if (project.claim.assetValidations) {
        confirmNoAssetValidationMismatch(
          project.claim.assets,
          project.claim.assetValidations
        );

        assetValidator.init(project.claim.assetValidations);
      } else {
        assetValidator.clear();
      }
    } catch (error) {
      handleError(error);
    }
  }, [handleError, project.claim.assetValidations, project.claim.assets]);
};

export default useInitProject;
