import { ReactNode, useCallback, useMemo } from 'react';

import debounce from 'lodash.debounce';
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';

import useMoveToNextJob from 'src/hooks/currentJobId/useMoveToNextJob';
import useSetFindingIndex from 'src/hooks/tasks/useSetFindingIndex';
import useAlert from 'src/hooks/useAlert';
import useConfirmModal from 'src/hooks/useConfirmModal';
import useErrorHandler from 'src/hooks/useErrorHandler';
import { syncAnnotation as syncAnnotationService } from 'src/services/annotation';
import { errorFindingIdxState } from 'src/states/finding';
import imageState from 'src/states/image';
import { jobState, useRefreshCachedJob } from 'src/states/job';
import jobIdListState from 'src/states/jobIdList';
import { useRefreshJobList } from 'src/states/jobList';
import { errorLabelNameState } from 'src/states/label';
import { projectState } from 'src/states/project';
import { taskState } from 'src/states/task';
import {
  validateDecisionAssets,
  isUnassignedAssetLabelError,
} from 'src/utils/claim';
import FindingUtils from 'src/utils/finding';

export interface JobProviderProps {
  children: ReactNode;
}

export function useSaveJob(): () => Promise<boolean> | undefined {
  const { open: openAlert } = useAlert();
  const { getConfirmation } = useConfirmModal();

  const decisionLabelsLoadable = useRecoilValueLoadable(
    taskState.decisionLabels
  );
  const decisionLabels = useMemo(
    () =>
      decisionLabelsLoadable.state === 'hasValue'
        ? decisionLabelsLoadable.contents
        : [],
    [decisionLabelsLoadable.contents, decisionLabelsLoadable.state]
  );

  const findingsLoadable = useRecoilValueLoadable(taskState.findings);
  const findings = useMemo(
    () =>
      findingsLoadable.state === 'hasValue' ? findingsLoadable.contents : [],
    [findingsLoadable.contents, findingsLoadable.state]
  );

  const project = useRecoilValue(projectState.current);
  const isCPCProject = useRecoilValue(projectState.isCPC);
  const isDBTProject = useRecoilValue(projectState.isDBT);
  const isDBTWithBoth2D3DImages = useRecoilValue(
    imageState.isDBTWithBoth2D3DImages
  );

  const setFindingIndex = useSetFindingIndex();
  const currentJobId = useRecoilValue(jobIdListState.currentJobId);
  const setIsAnnotating = useSetRecoilState(jobState.isAnnotating);
  const isLastJobInProject = useRecoilValue(jobIdListState.isLastJobInProject);

  const setErrorLabel = useSetRecoilState(errorLabelNameState);
  const setErrorFindingIdx = useSetRecoilState(errorFindingIdxState);

  const syncAnnotation = useErrorHandler(syncAnnotationService);
  const moveToNextJob = useMoveToNextJob();
  const refreshJobList = useRefreshJobList();
  const refreshCachedJob = useRefreshCachedJob(currentJobId);

  const hasAnyUndesiredUngroupedFinding =
    FindingUtils.hasAnyUndesiredUngroupedFinding(findings);

  const showFindingGroupingAlert =
    isDBTProject && isDBTWithBoth2D3DImages && hasAnyUndesiredUngroupedFinding;

  const saveJob = useCallback(async () => {
    try {
      const notConfirmedFinding = findings.find(({ confirmed }) => !confirmed);

      if (notConfirmedFinding) {
        setFindingIndex(notConfirmedFinding.index, false);
        setErrorFindingIdx(notConfirmedFinding.index);
        throw new Error(
          `Finding #${notConfirmedFinding.index} is not confirmed yet!`
        );
      }

      // Validate decision assets.
      validateDecisionAssets(project.claim, decisionLabels);

      if (showFindingGroupingAlert) {
        const confirmed = await getConfirmation({
          title: 'Ungrouped finding(s)',
          description: `The abnormal findings are not marked on 4 images on the same
                        side in both 2D and 3D. Please check if the findings have
                        been correctly annotated in the annotation tools.`,
          confirmButtonText: 'Save',
        });
        if (!confirmed) return false;
      }

      // need to filter out viewOnly findings and labels
      await syncAnnotation({
        jobId: currentJobId,
        findings: findings.filter(f => !f.viewOnly),
        labels: decisionLabels,
      });

      setIsAnnotating(false);
      refreshJobList();
      refreshCachedJob();

      if (isLastJobInProject) {
        openAlert({
          message: 'Congratulations! All tasks done!',
          type: 'success',
        });
      } else {
        openAlert({
          message: 'All labels and findings are saved.',
          type: 'success',
          autoHide: true,
        });

        // if cpc, then we don't go to next job automatically
        // in order to prevent a lot of UI syncing bugs and simplify user flow
        if (!isCPCProject) {
          moveToNextJob();
        }
      }
      return true;
    } catch (err) {
      if (isUnassignedAssetLabelError(err)) {
        setErrorLabel(err.assetName);
      }
      const error = err as Error;
      setFindingIndex(undefined, false);
      openAlert({
        message: `${error.message}`,
        type: 'error',
      });
      return false;
    }
  }, [
    currentJobId,
    findings,
    isLastJobInProject,
    decisionLabels,
    moveToNextJob,
    openAlert,
    project.claim,
    refreshCachedJob,
    refreshJobList,
    setErrorFindingIdx,
    setIsAnnotating,
    setErrorLabel,
    setFindingIndex,
    syncAnnotation,
    isCPCProject,
    getConfirmation,
    showFindingGroupingAlert,
  ]);

  // Debounce for preventing lots of save action, especially for keyboard shortcut
  // return debounce(saveJob, 500); // TODO: replace with below after fixing
  // Promise<void> | undefined issue with `act(() => result.current.saveJob())`
  return debounce(saveJob, 500);
}
