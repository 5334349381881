import { Asset, Label } from 'src/interfaces';

const AssetUtils = (() => {
  const isThisLabelSelected =
    (label: Label) =>
    (selectedValue: string | boolean): boolean => {
      if (
        typeof label.value === 'object' &&
        typeof selectedValue === 'string'
      ) {
        return !!label.value[selectedValue];
      }

      const selectedValueStringAsBoolean =
        Boolean(String(selectedValue).toLowerCase()) === true;

      if (typeof label.value === 'boolean') {
        return selectedValueStringAsBoolean === label.value;
      }
      return false;
    };

  const isParentLabelSelected = (
    asset: Asset,
    currentLabels: Label[],
    assets: Asset[] = []
  ) =>
    // Just return true when target asset has no parent or index for common use cases.
    {
      const hasParent = !!asset.parent;
      if (!hasParent) return true;

      const parentAssetId = asset.parent?.id;
      const parentAsset = assets.find(({ id }) => id === parentAssetId);
      const parentAssetName = parentAsset?.name;
      const parentLabel = currentLabels.find(
        ({ name }) => name === parentAssetName
      );
      if (!parentLabel) return false;

      const selectedValue = asset.parent?.selectedValues?.[0] || '';
      return isThisLabelSelected(parentLabel)(selectedValue);
    };
  return Object.freeze({
    isThisLabelSelected,
    isParentLabelSelected,
  });
})();

export default AssetUtils;
