import { useResetRecoilState, useSetRecoilState } from 'recoil';

import controlState from 'src/states/control';

type Return = () => void;

const useResetControls = (): Return => {
  const resetControl = useResetRecoilState(controlState.current);
  const resetInvert = useResetRecoilState(controlState.invert);
  const resetFlip = useResetRecoilState(controlState.flip);
  const setResetTime = useSetRecoilState(controlState.resetTime);

  return () => {
    resetControl();
    resetFlip();
    resetInvert();
    setResetTime(Date.now());
  };
};

export default useResetControls;
