import { FormEvent, useCallback, useEffect, useState, MouseEvent } from 'react';

import cookie from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { Footer } from 'src/components/forms/Footer';
import { FormPageContainer } from 'src/components/forms/FormPageContainer';
import LogoIcon from 'src/components/icons/LogoIcon';
import { modalityKey } from 'src/http';

type Databases = {
  databases: string[];
};

// This page will be removed when BE is ready.
export default function Database(): JSX.Element {
  const location = useLocation<Databases>();
  const history = useHistory();

  const [modality, setModality] = useState<string>();

  useEffect(() => {
    if (location.state === undefined) history.push('/login');
  }, [history, location.state]);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      history.push('./workspace');
    },
    [history]
  );

  // This feature is a temporary solution
  const handleChangeModality = (
    _: MouseEvent<HTMLElement>,
    value: string | null
  ) => {
    if (value !== null) {
      setModality(value);
      cookie.set(modalityKey, value);
    }
  };

  return (
    <FormPageContainer>
      <h1>
        <LogoIcon />
      </h1>

      <StyledForm onSubmit={handleSubmit}>
        <ToggleButtonGroup
          fullWidth
          size="small"
          value={modality}
          exclusive
          onChange={handleChangeModality}
        >
          {location.state?.databases.map(db => (
            <StyledToggleButton value={db} key={db}>
              <ModalityWrap>{db}</ModalityWrap>
            </StyledToggleButton>
          ))}
        </ToggleButtonGroup>
        <Button
          type="submit"
          variant="contained"
          size="large"
          disabled={!modality}
        >
          Go to workspace
        </Button>
      </StyledForm>
      <Footer />
    </FormPageContainer>
  );
}

const StyledToggleButton = styled(ToggleButton)`
  display: flex;
  padding: 0;
`;

const StyledForm = styled.form`
  margin-bottom: 50px;
`;

const ModalityWrap = styled.div`
  flex: 1;
  padding: 0.35rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
