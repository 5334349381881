import { atom, selector } from 'recoil';

import { Shortcut, ShortcutDefinition } from 'src/interfaces/shortcut';
import { confirmedProjectShortcuts } from 'src/utils/claim';

import { projectState } from './project';

/**
 * For keeping just activated shortcut
 */
const activated = atom<Shortcut | undefined>({
  key: 'shortcutState/activated',
  default: undefined,
});

/**
 * For listing up whole added shortcut definitions
 */
const definitions = atom<ShortcutDefinition[]>({
  key: 'shortcutState/definitions',
  default: [],
});

/**
 * For getting executable shortcut definitions
 */
const executableDefinitions = selector<ShortcutDefinition[]>({
  key: 'shortcutState/executableDefinitions',
  get: ({ get }) => {
    const defs = get(definitions);
    const isConfirmedProject = get(projectState.isConfirmed);

    const conditionalControls = isConfirmedProject
      ? confirmedProjectShortcuts(defs)
      : defs;

    return conditionalControls.filter(({ validation }) =>
      validation === undefined ? true : validation
    );
  },
});

const shortcutState = Object.freeze({
  activated,
  definitions,
  executableDefinitions,
});

export default shortcutState;
