import { LocalJob } from 'src/interfaces/job';
import { isArray } from 'src/utils/typeHelper';

interface Props {
  job?: LocalJob;
  imageKey: string;
}

const useImagePath = ({ job, imageKey }: Props): string => {
  if (!job?.images) {
    return '';
  }

  const jobImage = job?.images[imageKey];

  if (
    !jobImage ||
    isArray(jobImage) ||
    jobImage.type === 'multiple' ||
    !jobImage.path
  ) {
    return '';
  }

  return jobImage.path;
};

export default useImagePath;
