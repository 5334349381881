import { Suspense } from 'react';

import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import ErrorPanel from 'src/components/ErrorFallback/ErrorPanel';
import Loading from 'src/components/loading';
import ConfirmModal from 'src/components/modals/ConfirmModal';
import useSyncCPCState from 'src/hooks/cpc/useSyncCPCState';
import { Footer } from 'src/pages/workspace/footer';
import Header from 'src/pages/workspace/header';
import { Task } from 'src/pages/workspace/task';
import { projectState } from 'src/states/project';
import { isCPCPriorCaseWindow } from 'src/utils/cpc';

import { Alert } from './alert';

export default function WorkspacePage(): JSX.Element {
  const isCPCProject = useRecoilValue(projectState.isCPC);
  useSyncCPCState();

  return (
    <>
      <Container>
        <Header />
        {isCPCPriorCaseWindow && !isCPCProject ? (
          <ErrorPanel
            title="Non-CPC project"
            subTitle1="The `/prior` page can only be used in CPC projects. Please close this tab/window or select a CPC project"
          />
        ) : (
          <>
            <Suspense fallback={<Loading />}>
              <Task />
            </Suspense>
            <Footer />
          </>
        )}
      </Container>
      <Alert />
      <ConfirmModal />
    </>
  );
}

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  > :nth-child(2) {
    flex: 1;
    min-height: 0;
  }
`;
