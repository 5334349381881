import styled from 'styled-components';

const RowButtonGroup = styled.div`
  display: flex;
  margin: 0 -4px;
  > * {
    flex: 1;
    margin: 0 4px;
  }
`;

export default RowButtonGroup;
