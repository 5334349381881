import { dataApi } from 'src/http';
import { ClientError, ClientErrorCode } from 'src/http/client-error';
import { DEFAULT_PAGE_SIZE, Report, ReportListSchema } from 'src/interfaces';

interface GetReportListProps {
  jobId?: string;
  userId?: string;
  includeResolved?: boolean;
  resolvedOnly?: boolean;
  dateFrom?: string;
  dateTo?: string;
  sort?: string;
  page: number;
  pageSize?: number;
}

export const getReportList = async ({
  jobId,
  userId,
  includeResolved = true,
  resolvedOnly,
  dateFrom,
  dateTo,
  sort = '-createdAt',
  page,
  pageSize = DEFAULT_PAGE_SIZE,
}: GetReportListProps): Promise<ReportListSchema> => {
  const { data } = await dataApi.get<ReportListSchema>(`reports/`, {
    params: {
      job_id: jobId,
      user: userId,
      include_resolved: includeResolved,
      resolved_only: resolvedOnly,
      date_from: dateFrom,
      date_to: dateTo,
      sort: sort,
      page: page,
      page_size: pageSize,
    },
  });
  return data;
};

export interface PostReportProps {
  text?: string;
  jobId?: string;
}

export const postReport = async ({
  text,
  jobId,
}: PostReportProps): Promise<Report> => {
  if (!text) {
    throw new ClientError({
      code: ClientErrorCode.BAD_REQUEST,
      message: 'No descriptions',
    });
  }
  const { data } = await dataApi.post<Report>(`reports/`, {
    text,
    job: jobId,
    resolved: false,
  });
  return data;
};
