import { useCallback } from 'react';

import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';

import { jobState } from 'src/states/job';
import { taskState } from 'src/states/task';

import useInitTask from './useInitTask';

const useResetAnnotations = (): (() => void) => {
  const jobLoadable = useRecoilValueLoadable(jobState.current);

  const setFindingIndex = useSetRecoilState(taskState.findingIndex);
  const setIsAnnotating = useSetRecoilState(jobState.isAnnotating);
  const initTask = useInitTask();

  const resetAnnotations = useCallback(() => {
    if (!window.confirm('Do you want to reset all labels and findings?')) {
      return;
    }

    if (jobLoadable.state !== 'hasValue') {
      return;
    }

    initTask(jobLoadable.contents);
    setFindingIndex(undefined);
    setIsAnnotating(false);
  }, [
    initTask,
    jobLoadable.contents,
    jobLoadable.state,
    setFindingIndex,
    setIsAnnotating,
  ]);

  return resetAnnotations;
};

export default useResetAnnotations;
