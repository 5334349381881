import { useRecoilState, useSetRecoilState } from 'recoil';

import { taskState } from 'src/states/task';

type Return = (group?: string) => () => void;

const useToggleFindings = (): Return => {
  const setLocalFindings = useSetRecoilState(taskState.localFindings);
  const [showFindings, setShowFindings] = useRecoilState(
    taskState.showFindings
  );

  return group => () => {
    setShowFindings(prev => !prev);

    if (!!group) {
      setLocalFindings(prev => {
        const groupFindingsHiddenStatus = prev
          .filter(f => f.group === group)
          .map(({ hidden }) => !!hidden);

        return prev.map(finding =>
          finding.group === group
            ? {
                ...finding,
                hidden: groupFindingsHiddenStatus.includes(false),
              }
            : finding
        );
      });
    } else {
      setLocalFindings(prev =>
        prev.map(finding => ({
          ...finding,
          hidden: showFindings,
        }))
      );
    }
  };
};

export default useToggleFindings;
