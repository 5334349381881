import { SelectorAttributes } from '@lunit-io/radiology-data-interface';

import {
  Claim,
  Override,
  Asset,
  SelectorLabel,
  Label,
  SelectorAsset,
  AssetForm,
  FindingShape,
} from 'src/interfaces';
import { ShortcutDefinition } from 'src/interfaces/shortcut';
import { ensure } from 'src/utils/typeHelper';

import AssetUtils from './asset';

/**
 * Override claims according to current value.
 *
 * @description It changes the behavior of assets from whatever the existing/default configuration
 * is based. In the case of something like skip, it can override the allowEmpty configuration of
 * other assets to allow them to be empty, in other words, we need it for exceptions or edge cases
 * (so that not to break the existing workflow)
 *
 * @param claim claim of the project
 * @param labels current label got from the user
 * @returns modified claim
 */
export function overrideClaim(claim: Claim, labels: Label[]): Claim {
  const newClaim: Claim = JSON.parse(JSON.stringify(claim));
  if (newClaim.overrides) {
    newClaim.overrides.forEach((override: Override) => {
      let { filter } = override;
      newClaim.assets.forEach(asset => {
        const [label]: Label[] = labels.filter(lbl => lbl.name === asset.name);
        let labelValue: string;

        if (label)
          switch (asset.form) {
            case AssetForm.SELECTOR:
              labelValue = `"${Object.keys(label.value)
                .filter(category => (label as SelectorLabel).value[category])
                .join('&')}"`;
              break;
            default:
              labelValue = label.value.toString();
              break;
          }
        else
          switch (asset.form) {
            case AssetForm.TOGGLER:
              labelValue = 'false';
              break;
            default:
              labelValue = '';
              break;
          }
        filter = filter.replaceAll(`$\{${asset.name}}`, labelValue);
      });

      // If filter is true, then override values
      // TODO(jhlee): "eval" should be replaced!!
      // eslint-disable-next-line no-eval
      if (eval(filter)) {
        override.actions.forEach(action => {
          const assetIndex = newClaim.assets.findIndex(
            asset => asset.name === action.asset
          );
          for (const key in action.value) {
            // TODO(jhlee): "eval" should be replaced!!
            // eslint-disable-next-line no-eval
            action.value[key] = eval(action.value[key] as string);
          }
          /**
           * Override feature only support to update `asset.formAttributes`
           * @see https://lunit.atlassian.net/browse/AISS-44?focusedCommentId=21885
           */
          const targetAsset = ensure(newClaim.assets[assetIndex]);
          if (targetAsset.formAttributes) {
            newClaim.assets[assetIndex] = {
              ...targetAsset,
              formAttributes: {
                ...targetAsset.formAttributes,
                ...action.value,
              },
            };
          }
        });
      }
    });
  }
  return newClaim;
}

type SelectorValue = { [k: string]: boolean };

const determineSelector = (
  labelCategories: SelectorValue,
  allowEmpty: boolean,
  allowMultiSelect: boolean,
  isParentSelected?: boolean
): boolean => {
  if (!allowEmpty) {
    // If this asset have a parent and parent asset has been selected.
    if (isParentSelected !== undefined && !isParentSelected) {
      return false;
    }
    const isEmpty = Object.values(labelCategories).every(category => !category);
    if (isEmpty) return true;
  }

  if (!allowMultiSelect) {
    // [true, false, false, true, false] selectedOnes: 2
    const selectedOnes = Object.values(labelCategories).reduce<number>(
      (a, c) => (c ? a + 1 : a),
      0
    );
    if (selectedOnes > 1) return true;
  }

  return false;
};

/**
 * Check whether invalid value exists according to overrided claim
 * @param asset (overrided) asset definition in claim
 * @param label current value of the asset
 * @returns Whether the value is invalid or not
 */
export function findInvalidSelector(
  asset: Asset,
  label?: Label,
  isParentSelected?: boolean
): Asset | undefined {
  if (asset && asset.form === AssetForm.SELECTOR) {
    const formAttributes = (asset as SelectorAsset)
      .formAttributes as SelectorAttributes;

    // { category1: boolean, category2: boolean,... }
    const defaultLabel = formAttributes.categories.reduce(
      (combined: SelectorValue, curr) => {
        combined[curr.name] = false;
        return combined;
      },
      {}
    );

    const determined = determineSelector(
      (label?.value as SelectorValue) || defaultLabel,
      formAttributes.allowEmpty || false,
      formAttributes.allowMultiSelect || false,
      isParentSelected
    );

    if (determined) return asset;
  }

  return undefined;
}

export const validateDecisionAssets = (claim: Claim, labels: Label[]): void => {
  const newClaim = overrideClaim(claim, labels);

  const findCategory = (id: number | undefined) => {
    if (id === undefined) return undefined;
    return newClaim.categories?.find(cate => cate.id === id);
  };

  newClaim.assets
    .filter(x => x.group === 'decision')
    .forEach(asset => {
      const label = labels.find(label => label.name === asset.name);
      const isParentSelected = AssetUtils.isParentLabelSelected(
        asset,
        labels,
        newClaim.assets
      );

      const invalidAsset = findInvalidSelector(asset, label, isParentSelected);

      if (!!invalidAsset) {
        const parentOfInvalidAsset = newClaim.assets.find(
          asset => asset.id === invalidAsset.parent?.id
        );

        if (parentOfInvalidAsset) {
          const parentOfInvalidAssetCategory = findCategory(
            parentOfInvalidAsset.category
          );

          const rootCategoryText = findCategory(
            parentOfInvalidAssetCategory?.parent
          )?.text;

          if (
            rootCategoryText === undefined ||
            parentOfInvalidAssetCategory?.text === undefined
          ) {
            throw new Error(
              'Latest Asset Category not found: please check the claim configuration contains valid categories.'
            );
          }

          const errorAssetPath = [
            rootCategoryText,
            parentOfInvalidAssetCategory?.text,
            parentOfInvalidAsset.text,
            invalidAsset.text,
          ]
            .filter(Boolean)
            .join(' > ');

          throw new UnassignedAssetLabelError(
            asset.text,
            `The label '${errorAssetPath}' must be assigned.`
          );
        }
      }
    });
};

export class UnassignedAssetLabelError {
  assetName = '';
  message? = '';
  constructor(assetName: string, message?: string) {
    this.assetName = assetName;
    this.message = message;
  }
}

export const isUnassignedAssetLabelError = (
  err: unknown
): err is UnassignedAssetLabelError => {
  return err instanceof UnassignedAssetLabelError;
};

export const drawShortcuts: string[] = [
  FindingShape.MULTI_FRAME_POLYGON,
  FindingShape.POLYGON,
  FindingShape.LINE,
  FindingShape.POINT,
];

export const isAllowedInConfirmedProject = (shortcut: string): boolean => {
  return !drawShortcuts.includes(shortcut) && shortcut !== 'saveData';
};

export const confirmedProjectShortcuts = (
  shortcuts: ShortcutDefinition[]
): ShortcutDefinition[] =>
  shortcuts.filter(({ shortcut }) => isAllowedInConfirmedProject(shortcut));
