import cookie from 'js-cookie';
import { atom, selector } from 'recoil';

import { getAccessToken, authKey } from 'src/http';
import { ClientError, ClientErrorCode } from 'src/http/client-error';
import { Project } from 'src/interfaces';
import { Me, SignedMe } from 'src/interfaces/me';
import { getMe } from 'src/services/user';

import { DEFAULT_ME } from './defaults';

const me = atom<Me>({
  key: 'authState/me',
  default: selector<Me>({
    key: 'authState/me/DEFAULT',
    get: async (): Promise<Me> => {
      const token = getAccessToken();
      if (typeof token === 'string') {
        try {
          const user = await getMe();
          return {
            status: 'SIGNED',
            user,
          };
        } catch (error) {
          cookie.remove(authKey);
          throw error;
        }
      }
      return DEFAULT_ME;
    },
  }),
});

const signedMe = selector<SignedMe>({
  key: 'authState/signedMe',
  get: ({ get }): SignedMe => {
    const meState = get(me);

    if (meState.status !== 'SIGNED' || !meState.user) {
      throw new ClientError({
        code: ClientErrorCode.UNAUTHENTICATED,
        message: `Not authenticated user : ${meState}`,
      });
    }

    return meState as SignedMe;
  },
});

/**
 * We call "auth" to current user.
 * This state is for the project list assigned to current user.
 *
 * [TODO]
 * - It is set when whole projects we got from API.
 *   So this state should be updated as a derived state when global project list has been replaced to recoil state.
 * - This auth project list should not be duplicated with global project list state.
 *   So we don't need to save whole of project on here. This state can be replaced as a simple list of project id.
 */
const assignedProjectList = atom<Project[]>({
  key: 'authState/assignedProjectList',
  default: [],
});

const myId = selector({
  key: 'authState/myId',
  get: ({ get }) => {
    const me = get(signedMe);
    return me.user.id;
  },
});

const authState = Object.freeze({
  me,
  signedMe,
  myId,
  assignedProjectList,
});

export default authState;
