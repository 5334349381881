import { ViewPresetIconColor, ViewPresetIconProps } from 'src/interfaces/ui';

const HorizontalFourViewerIcon = ({
  status = 'ENABLED',
}: ViewPresetIconProps): JSX.Element => (
  <svg viewBox="0 0 32 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.895431 24 2 24H30C31.1046 24 32 23.1046 32 22V2C32 0.895431 31.1046 0 30 0H2ZM3 3H9H11H15H17H21H23H29V21H23H21H17H15H11H9H3V3ZM17 5H21V19H17V5ZM23 19V5H27V19H23ZM15 19V5H11V19H15ZM5 5H9V19H5V5Z"
      fill={ViewPresetIconColor[status]}
    />
  </svg>
);

export default HorizontalFourViewerIcon;
