import { useMemo } from 'react';

import { adjust } from './adjust';
import { pan } from './pan';
import { zoomWheel } from './zoom';

export function useViewerInteractions(
  interactions: ('pan' | 'adjust' | 'zoom' | string)[],
  {
    element = null,
    zoomInteraction = zoomWheel,
  }: {
    element?: HTMLElement | null;
    zoomInteraction?: typeof zoomWheel;
  }
): (
  | ReturnType<typeof pan>
  | ReturnType<typeof adjust>
  | ReturnType<typeof zoomWheel>
  | undefined
)[] {
  return useMemo(
    () => {
      return interactions.map(interactionName => {
        switch (interactionName) {
          case 'pan':
            return pan({ element });
          case 'adjust':
            return adjust({ element });
          case 'zoom':
            return zoomInteraction({ element });
          default:
            return undefined;
        }
      });
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [element, ...interactions]
  );
}
