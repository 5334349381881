import styled from 'styled-components';

import IconButton from '@mui/material/IconButton';

const ButtonListIconButton = styled(IconButton)`
  box-sizing: content-box;
  width: 1.25rem;
  height: 1.25rem;
  padding: 8px;
  border-radius: var(--ctl-border-radius);

  &,
  &.Mui-disabled {
    color: var(--ctl-background-color-active);
    background-color: var(--ctl-background-color-lighter);
    background-color: var(--ctl-background-color-lighter);
  }

  &.Mui-disabled {
    opacity: 0.4;
  }

  &:hover {
    background-color: var(--ctl-background-color-lightest);
  }
`;

export default ButtonListIconButton;
