import { useRecoilState } from 'recoil';

import useSyncCurrentJob from 'src/hooks/currentJobId/useSyncCurrentJob';
import useDiscardAnnotations from 'src/hooks/tasks/useDiscardAnnotations';
import useAlert from 'src/hooks/useAlert';
import jobIdListState from 'src/states/jobIdList';

type Return = (jobId?: string) => Promise<void>;

const useMoveByJobId = (): Return => {
  const [currentJobId, setCurrentJobId] = useRecoilState(
    jobIdListState.currentJobId
  );
  const checkDiscard = useDiscardAnnotations();

  const syncCurrentJob = useSyncCurrentJob();

  const { open: openAlert } = useAlert();

  return async (jobId?: string) => {
    try {
      if (!jobId) {
        throw new Error(`No job is found with given job ID : ${jobId}`);
      }
      if (jobId === currentJobId) {
        return;
      }

      const discard = await checkDiscard();
      if (!discard) return;

      await syncCurrentJob(jobId);
      setCurrentJobId(jobId);
    } catch (error) {
      openAlert({
        type: 'error',
        message: (error as Error).message,
      });
    }
  };
};

export default useMoveByJobId;
