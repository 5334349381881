import { dataApi } from 'src/http';
import { ClientError, ClientErrorCode } from 'src/http/client-error';
import { RemoteProjectListResp, RemoteProject } from 'src/interfaces';

export const getProjects = async (): Promise<RemoteProject[]> => {
  const { data } = await dataApi.get<RemoteProjectListResp>('projects/', {
    params: { sort: ['-createdAt'], page_size: 100 },
  });

  if (data.projects.length < 1) {
    throw new ClientError({ code: ClientErrorCode.NO_PROJECT_ASSIGNED });
  }

  return data.projects;
};

export const getProject = async (projectId: string): Promise<RemoteProject> => {
  const { data } = await dataApi.get<RemoteProject>(`projects/${projectId}/`);
  return data;
};

type SyncProjectCurrJobIdProps = {
  projectId: string;
  jobId: string;
};

export const syncProjectCurrJobId = async ({
  projectId,
  jobId,
}: SyncProjectCurrJobIdProps): Promise<void> => {
  await dataApi.patch<RemoteProject>(`projects/${projectId}/focus/v2/`, {
    jobId,
  });
};
