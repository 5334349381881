import IssueIconBase, { ISSUE_ICON_WIDTH } from './IssueIconBase';

interface Props {
  label: string;
  activated: boolean;
}

const IssueIcon = ({ label, activated }: Props): JSX.Element => {
  return (
    <svg
      viewBox={`0 0 ${ISSUE_ICON_WIDTH} ${ISSUE_ICON_WIDTH}`}
      x="0"
      y="0"
      style={{ width: ISSUE_ICON_WIDTH, height: ISSUE_ICON_WIDTH }}
    >
      <IssueIconBase label={label} activated={!!activated} />
    </svg>
  );
};

export default IssueIcon;
