import { useSetRecoilState } from 'recoil';

import { taskState } from 'src/states/task';

const useConfirmFinding = (): ((
  findingIndex: number,
  confirmed?: boolean
) => void) => {
  const setLocalFindings = useSetRecoilState(taskState.localFindings);

  const confirmFinding = (findingIndex: number, confirmed = true) => {
    setLocalFindings(prev =>
      prev.map(finding => {
        if (finding.index === findingIndex) {
          return {
            ...finding,
            confirmed,
          };
        } else {
          return finding;
        }
      })
    );
  };

  return confirmFinding;
};

export default useConfirmFinding;
