import { useRecoilValue } from 'recoil';

import imageState from 'src/states/image';

const NextImagePreloader = (): null => {
  useRecoilValue(imageState.nextJobImagesLoading);
  return null;
};

export default NextImagePreloader;
