import { useState, useEffect, useRef } from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import Button from '@mui/material/Button';

import { Report } from 'src/interfaces';
interface Props {
  reports: Report[];
  isShowMoreAvailable: boolean;
  isReportsLoading?: boolean;
  onShowMore: () => void;
}

const ReportList = ({
  reports,
  isShowMoreAvailable,
  onShowMore,
  isReportsLoading,
}: Props): JSX.Element => {
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (!isReportsLoading && buttonRef.current) {
      // very rarely, isReportsLoading and actual api call resolving not matching
      // causing `scrollIntoView` run before more reports added into DOM
      // for safety I added setTimeout, if there is better solution
      // this would be replaced
      timeout = setTimeout(() => {
        buttonRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 50);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isReportsLoading]);

  const handleClickItem = (reportId: string) => () =>
    setSelectedId(prev => (prev === reportId ? undefined : reportId));

  return (
    <Container>
      <Reports>
        {reports.map(({ id, resolved, createdAt, text }) => {
          const expanded = id === selectedId;
          const foldAndResolved = resolved && !expanded;
          return (
            <ReportListItem
              key={id}
              onClick={handleClickItem(id)}
              expanded={expanded}
            >
              <CreatedAt hasCanceled={foldAndResolved}>
                {dayjs(new Date(createdAt)).format('YYYY.MM.DD')}
              </CreatedAt>
              <Text hasShorten={!expanded} hasCanceled={foldAndResolved}>
                {text}
              </Text>
              {resolved && <Tag>Resolved</Tag>}
            </ReportListItem>
          );
        })}
        <FixedBottom>
          <Button
            ref={buttonRef}
            fullWidth
            color="primary"
            variant="contained"
            onClick={onShowMore}
            disabled={!isShowMoreAvailable || isReportsLoading}
          >
            {isReportsLoading ? 'Loading...' : 'Show More'}
          </Button>
        </FixedBottom>
      </Reports>
    </Container>
  );
};

export default ReportList;

const Container = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  margin-top: 24px;
`;

const Reports = styled.div`
  overflow-y: auto;
`;

const ReportListItem = styled.button<{ expanded: boolean }>`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 8px;
  background-color: transparent;
  color: var(--ctl-color-active);
  border-width: 1px 0;
  border-style: solid;
  border-color: var(--ctl-border-color-light);
  cursor: pointer;
  text-align: left;
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    opacity 0.1s ease;
  opacity: 0.7;
  ${({ expanded }) =>
    expanded &&
    `
      position: relative;
      opacity: 1;
    `}
  & ~ & {
    margin-top: -1px;
  }
  &:hover {
    opacity: 1;
  }
  &:last-child {
    border-bottom: 1px solid var(--ctl-border-color-light);
    margin-bottom: 24px;
  }
`;

const CreatedAt = styled.span<{ hasCanceled: boolean }>`
  margin-right: 16px;
`;

const Text = styled.span<{ hasCanceled: boolean; hasShorten: boolean }>`
  overflow: hidden;
  width: 100%;
  ${({ hasShorten }) =>
    hasShorten &&
    `
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`;

const Tag = styled.em`
  display: inline-block;
  font-style: initial;
  margin-left: 8px;
  background-color: var(--ctl-brand-color);
  font-size: 0.85rem;
  padding: 2px 6px;
  border-radius: 4px;
`;

const FixedBottom = styled.div`
  width: 100%;
  margin-top: 15px;
  bottom: 0;
`;
