import { MouseEventHandler, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import IconButton from '@mui/material/IconButton';

import IssueDate from 'src/components/Issue/IssueDate';
import useAlert from 'src/hooks/useAlert';
import useErrorHandler from 'src/hooks/useErrorHandler';
import { IssueUpdate } from 'src/interfaces/issue';
import { toggleReadUpdate } from 'src/services/issue';
import authState from 'src/states/auth';
import { useRefreshUpdates } from 'src/states/issues';

type Props = Pick<IssueUpdate, 'createdAt' | 'id' | 'readUsers'>;

const NewIssueAction = ({ createdAt, id, readUsers }: Props): JSX.Element => {
  const myId = useRecoilValue(authState.myId);
  const refreshUpdates = useRefreshUpdates();
  const toggleRead = useErrorHandler(toggleReadUpdate);
  const { open: openAlert } = useAlert();

  const read = useMemo(() => readUsers.includes(myId), [readUsers, myId]);

  const handleClickRead: MouseEventHandler<HTMLButtonElement> = async e => {
    e.stopPropagation();
    try {
      await toggleRead({
        updateId: id,
        readStatus: !readUsers.includes(myId),
      });
      refreshUpdates();
    } catch (error) {
      openAlert({
        type: 'error',
        message: `Failed to toggle read status: ${(error as Error).message}`,
      });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: '0.5rem',
        alignItems: 'center',
      }}
    >
      <IssueDate createdAt={createdAt} />
      <IconButton size="small" onClick={handleClickRead}>
        {read ? (
          <MarkChatReadIcon fontSize="inherit" />
        ) : (
          <MarkChatUnreadIcon fontSize="inherit" />
        )}
      </IconButton>
    </div>
  );
};

export default NewIssueAction;
