import { useCallback, useEffect } from 'react';

import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';

import useMoveByJobId from 'src/hooks/currentJobId/useMoveByJobId';
import useConfirmModal from 'src/hooks/useConfirmModal';
import authState from 'src/states/auth';
import enqueuedJobState from 'src/states/enqueuedJob';
import { jobState } from 'src/states/job';
import jobIdListState from 'src/states/jobIdList';
import jobListState from 'src/states/jobList';
import { projectState } from 'src/states/project';
import { thisCPCWindow, isCPCPriorCaseWindow } from 'src/utils/cpc';

export const CPCBroadcastChannel = new BroadcastChannel('CPC');

const requestCPCWindowNameForDuplicateCheck = (): void => {
  CPCBroadcastChannel.postMessage({
    type: 'requestStateUpdateFromOtherCPCCase',
    origin: thisCPCWindow.name,
    payload: 'checkForDuplicateCPCWindow',
  });
};

type SyncMessageOriginType = 'indexCase' | 'priorCase';

type ID = string;
type SyncMessageType =
  | { type: 'jobChange'; origin: SyncMessageOriginType; payload: ID }
  | { type: 'projectChange'; origin: SyncMessageOriginType; payload: ID }
  | { type: 'annotatorChange'; origin: SyncMessageOriginType; payload: ID }
  | {
      type: 'otherWindowIsAnnotating';
      origin: SyncMessageOriginType;
      payload: boolean;
    }
  | { type: 'closedOtherWindow'; origin: SyncMessageOriginType }
  | { type: 'checkForDuplicateCPCWindow'; origin: SyncMessageOriginType }
  | { type: 'logout'; origin: SyncMessageOriginType }
  | {
      type: 'requestStateUpdateFromOtherCPCCase';
      origin: SyncMessageOriginType;
      payload: 'isAnnotating' | 'checkForDuplicateCPCWindow';
    };

const useSyncCPCState = (): void => {
  const currentProject = useRecoilValue(projectState.current);
  const isCPCProject = useRecoilValue(projectState.isCPC);
  const setProjectId = useSetRecoilState(projectState.currentId);
  const currentJobId = useRecoilValue(jobIdListState.currentJobId);
  const setPreventCPCDoubleEnqueue = useSetRecoilState(
    enqueuedJobState.preventCPCDoubleEnqueue
  );
  const isAnnotating = useRecoilValue(jobState.isAnnotating);
  const pairedJobList = useRecoilValue(jobListState.pairedJobs);
  const [currentAssociateId, setCurrentAssociateId] = useRecoilState(
    projectState.currentAssociateId
  );
  const moveByJobId = useMoveByJobId();
  const setIsOtherCPCWindowAnnotating = useSetRecoilState(
    jobState.isOtherCPCWindowAnnotating
  );
  const auth = useRecoilValue(authState.me);
  const { getConfirmation } = useConfirmModal();

  // Prevents losing unsaved progress if navigate by URL or close tab
  useEffect(() => {
    if (isAnnotating) window.onbeforeunload = () => true;
    return () => {
      window.onbeforeunload = null;
    };
  }, [isAnnotating]);

  if (isCPCProject) {
    // Sync both isAnnotating states when focused on the window
    window.onfocus = () => {
      CPCBroadcastChannel.postMessage({
        type: 'requestStateUpdateFromOtherCPCCase',
        origin: thisCPCWindow.name,
        payload: 'isAnnotating',
      });
      if (isAnnotating) {
        CPCBroadcastChannel.postMessage({
          type: 'otherWindowIsAnnotating',
          origin: thisCPCWindow.name,
          payload: isAnnotating,
        });
      }
    };

    // Last event fired before page is closed. Ensures isOtherCPCWindowAnnotating is always `false` if window is closed
    window.onpagehide = () => {
      CPCBroadcastChannel.postMessage({
        type: 'closedOtherWindow',
        origin: thisCPCWindow.name,
      });
    };
  }

  useEffect(() => {
    if (!currentJobId || !isCPCProject) return;
    let payload: string | undefined;

    if (isCPCPriorCaseWindow) {
      const pairedPriorJob = pairedJobList.find(
        pj => pj?.prior?.id === currentJobId
      );
      payload = pairedPriorJob?.index.id;
    } else {
      const pairedIndexJob = pairedJobList.find(
        pj => pj.index.id === currentJobId
      );
      payload = pairedIndexJob?.prior?.id;
    }
    // make sure payload is not undefined, especially at initial load
    if (payload) {
      CPCBroadcastChannel.postMessage({
        type: 'jobChange',
        origin: thisCPCWindow.name,
        payload,
      });
    }
  }, [currentJobId, isCPCProject, pairedJobList]);

  useEffect(() => {
    if (!currentProject.id) return;
    CPCBroadcastChannel.postMessage({
      type: 'projectChange',
      origin: thisCPCWindow.name,
      payload: currentProject.id,
    });
  }, [currentProject.id]);

  useEffect(() => {
    if (!currentAssociateId || !isCPCProject) return;
    CPCBroadcastChannel.postMessage({
      type: 'annotatorChange',
      origin: thisCPCWindow.name,
      payload: currentAssociateId,
    });
  }, [currentAssociateId, isCPCProject]);

  const updateIsAnnotatingState = useCallback(() => {
    if (!isCPCProject) return;
    CPCBroadcastChannel.postMessage({
      type: 'otherWindowIsAnnotating',
      origin: thisCPCWindow.name,
      payload: isAnnotating,
    });
  }, [isAnnotating, isCPCProject]);

  updateIsAnnotatingState();

  if (isCPCProject) requestCPCWindowNameForDuplicateCheck();

  useEffect(() => {
    CPCBroadcastChannel.onmessage = async (
      event: MessageEvent<SyncMessageType>
    ) => {
      const { type, origin } = event.data;

      if (
        type === 'projectChange' &&
        currentProject.id !== event.data.payload
      ) {
        return setProjectId(event.data.payload);
      }

      if (!isCPCProject) return;

      if (
        type === 'requestStateUpdateFromOtherCPCCase' &&
        event.data.payload === 'checkForDuplicateCPCWindow'
      ) {
        CPCBroadcastChannel.postMessage({
          type: 'checkForDuplicateCPCWindow',
          origin: thisCPCWindow.name,
        });
      }

      if (origin === thisCPCWindow.name) {
        window.focus();
        getConfirmation({
          title: 'DUPLICATE WINDOW',
          description: `${thisCPCWindow.label} is opened in more than 1 tab/window. Please close one of the duplicate tabs/windows.`,
          confirmButtonText: 'OK',
        });
        return;
      }

      if (
        type === 'requestStateUpdateFromOtherCPCCase' &&
        event.data.payload === 'isAnnotating'
      ) {
        return updateIsAnnotatingState();
      }

      if (
        type === 'annotatorChange' &&
        currentAssociateId !== event.data.payload
      ) {
        return setCurrentAssociateId(event.data.payload);
      }

      if (type === 'otherWindowIsAnnotating') {
        return setIsOtherCPCWindowAnnotating(event.data.payload);
      }
      if (type === 'closedOtherWindow') {
        return setIsOtherCPCWindowAnnotating(false);
      }

      if (type === 'jobChange' && currentJobId !== event.data.payload) {
        setPreventCPCDoubleEnqueue(true);
        return moveByJobId(event.data.payload);
      }

      if (type === 'logout' && auth.status === 'SIGNED') {
        window.location.reload();
      }
    };
  }, [
    currentJobId,
    currentProject.id,
    moveByJobId,
    setIsOtherCPCWindowAnnotating,
    setProjectId,
    updateIsAnnotatingState,
    auth.status,
    getConfirmation,
    setPreventCPCDoubleEnqueue,
    currentAssociateId,
    setCurrentAssociateId,
    isCPCProject,
  ]);
};

export default useSyncCPCState;
