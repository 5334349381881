import { Dispatch, SetStateAction } from 'react';

import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import Save from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { useSaveJob } from 'src/hooks/job/useSaveJob';
import { jobState } from 'src/states/job';
import { projectState } from 'src/states/project';

interface Props {
  setEditIndexMode: Dispatch<SetStateAction<boolean>>;
}

const SaveJobButton = ({ setEditIndexMode }: Props): JSX.Element => {
  const saveJob = useSaveJob();
  const canSaveJobLoadable = useRecoilValueLoadable(jobState.canSave);
  const canSaveJob =
    canSaveJobLoadable.state === 'hasValue' && canSaveJobLoadable.contents;
  const isProjectConfirmed = useRecoilValue(projectState.isConfirmed);

  const saveChanges = async () => {
    const result = await saveJob();
    if (!result) return;
    setEditIndexMode(false); // fixes RCTL-261
  };

  return (
    <Tooltip
      title={
        isProjectConfirmed ? 'Cannot save jobs in confirmed projects' : 'Save'
      }
    >
      <span>
        <IconButton
          onClick={saveChanges}
          disabled={!canSaveJob || isProjectConfirmed}
          size="large"
          data-test-id="btn-save-job"
        >
          <Save />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default SaveJobButton;
