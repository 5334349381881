import {
  atom,
  selector,
  selectorFamily,
  waitForAll,
  waitForNone,
} from 'recoil';

import { CornerstoneSingleImage } from '@InsightViewer/image/CornerstoneSingleImage';
import { CornerstoneImage } from '@InsightViewer/image/types';

import { ClientError, ClientErrorCode } from 'src/http/client-error';
import { getSignedURL } from 'src/services/image';
import {
  CURRENT_DBT_FRAME_NEIGHBORS_PRELOADING_QTY,
  NEXT_DBT_JOB_FRAMES_PRELOADING_QTY,
  localStorageEffect,
} from 'src/utils/localStore';
import { isArray, isNotNull } from 'src/utils/typeHelper';

import { jobState } from './job';

const cornerstoneImage = selectorFamily<CornerstoneImage, string>({
  key: 'imageState/cornerstoneImage',
  get: imagePath => async () => {
    const signedUrl = await getSignedURL(imagePath);

    if (!signedUrl) {
      throw new ClientError({
        code: ClientErrorCode.INVALID_IMAGE,
      });
    }

    const cImage = new CornerstoneSingleImage(signedUrl);

    const getImage = () => {
      return new Promise(resolve => {
        if (!cImage) resolve(false);
        cImage.progress.subscribe(loaded => {
          if (loaded === 1) {
            resolve(true);
          }
        });
        cImage.failedImageLoadAttempts.subscribe(failedAttempts => {
          if (failedAttempts > 2) {
            resolve(false);
          }
        });
      });
    };

    const loaded = await getImage();

    if (!loaded) {
      throw new ClientError({
        code: ClientErrorCode.INVALID_IMAGE,
        message: `Failed to load image. Please try again and report job if needed. Image path: ${imagePath}`,
      });
    }

    return cImage;
  },
  dangerouslyAllowMutability: true,
});

const currentJobImagesLoading = selector<void>({
  key: 'imageState/currentJobImagesLoading',
  get: async ({ get }) => {
    const job = get(jobState.current);
    const jobImages = job?.images;

    if (!jobImages) {
      return;
    }

    Object.values(jobImages).map(jobImage => {
      if (isArray(jobImage) || !jobImage.path) {
        return null;
      }
      return get(cornerstoneImage(jobImage.path));
    });
  },
});

// default=0 in local testing and CI testing (to speed up testing, and avoid timeout failure)
const isLocalTestOrCITestEnvironment =
  process.env.REACT_APP_DEPLOYMENT_PHASE?.includes('test');

export const DEFAULT_CURRENT_DBT_FRAME_NEIGHBORS_PRELOADING_QTY =
  isLocalTestOrCITestEnvironment ? 0 : 50;
export const DEFAULT_NEXT_DBT_JOB_FRAMES_PRELOADING_QTY =
  isLocalTestOrCITestEnvironment ? 0 : 50;

const currentDBTFrameNeighborsPreloadingQty = atom({
  key: 'imageState/currentDBTFrameNeighborsPreloadingQty',
  default: Number(
    localStorage.getItem(CURRENT_DBT_FRAME_NEIGHBORS_PRELOADING_QTY) ??
      DEFAULT_CURRENT_DBT_FRAME_NEIGHBORS_PRELOADING_QTY
  ),
  effects: [localStorageEffect(CURRENT_DBT_FRAME_NEIGHBORS_PRELOADING_QTY)],
});

const nextDBTJobFramesPreloadingQty = atom({
  key: 'imageState/nextDBTJobFramesPreloadingQty',
  default: Number(
    localStorage.getItem(NEXT_DBT_JOB_FRAMES_PRELOADING_QTY) ??
      DEFAULT_NEXT_DBT_JOB_FRAMES_PRELOADING_QTY
  ),
  effects: [localStorageEffect(NEXT_DBT_JOB_FRAMES_PRELOADING_QTY)],
});

const nextJobImagesLoading = selector<void>({
  key: 'imageState/nextJobImagesLoading',
  get: async ({ get }) => {
    const nextJob = get(jobState.next);
    const jobImages = nextJob?.images;

    if (!jobImages) {
      return;
    }

    Object.values(jobImages).map(jobImage => {
      if (isArray(jobImage)) {
        return null;
      }

      // All DBT 3D images use jobImage.paths. Others use jobImage.path
      const isDBT3D = isArray(jobImage.paths) && !jobImage.path;
      if (isDBT3D) {
        const nextDBTJobFramesPreloadingQuantity = get(
          imageState.nextDBTJobFramesPreloadingQty
        );
        const slicedPaths = jobImage.paths?.slice(
          0,
          nextDBTJobFramesPreloadingQuantity
        );
        if (!slicedPaths) {
          return null;
        }
        const nextJobImagePaths = get(
          waitForAll(slicedPaths.map(path => cornerstoneImage(path)))
        );
        return nextJobImagePaths;
      }
      // All non-DBT non-3D images use jobImage.path. Only DBT 3D images use jobImage.paths
      if (!isDBT3D && jobImage.path) {
        return get(cornerstoneImage(jobImage.path));
      }

      return null;
    });
  },
});

const imagesPreloading = selectorFamily<void, string[]>({
  key: 'imageState/imagesPreloading',
  get:
    imagePaths =>
    async ({ get }) => {
      const cornerstoneImageLoadable = get(
        waitForNone(imagePaths.map(path => cornerstoneImage(path)))
      );
      cornerstoneImageLoadable
        .filter(({ state }) => state === 'hasValue')
        .map(({ contents }) => contents);
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

type ViewNameType = {
  name: string;
  type?: string;
};

const viewTypes = selector<ViewNameType[]>({
  key: 'imageState/viewTypes',
  get: async ({ get }) => {
    const job = get(jobState.current);
    const jobImages = job?.images;

    const viewNamesAndTypes = Object.entries(jobImages).map(([key, value]) => {
      if (isArray(value)) return null;
      return { name: key, type: value.type };
    });
    return viewNamesAndTypes.filter(isNotNull);
  },
});

/**
 * @description: Since DBT 2D and DBT 3D show the same object from the same views
 * (LCC, RCC, LMLO, RMLO), findings in one view should be visible in both 2D and 3D.
 * For example: An LCC 3D finding should be grouped with an LCC 2D finding, and vice-versa.
 * If the user tries to save a 2D 3D DBT job with ungrouped findings as described above,
 * the app should show a warning/confirmation. In other modalities, ungrouped findings
 * are OK and the app should not show a warning/confirmation.
 */
const isDBTWithBoth2D3DImages = selector({
  key: 'imageState/isDBTWithBoth2D3DImages',
  get: ({ get }) => {
    // without explicit assertion, TypeScript@4.5.2 could not infer the type of localViewTypes
    const localViewTypes = get(imageState.viewTypes) as ViewNameType[];

    const images2D = localViewTypes.some(
      image => !!image.type && image.type === 'single'
    );
    const images3D = localViewTypes.some(
      image => !!image.type && image.type === 'multiple'
    );

    return images2D && images3D;
  },
});

const imageState = Object.freeze({
  cornerstoneImage,
  currentDBTFrameNeighborsPreloadingQty,
  nextDBTJobFramesPreloadingQty,
  imagesPreloading,
  viewTypes,
  currentJobImagesLoading,
  nextJobImagesLoading,
  isDBTWithBoth2D3DImages,
});

export default imageState;
