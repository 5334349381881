import { useMemo, useCallback } from 'react';

import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import useMoveToNextJob from 'src/hooks/currentJobId/useMoveToNextJob';
import useMoveToPrevJob from 'src/hooks/currentJobId/useMoveToPrevJob';
import { useSaveJob } from 'src/hooks/job/useSaveJob';
import useRemoveFinding from 'src/hooks/tasks/useRemoveFinding';
import useResetAnnotations from 'src/hooks/tasks/useResetAnnotations';
import useSetFindingIndex from 'src/hooks/tasks/useSetFindingIndex';
import useSetOperationMode from 'src/hooks/tasks/useSetOperationMode';
import useToggleFindings from 'src/hooks/tasks/useToggleFindings';
import { useLayoutState } from 'src/hooks/useLayoutState';
import useShortcuts from 'src/hooks/useShortcuts';
import { ShortcutDefinition } from 'src/interfaces/shortcut';
import { jobState } from 'src/states/job';
import { operationModeState } from 'src/states/operationMode';
import { taskState } from 'src/states/task';

const useTaskShortcuts = (): void => {
  const setFindingIndex = useSetFindingIndex();
  const resetAnnotations = useResetAnnotations();
  const removeFinding = useRemoveFinding();
  const saveJob = useSaveJob();
  const moveToNextJob = useMoveToNextJob();
  const moveToPrevJob = useMoveToPrevJob();
  const toggleFindings = useToggleFindings();
  const setOperationMode = useSetOperationMode();
  const { toggleFullScreen } = useLayoutState();

  const findingIndex = useRecoilValue(taskState.findingIndex);
  const nextMode = useRecoilValue(operationModeState.next);

  const findingsLoadable = useRecoilValueLoadable(taskState.findings);
  const findings = useMemo(
    () =>
      findingsLoadable.state === 'hasValue' ? findingsLoadable.contents : [],
    [findingsLoadable.contents, findingsLoadable.state]
  );
  const canGoPrevLoadable = useRecoilValueLoadable(jobState.canGoPrev);
  const canGoPrev =
    canGoPrevLoadable.state === 'hasValue' && canGoPrevLoadable.contents;
  const canGoNextLoadable = useRecoilValueLoadable(jobState.canGoNext);
  const canGoNext =
    canGoNextLoadable.state === 'hasValue' && canGoNextLoadable.contents;
  const canSaveLoadable = useRecoilValueLoadable(jobState.canSave);
  const canSave =
    canSaveLoadable.state === 'hasValue' && canSaveLoadable.contents;

  const toggleOperationMode = useCallback(() => {
    setOperationMode(nextMode);
  }, [nextMode, setOperationMode]);

  const shortcuts = useMemo<ShortcutDefinition[]>(
    () => [
      {
        shortcut: 'resetFinding',
        callback: resetAnnotations,
      },
      {
        shortcut: 'toggleMode',
        callback: toggleOperationMode,
      },
      {
        shortcut: 'selectFinding1',
        callback: () => {
          if (findings.find(x => x.index === 1)) setFindingIndex(1);
        },
      },
      {
        shortcut: 'selectFinding2',
        callback: () => {
          if (findings.find(x => x.index === 2)) setFindingIndex(2);
        },
      },
      {
        shortcut: 'selectFinding3',
        callback: () => {
          if (findings.find(x => x.index === 3)) setFindingIndex(3);
        },
      },
      {
        shortcut: 'selectFinding4',
        callback: () => {
          if (findings.find(x => x.index === 4)) setFindingIndex(4);
        },
      },
      {
        shortcut: 'selectFinding5',
        callback: () => {
          if (findings.find(x => x.index === 5)) setFindingIndex(5);
        },
      },
      {
        shortcut: 'selectFinding6',
        callback: () => {
          if (findings.find(x => x.index === 6)) setFindingIndex(6);
        },
      },
      {
        shortcut: 'selectFinding7',
        callback: () => {
          if (findings.find(x => x.index === 7)) setFindingIndex(7);
        },
      },
      {
        shortcut: 'selectFinding8',
        callback: () => {
          if (findings.find(x => x.index === 8)) setFindingIndex(8);
        },
      },
      {
        shortcut: 'selectFinding9',
        callback: () => {
          if (findings.find(x => x.index === 9)) setFindingIndex(9);
        },
      },
      {
        shortcut: 'prev',
        callback: () => {
          if (canGoPrev) moveToPrevJob();
        },
      },
      {
        shortcut: 'next',
        callback: () => {
          if (canGoNext) moveToNextJob();
        },
      },
      {
        shortcut: 'saveData',
        callback: () => {
          if (!canSave) return;
          saveJob();
        },
      },
      {
        shortcut: 'toggleShowFinding',
        callback: toggleFindings(),
      },
      {
        shortcut: 'deleteSelectedFinding',
        callback: () => {
          if (typeof findingIndex === 'undefined') {
            return;
          }
          removeFinding(findingIndex);
        },
      },
      {
        shortcut: 'toggleFullScreen',
        callback: toggleFullScreen,
      },
    ],
    [
      resetAnnotations,
      toggleOperationMode,
      toggleFindings,
      toggleFullScreen,
      findings,
      setFindingIndex,
      canGoPrev,
      moveToPrevJob,
      canGoNext,
      moveToNextJob,
      canSave,
      saveJob,
      findingIndex,
      removeFinding,
    ]
  );

  useShortcuts(shortcuts);
};

export default useTaskShortcuts;
