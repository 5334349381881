import Chip from '@mui/material/Chip';

import { thisCPCWindow } from 'src/utils/cpc';

export function CPCCaseChip(): JSX.Element {
  return (
    <Chip
      label={thisCPCWindow.label}
      size="small"
      sx={{ backgroundColor: thisCPCWindow.color }}
    />
  );
}
