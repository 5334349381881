class ValidationFailure {
  public findingIndex: number;
  public message = '';

  constructor(findingIndex: number, message: string) {
    this.findingIndex = findingIndex;
    this.message = message;
  }
}

export { ValidationFailure };
