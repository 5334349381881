import { useCallback, useMemo } from 'react';

import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';

import useAlert from 'src/hooks/useAlert';
import { jobState } from 'src/states/job';
import { operationModeState } from 'src/states/operationMode';
import { projectState } from 'src/states/project';
import { taskState } from 'src/states/task';

const useRemoveFinding = (): ((index: number) => void) => {
  const { open: openAlert } = useAlert();

  const setFindingIndex = useSetRecoilState(taskState.findingIndex);
  const setIsAnnotating = useSetRecoilState(jobState.isAnnotating);

  const setLocalFindings = useSetRecoilState(taskState.localFindings);

  const findingsLoadable = useRecoilValueLoadable(taskState.findings);
  const findings = useMemo(
    () =>
      findingsLoadable.state === 'hasValue' ? findingsLoadable.contents : [],
    [findingsLoadable.contents, findingsLoadable.state]
  );

  // Some cache for modes
  const operationMode = useRecoilValue(operationModeState.current);
  const isConfirmedProject = useRecoilValue(projectState.isConfirmed);

  const removeFinding = useCallback(
    (targetFindingIndex: number) => {
      if (!operationMode.isEditable) {
        openAlert({
          message: `Finding can't be changed in ${operationMode.text} mode!`,
          type: 'error',
          autoHide: true,
        });
        return;
      }
      if (isConfirmedProject) {
        openAlert({
          message: `This project is confirmed so findings can't be deleted!`,
          type: 'error',
          autoHide: true,
        });
        return;
      }
      setFindingIndex(prev => (prev === targetFindingIndex ? undefined : prev));
      const targetFinding = findings.find(
        ({ index }) => index === targetFindingIndex
      );
      if (targetFinding?.viewOnly) {
        openAlert({
          message: `Read-only finding can't be deleted!`,
          type: 'error',
          autoHide: true,
        });
        return;
      }

      if (!!targetFinding) {
        setLocalFindings(prev => {
          const findingsWithOldIndexes = prev.filter(
            ({ index }) => index !== targetFindingIndex
          );
          const findingsWithConsecutiveIndexes = findingsWithOldIndexes.map(
            (finding, i) => ({
              ...finding,
              index: i + 1,
            })
          );
          return findingsWithConsecutiveIndexes;
        });
      }
      setIsAnnotating(true);
    },
    [
      setFindingIndex,
      setIsAnnotating,
      setLocalFindings,
      findings,
      operationMode.isEditable,
      operationMode.text,
      openAlert,
      isConfirmedProject,
    ]
  );

  return removeFinding;
};

export default useRemoveFinding;
