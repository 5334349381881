import { ChangeEventHandler, useMemo } from 'react';

import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

import AssetPanel from 'src/components/LabelPanel/Asset/AssetPanel';
import { SetLabelFunction } from 'src/hooks/tasks/useSetFindingLabel';
import { Label } from 'src/interfaces';

export interface TexterProps {
  assetName: string;
  assetText: string;
  labels: Label[];
  labelIndex: number;
  isLabelImmutable: boolean;
  onChangeLabel: SetLabelFunction;
  testId?: string;
}

const Texter = ({
  assetName,
  assetText,
  labels,
  labelIndex,
  isLabelImmutable,
  onChangeLabel,
  testId,
}: TexterProps): JSX.Element => {
  const value = useMemo(
    () => labels[labelIndex]?.value || '',
    [labels, labelIndex]
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    onChangeLabel(
      {
        name: assetName,
        value: event.target?.value,
        viewOnly: false,
      },
      labelIndex
    );
  };

  return (
    <AssetPanel assetText={assetText} data-testid={testId}>
      <StyledTextField
        variant="outlined"
        multiline
        rows={4}
        value={value}
        onChange={handleChange}
        disabled={isLabelImmutable}
      />
    </AssetPanel>
  );
};

export default Texter;

const StyledTextField = styled(TextField)({
  padding: '0 2px 2px',
  width: '100%',
});
