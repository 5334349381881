import { isCPCPriorCaseWindow } from 'src/utils/cpc';

import { getPairedJobList } from './job';

export interface AnnotatorJobSchema {
  id: string;
  currentJobId: string;
}

interface GetAnnotatorInitialJobIdProps {
  projectId: string;
  annotatorJob: AnnotatorJobSchema;
  isCPC?: boolean;
}

export const getAnnotatorInitialJobId = async ({
  projectId,
  annotatorJob,
  isCPC,
}: GetAnnotatorInitialJobIdProps): Promise<string> => {
  // we need to setup correct list of jobs for each window based on
  // currentJobId which can be set by both type of jobs: index and prior
  // index jobs should use currentJobId set by index job and vice versa for prior
  // if index window opened when currentJobId was last set by prior then
  // we need to replace it by finding corresponding job from paired job list
  if (isCPC) {
    const pairedList = await getPairedJobList({
      projectId,
      annotatorId: annotatorJob.id,
    });
    const indexJob = pairedList.find(
      pairedJob => pairedJob.index.id === annotatorJob.currentJobId
    );
    const priorJob = pairedList.find(
      pairedJob => pairedJob?.prior?.id === annotatorJob.currentJobId
    );
    // if `project.currentJobId` set by index job
    if (indexJob)
      return isCPCPriorCaseWindow
        ? indexJob?.prior?.id || ''
        : indexJob.index.id;
    // if `project.currentJobId` set by prior job
    if (priorJob)
      return isCPCPriorCaseWindow
        ? priorJob?.prior?.id || ''
        : priorJob.index.id;
  }

  return annotatorJob.currentJobId;
};

export interface ReviewerAssociateJobSchema {
  id: string;
  currentJobId: string;
}

interface GetReviewerInitialJobIdProps {
  projectId: string;
  reviewerAssociateJob: ReviewerAssociateJobSchema;
  associateId: string;
  isCPC?: boolean;
}

export const getReviewerInitialJobId = async ({
  projectId,
  reviewerAssociateJob,
  associateId,
  isCPC,
}: GetReviewerInitialJobIdProps): Promise<string> => {
  if (isCPC) {
    const pairedList = await getPairedJobList({
      projectId,
      annotatorId: associateId,
    });
    const indexJob = pairedList.find(
      pairedJob => pairedJob.index.id === reviewerAssociateJob.currentJobId
    );
    const priorJob = pairedList.find(
      pairedJob => pairedJob?.prior?.id === reviewerAssociateJob.currentJobId
    );

    // if `project.currentJobId` set by index job
    if (indexJob)
      return isCPCPriorCaseWindow
        ? indexJob?.prior?.id || ''
        : indexJob.index.id;
    // if `project.currentJobId` set by prior job
    if (priorJob)
      return isCPCPriorCaseWindow
        ? priorJob?.prior?.id || ''
        : priorJob.index.id;
  }

  return reviewerAssociateJob.currentJobId;
};
