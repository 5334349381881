import { vec2 } from 'cornerstone-core';

import { startPanInteraction } from '@InsightViewer/components/interactions/startPanInteraction';
import { CornerstoneViewerLike } from '@InsightViewer/types';

export const pan =
  ({ element }: { element?: HTMLElement | null } = {}) =>
  ({
    getElement,
    getCurrentViewport,
    getContentWindow,
    updateViewport,
  }: CornerstoneViewerLike): (() => void) => {
    if (!element) element = getElement();

    return startPanInteraction({
      element,
      getCurrentViewport,
      onMove: (translation: vec2) => updateViewport({ translation }),
      onEnd: () => {},
      contentWindow: getContentWindow(),
    });
  };
