import { useRecoilValue, useRecoilCallback } from 'recoil';

import useAlert from 'src/hooks/useAlert';
import useConfirmModal from 'src/hooks/useConfirmModal';
import { jobState } from 'src/states/job';
import { projectState } from 'src/states/project';
import { otherCPCWindow } from 'src/utils/cpc';

type Return = () => Promise<boolean>;

const useDiscardAnnotations = (): Return => {
  const isCPCProject = useRecoilValue(projectState.isCPC);
  const isOtherCPCWindowAnnotating = useRecoilValue(
    jobState.isOtherCPCWindowAnnotating
  );

  const { getConfirmation } = useConfirmModal();
  const { open: openAlert } = useAlert();

  return useRecoilCallback(
    ({ snapshot, reset }) =>
      async () => {
        const isAnnotating = await snapshot.getPromise(jobState.isAnnotating);
        if (!isCPCProject && !isAnnotating) return true;
        if (isCPCProject && !isAnnotating && !isOtherCPCWindowAnnotating)
          return true;

        if (isCPCProject && isOtherCPCWindowAnnotating) {
          openAlert({
            type: 'error',
            message: `${otherCPCWindow.label} window has unsaved annotations! Go to the other window and save or discard them first!`,
          });
          return false;
        }

        const confirmed = await getConfirmation({
          title: 'Annotation in progress',
          description:
            'Are you sure you want to discard all unsaved annotations and move to another job?',
          confirmButtonText: 'Discard',
        });
        // we need reset isAnnotating as we discard it, otherwise it does not
        // allow to move to other job in other window (in CPC project)
        // @humoyun: this CPC feature made codebase brittle and increased complexity unnecessarily
        if (confirmed) {
          reset(jobState.isAnnotating);
        }
        return confirmed;
      },
    [getConfirmation, isCPCProject, isOtherCPCWindowAnnotating, openAlert]
  );
};

export default useDiscardAnnotations;
