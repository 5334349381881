import { CSSProperties } from 'react';

import ReactTimeAgo from 'react-time-ago';

import { styled } from '@mui/material/styles';

interface Props {
  createdAt: string;
  style?: CSSProperties;
}

const IssueDate = ({ createdAt, style }: Props): JSX.Element => {
  return (
    <Container style={style}>
      <ReactTimeAgo
        date={Date.parse(createdAt)}
        timeStyle="round"
        locale="en"
      />
    </Container>
  );
};

export default IssueDate;

const Container = styled('div')`
  color: rgba(255, 255, 255, 0.5);
`;
