import { atom, selector, waitForAll } from 'recoil';

import { enqueueSingleJob } from 'src/services/job';

import initialJobIdState from './initialJobId';
import jobIdListState from './jobIdList';
import { projectState } from './project';

const needFocus = selector({
  key: 'enqueuedJob/needFocus',
  get: ({ get }) => {
    const initialAnnotatorJobId = get(initialJobIdState.annotator);
    return initialAnnotatorJobId === null;
  },
});

/**
 * This state is used to prevent enqueueing job in CPC projects as in this type of projects
 * there is a pair of jobs (index and prior) and when one is enqueued the other is also
 * enqueued by backend automatically, so we need to prevent this behavior in frontend so that both
 * we end up with double enqueued jobs in both windows (4 enqueued jobs in total)
 *
 * I hate this but it's the only way to prevent this behavior, and our system works in this way
 * this is not standard way of coding, but this is reality of our system
 */
const preventCPCDoubleEnqueue = atom({
  key: 'enqueuedJob/preventCPCDoubleEnqueue',
  default: false,
});

const current = selector({
  key: 'enqueuedJob/current',
  get: async ({ get }) => {
    const { project } = get(
      waitForAll({
        project: projectState.current,
        currentAnnotatorId: projectState.currentAnnotatorId,
      })
    );
    if (project.isReviewer) {
      return null;
    }
    const isLastJobIndex = get(jobIdListState.isLastJobIndex);
    if (!isLastJobIndex) {
      return null;
    }
    const preventCPCDoubleEnqueue = get(
      enqueuedJobState.preventCPCDoubleEnqueue
    );
    if (preventCPCDoubleEnqueue) {
      return null;
    }
    const needFocusState = get(needFocus);
    const job = await enqueueSingleJob({
      projectId: project.id,
      setFocus: needFocusState,
    });

    return job.id;
  },
  /**
   * I don't know why but without this,
   * it will occurring infinite calls of the useEffect which is using this state.
   */
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

const enqueuedJobState = {
  current,
  needFocus,
  preventCPCDoubleEnqueue,
};

export default enqueuedJobState;
