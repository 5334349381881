import { ReactNode, useCallback } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { LocalFinding, Label } from 'src/interfaces';
import { errorFindingIdxState } from 'src/states/finding';
import { errorLabelNameState } from 'src/states/label';
import { projectState } from 'src/states/project';
import { ValidationFailure } from 'src/utils/AssetValidator/errors';
import AssetUtils from 'src/utils/asset';
import { overrideClaim, findInvalidSelector } from 'src/utils/claim';

import useSetFindingIndex from './tasks/useSetFindingIndex';
export interface JobProviderProps {
  children: ReactNode;
}

export function useValidateFinding(): (
  labels: Label[]
) => (finding: LocalFinding) => void {
  const project = useRecoilValue(projectState.current);
  const setFindingIndex = useSetFindingIndex();

  const setErrorLabel = useSetRecoilState(errorLabelNameState);
  const setErrorFindingIdx = useSetRecoilState(errorFindingIdxState);

  const validateFinding = useCallback(
    (labels: Label[]) => (finding: LocalFinding) => {
      const currentLabels = labels.filter(
        label => label.index === finding.index || label.index === undefined
      );
      const claim = overrideClaim(project.claim, currentLabels);
      const assets = claim.assets.filter(x => x.group.endsWith(finding.shape));
      assets.forEach(asset => {
        const currentLabel = currentLabels.find(
          label => label.name === asset.name
        );

        const isParentSelected = AssetUtils.isParentLabelSelected(
          asset,
          currentLabels
        );

        const invalidAsset = findInvalidSelector(
          asset,
          currentLabel,
          isParentSelected
        );

        if (!!invalidAsset) {
          setFindingIndex(finding.index, false);
          setErrorFindingIdx(finding.index);
          setErrorLabel(asset.name);
          throw new ValidationFailure(
            finding.index,
            `"${invalidAsset.text}" label is not valid.`
          );
        }
      });
    },
    [project.claim, setErrorFindingIdx, setErrorLabel, setFindingIndex]
  );

  return validateFinding;
}
