import { atom, selector } from 'recoil';

import jobListState from './jobList';
import { taskState } from './task';

// indicates that there is no job left in the project for enqueuing
const isLastJobInProject = atom<boolean>({
  key: 'jobIdList/isLastJobInProject',
  default: false,
});

const currentJobId = atom<string | null>({
  key: 'jobIdList/currentJobId',
  default: null,
});

const prevJobId = selector<string | undefined>({
  key: 'jobIdList/prevJobId',
  get: ({ get }) => {
    const currentIndex = get(currentJobIndex);
    if (currentIndex === null) return undefined;
    const jobIds = get(current);
    return jobIds[currentIndex - 1];
  },
});

const nextJobId = selector<string | undefined>({
  key: 'jobIdList/nextJobId',
  get: ({ get }) => {
    const currentIndex = get(currentJobIndex);
    if (currentIndex === null) return undefined;
    const jobIds = get(current);
    return jobIds[currentIndex + 1];
  },
});

const writableCurrentJobId = selector<string | null>({
  key: 'jobIdList/writableCurrentJobId',
  get: ({ get }) => get(currentJobId),
  set: ({ set, reset, get }, newValue) => {
    // Necessary states to initialize when we change the current job
    reset(isLastJobInProject);
    reset(taskState.findingIndex);
    // Set current job ID
    set(currentJobId, newValue);
  },
});

const current = selector<string[]>({
  key: 'jobIdList/current',
  get: async ({ get }) => {
    const jobs = get(jobListState.current);
    return jobs.map(({ id }) => id);
  },
});

const currentJobIndex = selector({
  key: 'jobIdList/currentJobIndex',
  get: ({ get }) => {
    const currentJobIdState = get(currentJobId);
    const jobIds = get(current);

    const idx = jobIds.findIndex(id => id === currentJobIdState);
    return idx === -1 ? null : idx;
  },
});

const isLastJobIndex = selector<boolean>({
  key: 'jobIdList/isLastJobIndex',
  get: ({ get }) => {
    const currentJobIdState = get(currentJobId);
    const jobIds = get(current);
    const currJobIndex = jobIds.findIndex(id => id === currentJobIdState);

    return currJobIndex === jobIds.length - 1;
  },
});

const jobIdList = Object.freeze({
  current,
  currentJobId: writableCurrentJobId,
  currentJobIndex,
  prevJobId,
  nextJobId,
  isLastJobInProject,
  isLastJobIndex,
});

export default jobIdList;
