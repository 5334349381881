import { ComponentType, PropsWithChildren } from 'react';

import { color as d3color } from 'd3-color';

import { css, styled } from '@mui/material/styles';

import {
  ContourViewerProps,
  ContourViewer,
} from '@InsightViewer/components/ContourViewer';

// TODO: `lesionColors` or `lesions` may not be used and maybe can be deleted
import { lesionColors } from 'src/env';
import { FindingContour } from 'src/interfaces';

const contourStyle = (lesion: string, color: string) => css`
  [data-lesion='${lesion}'] {
    --contour-viewer-color: ${color};
    --contour-viewer-focused-color: ${d3color(color)?.brighter(3).toString() ||
    color};
    --contour-viewer-fill-color: ${color};
  }
`;

export const contourColors = css`
  polygon {
    fill-opacity: 0.2;
  }
  ${(Object.keys(lesionColors) as Array<keyof typeof lesionColors>).map(
    lesion => contourStyle(lesion, lesionColors[lesion])
  )}
`;

const UserContourViewer = styled<
  ComponentType<PropsWithChildren<ContourViewerProps<FindingContour>>>
>(ContourViewer)`
  polygon {
    stroke-width: 4px;
  }
  ${contourColors}
`;

export default UserContourViewer;
