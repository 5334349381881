export interface DataList {
  countPerPage: number;
  countTotalObjects: number;
  countTotalPages: number;
  currentPage: number;
  nextPage?: string;
  previousPage?: string;
}

export const DEFAULT_PAGE_SIZE = 10;
