import { Offset } from 'src/interfaces';
import { IMAGE_PANEL_ID } from 'src/pages/workspace/task/JobImagePanel';

export const DEFAULT_OFFSET: Offset = { x: 0, y: 0 };

export const getDefaultOffset = (panelEl: HTMLDivElement | null): Offset => {
  const imagePanelEl = document.querySelector<HTMLDivElement>(
    `#${IMAGE_PANEL_ID}`
  );

  const newOffset: Offset = (() => {
    if (!imagePanelEl || !panelEl) {
      return DEFAULT_OFFSET;
    }
    const imagePanelOffset = imagePanelEl.getBoundingClientRect();
    const containerOffset = panelEl.getBoundingClientRect();
    return {
      x:
        imagePanelOffset.x +
        imagePanelOffset.width / 2 -
        containerOffset.width / 2,
      y:
        imagePanelOffset.y +
        imagePanelOffset.height / 2 -
        containerOffset.height / 2,
    };
  })();

  return newOffset;
};

export const getOffsetFromFinding = (
  panelEl: HTMLDivElement | null,
  rect: DOMRect
): Offset => {
  const { x, y, width } = rect;

  const parsedY = (() => {
    if (!panelEl) {
      return y;
    }
    const containerOffset = panelEl.getBoundingClientRect();
    return y + containerOffset.height >= window.innerHeight
      ? window.innerHeight - containerOffset.height - 50
      : y;
  })();

  return {
    x: x + width + 10,
    y: parsedY,
  };
};
