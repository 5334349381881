import { ReactNode } from 'react';

import { MutableSnapshot, RecoilRoot } from 'recoil';

import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';

import { GlobalStyle } from './components/GlobalStyle';

/**
 * Eventually, LunitThemeProvider will be removed (RCTL-682)
 * and its content will be merged into this theme
 */
const defaultTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#00a4c8',
      dark: '#2c6a82',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f05543',
      dark: '#60312b',
    },
  },
  typography: {
    fontFamily: 'proximanova,noto_sanslight,sans-serif',
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 13,
          border: '1px solid var(--ctl-background-color-lighter)',
          backgroundColor: 'var(--ctl-background-color-light)',
          backgroundImage:
            'linear-gradient(to right,  var(--ctl-background-color-lighter) 0%, var(--ctl-background-color-lighter) 100%)',
        },
      },
      defaultProps: {
        disableInteractive: true,
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          backgroundColor: '#22222691',
        },
      },
    },
  },
});

type CombinedProviderProps = {
  children?: ReactNode;
  initializeRecoilState?: (mutableSnapshot: MutableSnapshot) => void;
};

export function CombinedProviders({
  children,
  initializeRecoilState,
}: CombinedProviderProps): JSX.Element {
  return (
    <RecoilRoot initializeState={initializeRecoilState}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={defaultTheme}>
          <GlobalStyle />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </RecoilRoot>
  );
}
