import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const WelcomePanel = (): JSX.Element => {
  return (
    <Container>
      <Typography variant="h2">
        <span role="img" aria-label="Congratulation emoji">
          🎉
        </span>
      </Typography>
      <Typography variant="h5">Welcome to the new Project!</Typography>
      <Typography>
        It seems like this is the first time of visiting to this new project.
        Please click below button to start your first annotation.
      </Typography>
      <Button
        variant="contained"
        onClick={() => window.location.reload()}
        fullWidth
      >
        Start the project
      </Button>
    </Container>
  );
};

export default WelcomePanel;

const Container = styled('section')`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  max-width: 25rem;
  min-width: 20rem;
`;
