import { useEffect, useState, useMemo, ChangeEvent } from 'react';

import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Loading from 'src/components/loading';
import StyledDialog from 'src/components/modals/StyledDialog';
import StyledDialogContent from 'src/components/modals/StyledDialogContent';
import useErrorHandler from 'src/hooks/useErrorHandler';
import { Report, ReportListSchema } from 'src/interfaces';
import { getReportList } from 'src/services/reports';
import authState from 'src/states/auth';

import ReportList from './ReportList';
interface Props {
  visibility: boolean;
  onClose: () => void;
}

const DEFAULT_PAGE = 1;

const ReportListModal = ({
  visibility,
  onClose,
}: Props): JSX.Element | null => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useRecoilValue(authState.signedMe);

  const [reports, setReports] = useState<Report[]>([]);
  const [showResolved, setShowResolved] = useState(false);
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [reportList, setReportList] = useState<ReportListSchema>();
  const fetchReportList = useErrorHandler(getReportList);

  const clear = () => {
    setPage(DEFAULT_PAGE);
    setReportList(undefined);
    setReports([]);
    setShowResolved(false);
  };

  useEffect(() => {
    if (!visibility) {
      clear();
      return;
    }

    if (isLoading) {
      return;
    }

    // like HMR, this is for preventing the case when run this script without page changing
    if (reportList && page === reportList.currentPage) {
      return;
    }

    (async () => {
      setIsLoading(true);
      const response = await fetchReportList({
        page,
        userId: user.id,
        resolvedOnly: showResolved ? showResolved : undefined,
        includeResolved: showResolved ? undefined : true,
      });
      setReportList(response);
      setReports(prev => [...prev, ...response.reports]);
      setIsLoading(false);
    })();
  }, [
    visibility,
    page,
    fetchReportList,
    reportList,
    user.id,
    onClose,
    showResolved,
    isLoading,
  ]);

  const handleShowMore = () => setPage(prev => prev + 1);

  const handleReportStatusChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    clear();
    setShowResolved(checked);
  };

  const isShowMoreAvailable = useMemo(() => {
    if (!reportList) {
      return false;
    }
    const { currentPage, countTotalPages } = reportList;
    return currentPage < countTotalPages;
  }, [reportList]);

  return (
    <StyledDialog open={visibility} onClose={onClose} maxHeight={550}>
      <DialogHeader>
        <DialogTitle id="alert-dialog-title">Reported List</DialogTitle>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              value={showResolved}
              onChange={handleReportStatusChange}
            />
          }
          disabled={isLoading}
          label="Only resolved"
        />
      </DialogHeader>

      <StyledDialogContent>
        <ReportListContainer>
          {reports.length ? (
            <ReportList
              reports={reports}
              onShowMore={handleShowMore}
              isReportsLoading={isLoading}
              isShowMoreAvailable={isShowMoreAvailable}
            />
          ) : isLoading ? (
            <Loading flexible={true} />
          ) : (
            <span>No reported items yet.</span>
          )}
        </ReportListContainer>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default ReportListModal;

const ReportListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 480px;
  min-height: 360px;
  padding-bottom: 24px;
`;

const DialogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
