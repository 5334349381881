import { styled } from '@mui/material/styles';

import { IssueUpdate } from 'src/interfaces/issue';
import IssueUtils from 'src/utils/issue';

import IssueDate from './IssueDate';
import { getIssueItemInteractiveStyle, IssueItemProps } from './IssueItem';

type Props = Partial<
  Pick<IssueItemProps, 'actionElement' | 'onClick' | 'activated'>
> &
  Pick<IssueUpdate, 'userName' | 'type'> &
  Partial<Pick<IssueUpdate, 'createdAt'>>;

const DisabledIssueItem = ({
  userName,
  createdAt,
  type,
  actionElement,
  onClick,
  activated,
}: Props): JSX.Element => {
  const message = IssueUtils.getDisabledMessage({ type, userName });

  return (
    <Container
      onClick={onClick}
      $clickable={!!onClick}
      $activated={!!activated}
    >
      {!!message && (
        <div>
          <div>{message} </div>
          {createdAt && (
            <IssueDate createdAt={createdAt} style={{ marginTop: '0.5rem' }} />
          )}
        </div>
      )}
      {actionElement}
    </Container>
  );
};

export default DisabledIssueItem;

type ContainerProps = { $clickable: boolean; $activated: boolean };
const Container = styled('div')<ContainerProps>`
  display: flex;
  padding: 0.85rem;
  justify-content: space-between;
  align-items: center;

  ${({ $clickable, $activated }: ContainerProps) =>
    getIssueItemInteractiveStyle({ $activated, $clickable })}
`;
