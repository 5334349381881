import {
  ChangeEvent,
  FormEvent,
  MutableRefObject,
  useCallback,
  useRef,
  useState,
} from 'react';

import styled from 'styled-components';

import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Footer } from 'src/components/forms/Footer';
import { FormPageContainer } from 'src/components/forms/FormPageContainer';
import LogoIcon from 'src/components/icons/LogoIcon';
import useLogin from 'src/hooks/authentications/useLogin';
import { ClientError } from 'src/http/client-error';

function checkAvailableSubmit(
  id: MutableRefObject<string>,
  password: MutableRefObject<string>
): boolean {
  return id.current.trim().length > 0 && password.current.trim().length > 0;
}

export default function Login(): JSX.Element {
  const login = useLogin();

  const id = useRef<string>('');
  const password = useRef<string>('');
  const [availableSubmit, setAvailableSubmit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (
        id.current.trim().length === 0 ||
        password.current.trim().length === 0
      ) {
        return;
      }

      try {
        setErrorMessage('');
        setIsLoading(true);
        await login(id.current, password.current);
      } catch (error) {
        const err = error as ClientError;
        const message =
          err.originalError?.response.data.detail ||
          err.originalError?.message ||
          err.message;
        setErrorMessage(message);
      } finally {
        setIsLoading(false);
      }
    },
    [login]
  );

  const handleIdChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    // MutableRefObject.current 값을 갱신
    id.current = event.target.value;
    // submit button disabled 처리를 위해 availableSubmit 값을 계산
    setAvailableSubmit(checkAvailableSubmit(id, password));
  }, []);

  const onPasswordChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      password.current = event.target.value;
      setAvailableSubmit(checkAvailableSubmit(id, password));
    },
    []
  );

  return (
    <FormPageContainer>
      <h1>
        <LogoIcon />
      </h1>

      <form onSubmit={handleSubmit}>
        <TextField
          id="username"
          defaultValue={id.current || ''}
          type="text"
          required
          variant="outlined"
          size="small"
          autoFocus
          disabled={isLoading}
          onChange={handleIdChange}
          placeholder="Username"
        />
        <TextField
          id="password"
          defaultValue={password.current || ''}
          type="password"
          required
          variant="outlined"
          size="small"
          disabled={isLoading}
          onChange={onPasswordChange}
          placeholder="Password"
          autoComplete="password"
        />
        <LoadingButton
          type="submit"
          disabled={isLoading || !availableSubmit}
          loading={isLoading}
          variant="contained"
          size="large"
        >
          Login
        </LoadingButton>
      </form>
      <ErrorContainer>
        {errorMessage && (
          <Typography
            variant="subtitle1"
            component="div"
            data-view="error"
            color="secondary"
          >
            {errorMessage}
          </Typography>
        )}
      </ErrorContainer>
      <Footer />
    </FormPageContainer>
  );
}

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 4rem;
`;
