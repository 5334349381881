import styled from 'styled-components';

import ButtonBase from '@mui/material/ButtonBase';

const CollapsedControlButton = styled(ButtonBase)`
  flex: 1;
  box-sizing: content-box;
  background-color: var(--ctl-background-color-lighter);
  color: var(--ctl-color-active);
  border: none;
  border-radius: var(--ctl-border-radius);
  width: 1em;
  height: 1em;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &[aria-selected='true'] {
    background-color: var(--ctl-brand-color);
  }
  &.Mui-disabled {
    opacity: 0.5;
  }
`;

export default CollapsedControlButton;
