import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import AsideItem from 'src/components/aside/AsideItem';
import { jobState } from 'src/states/job';

export function ReportPanel(): JSX.Element {
  const { report } = useRecoilValue(jobState.current);

  return (
    <AsideItem label="Report" isCollapsible>
      <Report>{report}</Report>
    </AsideItem>
  );
}

const Report = styled.div`
  white-space: pre-line;
`;
