import { useCallback } from 'react';

import { Virtuoso } from 'react-virtuoso';
import {
  useRecoilValue_TRANSITION_SUPPORT_UNSTABLE,
  useSetRecoilState,
} from 'recoil';

import { styled } from '@mui/material/styles';

import useMoveByJobId from 'src/hooks/currentJobId/useMoveByJobId';
import { IssueUpdate } from 'src/interfaces/issue';
import issuesState from 'src/states/issues';
import { projectState } from 'src/states/project';

import NewIssueItem from './NewIssueItem';

type Props = {
  onClose: () => void;
};

const NewIssueList = ({ onClose }: Props): JSX.Element => {
  const updates = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(
    issuesState.updatesByProject
  );

  const moveByJobId = useMoveByJobId();
  const setActivatedId = useSetRecoilState(issuesState.activatedId);
  const setActivatedUpdateId = useSetRecoilState(issuesState.activatedUpdateId);
  const setCurrentAssociateId = useSetRecoilState(
    projectState.currentAssociateId
  );

  const handleClick = useCallback(
    (update: IssueUpdate) => async () => {
      onClose();
      update.userId && setCurrentAssociateId(update.userId);
      await moveByJobId(update.jobId);
      setActivatedId(update.issueId);
      setActivatedUpdateId(update.id);
    },
    [
      onClose,
      moveByJobId,
      setActivatedId,
      setActivatedUpdateId,
      setCurrentAssociateId,
    ]
  );

  return (
    <Container>
      {updates.length ? (
        <Virtuoso
          increaseViewportBy={{ top: 0, bottom: 200 }}
          style={{ height: 400 }}
          totalCount={updates.length}
          data={updates}
          itemContent={(index, update) => {
            if (!update) {
              return null;
            }
            return (
              <NewIssueItem
                update={update}
                key={index}
                onClick={handleClick(update)}
              />
            );
          }}
        />
      ) : (
        <EmptyBody>
          <span>There are no new issues.</span>
        </EmptyBody>
      )}
    </Container>
  );
};

const Container = styled('div')`
  min-height: 70px;
`;

const EmptyBody = styled('div')`
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--ctl-background-color-light);
`;

export default NewIssueList;
