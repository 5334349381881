import {
  DetailedHTMLProps,
  ButtonHTMLAttributes,
  ReactNode,
  MouseEventHandler,
  PropsWithChildren,
} from 'react';

import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';

interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  startIcon: ReactNode; //
  selected?: boolean; //
  startButton?: JSX.Element; //
  onClick: MouseEventHandler<HTMLButtonElement>; //
}

const ButtonListItem = ({
  children,
  startIcon,
  selected = false,
  onClick,
  css,
  ref,
  disabled,
  startButton,
  ...liProps
}: PropsWithChildren<Props>): JSX.Element => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation();
    onClick(event);
  };

  return (
    <ListItemContainer>
      {startButton}
      <ListItemButton
        role="row"
        {...liProps}
        onClick={handleClick}
        aria-selected={selected}
        disabled={disabled}
      >
        <ListItemLabel>
          <IconContainer>{startIcon}</IconContainer>
          <ChildrenContainer>{children}</ChildrenContainer>
        </ListItemLabel>
      </ListItemButton>
    </ListItemContainer>
  );
};

export default ButtonListItem;

const ListItemContainer = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 2px;
`;

const ListItemButton = styled(ButtonBase)`
  display: block;
  width: 100%;
  background-color: var(--ctl-background-color-lighter);
  border-radius: var(--ctl-border-radius);
  overflow: hidden;
  cursor: pointer;
  transition: background-color var(--ctl-transition-timing);

  &:hover {
    background-color: var(--ctl-background-color-lightest);
  }

  &.Mui-disabled {
    opacity: 0.5;
  }

  &[aria-selected='true'] {
    background-color: var(--ctl-brand-color);
    &:hover {
      background-color: var(--ctl-brand-color-light);
    }
  }
`;

const ListItemLabel = styled('div')`
  display: flex;
  align-items: center;
`;

const IconContainer = styled('div')`
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  opacity: 0.8;
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ChildrenContainer = styled('div')`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
  gap: 0.5rem;
`;
