import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const GoToNextJobButtonFallback = (): JSX.Element => {
  return (
    <Container>
      <IconButton disabled={true} size="large">
        <ArrowForwardIos />
      </IconButton>
    </Container>
  );
};

export default GoToNextJobButtonFallback;

const Container = styled('div')`
  position: relative;
`;
