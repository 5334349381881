import { useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

interface Props {
  text: string;
}

const CopyToClipboardButton = ({ text }: Props): JSX.Element => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleClick = (): void => {
    navigator.clipboard.writeText(text).then(() => {
      setIsTooltipVisible(true);
    });
  };

  const handleMouseLeave = (): void => {
    setIsTooltipVisible(false);
  };

  return (
    <Tooltip
      title={'Copied to clipboard!'}
      placement="top"
      disableFocusListener={true}
      disableHoverListener={true}
      disableTouchListener={true}
      open={isTooltipVisible}
    >
      <Icon
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onMouseLeave={handleMouseLeave}
        fontSize="inherit"
      />
    </Tooltip>
  );
};

export default CopyToClipboardButton;

const Icon = styled(ContentCopyIcon)`
  outline: none;
  cursor: pointer;
  color: #fffa;

  transition: color var(--ctl-transition-timing);

  &:hover {
    color: #fff;
  }
`;
