import { CSSProperties, MouseEventHandler } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';

interface Props {
  expanded: boolean;
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  style?: CSSProperties;
}

const ExpandButton = ({
  expanded,
  disabled,
  onClick,
  style,
}: Props): JSX.Element => {
  return (
    <Container disabled={disabled} onClick={onClick} style={style}>
      {expanded ? (
        <ExpandLess fontSize="small" />
      ) : (
        <ExpandMore fontSize="small" />
      )}
    </Container>
  );
};

export default ExpandButton;

const Container = styled(ButtonBase)`
  &.Mui-disabled {
    opacity: 0.5;
  }
`;
