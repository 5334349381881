export const lesionColors = {
  atelectasis: '#3366cc',
  calcified_nodule: '#dc3912',
  cardiomegaly: '#ff9900',
  consolidation: '#109618',
  fibrosis: '#990099',
  mediastinal_widening: '#0099c6',
  nodule: '#dd4477',
  pleural_effusion: '#66aa00',
  pneumoperitoneum: '#b82e2e',
  pneumothorax: '#316395',
  //'': '#994499',
  //'': '#22aa99',
  //'': '#aaaa11',
  //'': '#6633cc',
  //'': '#e67300',
  //'': '#8b0707',
  //'': '#651067',
  //'': '#329262',
  //'': '#5574a6',
  //'': '#3b3eac',
};
