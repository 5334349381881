import { FallbackProps } from 'react-error-boundary';

import Button from '@mui/material/Button';

import ErrorPanel from 'src/components/ErrorFallback/ErrorPanel';
import { ClientError, ClientErrorCode } from 'src/http/client-error';

import WelcomePanel from './WelcomePanel';

const ProjectInitializeErrorFallback = ({
  error,
  resetErrorBoundary,
}: FallbackProps): JSX.Element => {
  if (
    error instanceof ClientError &&
    error.code === ClientErrorCode.NO_JOB_ASSIGNED
  ) {
    return <WelcomePanel />;
  }
  let title = 'Initializing the project has been failed.';
  if (error instanceof ClientError) {
    const pairJobErr = error as ClientError;
    if (pairJobErr.code === ClientErrorCode.PAIR_JOB_NOT_MATCHED) {
      title = 'Pair jobs related to this project are not matched.';
    }
  }

  return (
    <ErrorPanel
      title={title}
      subTitle1={error.message}
      subTitle2="Please retry and if it is keep happening after retry, please report this project to administrator."
    >
      <Button
        variant="contained"
        onClick={() => window.location.reload()}
        fullWidth
      >
        Try again
      </Button>
    </ErrorPanel>
  );
};

export default ProjectInitializeErrorFallback;
