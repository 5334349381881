import { useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import { useRecoilStateLoadable, useResetRecoilState } from 'recoil';

import { CPCBroadcastChannel } from 'src/hooks/cpc/useSyncCPCState';
import { logout as logoutAPI } from 'src/services/me';
import authState from 'src/states/auth';
import jobIdListState from 'src/states/jobIdList';
import { thisCPCWindow } from 'src/utils/cpc';

type Return = () => void;

const useLogout = (): Return => {
  const [me, setMe] = useRecoilStateLoadable(authState.me);
  const resetCurrentJobId = useResetRecoilState(jobIdListState.currentJobId);

  const history = useHistory();

  const logout = useCallback(async () => {
    if (me.contents.status !== 'SIGNED') return;
    resetCurrentJobId();
    setMe(logoutAPI());
    history.push('/login');

    CPCBroadcastChannel.postMessage({
      type: 'logout',
      origin: thisCPCWindow.name,
    });
  }, [history, me, resetCurrentJobId, setMe]);

  return logout;
};

export default useLogout;
