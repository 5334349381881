import imageState, {
  DEFAULT_CURRENT_DBT_FRAME_NEIGHBORS_PRELOADING_QTY,
} from 'src/states/image';

import PreloadingQtySettingItem from './PreloadingQtySettingItem';

export function CurrentDBTFrameNeighborsPreloadingQty(): JSX.Element {
  return (
    <PreloadingQtySettingItem
      heading="Current Frame Neighbors"
      description="Load ± this many frames before and after the current frame."
      recoilState={imageState.currentDBTFrameNeighborsPreloadingQty}
      defaultValue={DEFAULT_CURRENT_DBT_FRAME_NEIGHBORS_PRELOADING_QTY}
    ></PreloadingQtySettingItem>
  );
}
