import { useRecoilState, useRecoilValue } from 'recoil';

import { LocalFinding, SelectorLabel, SelectorAsset } from 'src/interfaces';
import { getAliasForFinding } from 'src/services/job';
import { projectState } from 'src/states/project';
import { taskState } from 'src/states/task';

const useSetAliasToFinding = (): ((label: SelectorLabel) => void) => {
  const project = useRecoilValue(projectState.current);
  const [localFindings, setLocalFindings] = useRecoilState(
    taskState.localFindings
  );

  const setAliasToFinding = (label: SelectorLabel) => {
    setLocalFindings(prev => {
      const index = prev.findIndex(finding => finding.index === label.index);

      if (index < 0 || !localFindings[index]) {
        return prev;
      }

      return [
        ...prev.slice(0, index),
        {
          ...(prev[index] as LocalFinding),
          alias: getAliasForFinding(
            project.claim.assets as SelectorAsset[],
            label
          ),
        },
        ...prev.slice(index + 1, prev.length),
      ];
    });
  };

  return setAliasToFinding;
};

export default useSetAliasToFinding;
