import { atom, selector } from 'recoil';

import { OperationMode } from 'src/interfaces';
import { ensure } from 'src/utils/typeHelper';

import { DEFAULT_OPERATION_MODE } from './defaults';
import { projectState } from './project';

const current = atom({
  key: 'operationMode/current',
  // This selector will run only for initialization
  default: selector({
    key: 'operationMode/current/DEFAULT',
    get: ({ get }) => {
      const project = get(projectState.current);
      return project.claim.modes[0] || DEFAULT_OPERATION_MODE;
    },
  }),
});

const getNextMode = (
  allModes: OperationMode[],
  currMode: OperationMode
): OperationMode => {
  const modeIndex = allModes.findIndex(mode => mode.name === currMode.name);
  const nextModeIndex = (modeIndex + 1) % allModes.length;

  return ensure(allModes[nextModeIndex]);
};

const next = selector({
  key: 'operationMode/next',
  get: ({ get }) => {
    const project = get(projectState.current);
    const mode = get(current);
    const nextMode = getNextMode(project.claim.modes, mode);

    return nextMode;
  },
});

const editable = selector({
  key: 'operationMode/editable',
  get: ({ get }) => {
    const project = get(projectState.current);
    return (
      project.claim.modes.find(x => x.isEditable) || DEFAULT_OPERATION_MODE
    );
  },
});

const isCurrentModeEditable = selector({
  key: 'operationMode/isCurrentModeEditable',
  get: ({ get }) => {
    const currentMode = get(current);
    return currentMode.isEditable;
  },
});

export const operationModeState = Object.freeze({
  current,
  next,
  editable,
  isCurrentModeEditable,
});
