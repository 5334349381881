import { useCallback } from 'react';

import { useRecoilState, useSetRecoilState } from 'recoil';

import { taskState } from 'src/states/task';

const useSetFullscreenIndex = (): ((index: number | undefined) => void) => {
  const [fullscreenViewIndex, setFullscreenViewIndex] = useRecoilState(
    taskState.fullscreenIndex
  );
  const setFindingIndex = useSetRecoilState(taskState.findingIndex);

  const setFullscreenIndex = useCallback(
    (index: number | undefined): void => {
      const isFullscreenActivated = fullscreenViewIndex !== undefined;

      setFindingIndex(undefined);

      if (isFullscreenActivated) {
        setFullscreenViewIndex(undefined);
        return;
      }

      setFullscreenViewIndex(index);
    },
    [fullscreenViewIndex, setFindingIndex, setFullscreenViewIndex]
  );

  return setFullscreenIndex;
};

export default useSetFullscreenIndex;
