import { ClaimAssetValidationSchema } from '@lunit-io/radiology-data-interface';

import { HTTP_METHODS } from 'src/http';
import { ClientError, ClientErrorCode } from 'src/http/client-error';
import { Asset } from 'src/interfaces';
import { postLog } from 'src/services/log';

/**
 * If some validation's identifier is not valid, it is usually caused
 * by claim schema itself, but it is hard to debug when it happen.
 *  - validate all of values in identifier of asset validation existed on claim.assets
 *
 * @param assets
 * @param assetValidations
 * @returns
 */
const confirmNoAssetValidationMismatch = (
  assets: Asset[],
  assetValidations: ClaimAssetValidationSchema[]
): void => {
  const targetIdentifiers = assetValidations
    .flatMap(assetV => assetV.oneOf)
    .map(assetIdentifier => assetIdentifier.name);

  const assetMatchers = targetIdentifiers.map(target => ({
    assetIdentifier: target,
    hasMismatch: !assets.map(asset => asset.name).includes(target),
  }));

  const mismatchedAsset = assetMatchers.find(assetM => assetM.hasMismatch);

  if (mismatchedAsset) {
    const message = `There is a mismatch between asset ${mismatchedAsset.assetIdentifier} and its corresponding 'oneOf' validator`;
    postLog({
      level_name: 'DEBUG',
      method: HTTP_METHODS.GET,
      status_code: 200,
      request_body: JSON.stringify({}),
      error_message: message,
    });

    throw new ClientError({
      code: ClientErrorCode.INVALID_PROJECT,
      message,
    });
  }
};

export default confirmNoAssetValidationMismatch;
