import { useCallback, useTransition } from 'react';

import {
  atom,
  selector,
  useRecoilState_TRANSITION_SUPPORT_UNSTABLE,
} from 'recoil';

import { Point } from '@InsightViewer/types';

import { IssueUpdate } from 'src/interfaces/issue';
import { getIssueUpdateList, getIssueList } from 'src/services/issue';
import jobIdListState from 'src/states/jobIdList';
import { isCPCPriorCaseWindow } from 'src/utils/cpc';

import { projectState } from './project';

const updatesRefresh = atom({
  key: 'issues/updatesRefresh',
  default: 0,
});

export function useRefreshUpdates(): () => void {
  const [, setRefreshState] =
    useRecoilState_TRANSITION_SUPPORT_UNSTABLE(updatesRefresh);
  const [, startTransition] = useTransition();

  return useCallback(() => {
    startTransition(() => {
      setRefreshState(v => v + 1);
    });
  }, [setRefreshState]);
}

const updatesByProject = selector<IssueUpdate[]>({
  key: 'issues/updatesByProject',
  get: async ({ get }) => {
    get(updatesRefresh);
    const { id } = get(projectState.current);
    const isCPCProject = get(projectState.isCPC);
    const pairType = isCPCProject
      ? isCPCPriorCaseWindow
        ? 'PairPrior'
        : 'PairIndex'
      : 'Normal';
    const { updates } = await getIssueUpdateList({
      projectId: id,
      pairType,
    });
    return updates;
  },
});

const issuesRefresh = atom({
  key: 'issues/issuesRefresh',
  default: 0,
});

export function useRefreshIssues(): () => void {
  const [, setRefreshState] =
    useRecoilState_TRANSITION_SUPPORT_UNSTABLE(issuesRefresh);
  const [, startTransition] = useTransition();

  return useCallback(() => {
    startTransition(() => {
      setRefreshState(v => v + 1);
    });
  }, [setRefreshState]);
}

const current = selector({
  key: 'issues/current',
  get: async ({ get }) => {
    get(issuesRefresh);
    const currentJobId = get(jobIdListState.currentJobId);
    if (currentJobId === null) return [];
    const issues = await getIssueList(currentJobId);
    return issues.map((issue, idx) => ({
      ...issue,
      idx,
    }));
  },
});

const activatedId = atom<string | undefined>({
  key: 'issues/activatedId',
  default: undefined,
});

const activatedUpdateId = atom<string | undefined>({
  key: 'issues/activatedUpdateId',
  default: undefined,
});

const selected = selector({
  key: 'issues/selected',
  get: ({ get }) => {
    return get(current).find(({ id }) => id === get(activatedId));
  },
});

const pointList = selector({
  key: 'issues/pointList',
  get: ({ get }) => {
    const issues = get(current);
    return issues.map(({ id, location, idx, view }) => ({
      shape: 'point',
      polygon: [location] as [Point],
      id: idx + 1,
      issueId: id,
      view,
    }));
  },
});

type LocalIssue = {
  view: string;
  location: Point;
};

// add view
const localIssuePosition = atom<undefined | LocalIssue>({
  key: 'issues/localIssuePosition',
  default: undefined,
});

const issuesState = Object.freeze({
  current,
  activatedId,
  activatedUpdateId,
  selected,
  pointList,
  updatesByProject,
  localIssuePosition,
});

export default issuesState;
