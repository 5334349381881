import { Suspense } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { useRecoilValue } from 'recoil';

import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import ViewerErrorFallback from 'src/components/viewers/ViewerErrorFallback';
import Progress from 'src/components/viewers/loading/Progress';
import useMoveToNextJob from 'src/hooks/currentJobId/useMoveToNextJob';
import { jobState } from 'src/states/job';
import jobIdList from 'src/states/jobIdList';

import NextImagePreloader from './NextImagePreloader';
import NextJobPreloader from './NextJobPreloader';

interface Props {
  editIndexMode: boolean;
}

const GoToNextJobButton = ({ editIndexMode }: Props): JSX.Element => {
  const moveToNextJob = useMoveToNextJob();

  const canGoNextJob = useRecoilValue(jobState.canGoNext);
  const currentJobId = useRecoilValue(jobIdList.currentJobId);

  return (
    <Tooltip title="Next" disableInteractive>
      <Container>
        <IconButton
          disabled={!canGoNextJob || editIndexMode}
          onClick={moveToNextJob}
          size="large"
          data-test-id="btn-next-job"
        >
          <ArrowForwardIos />
        </IconButton>

        <ProgressWrap>
          <Suspense fallback={<Progress step={'JOB'} />}>
            <NextJobPreloader />
          </Suspense>
          <ErrorBoundary
            FallbackComponent={ViewerErrorFallback}
            resetKeys={[currentJobId]}
            key={currentJobId}
          >
            <Suspense fallback={<Progress step={'IMAGE'} enqueued />}>
              <NextImagePreloader />
            </Suspense>
          </ErrorBoundary>
        </ProgressWrap>
      </Container>
    </Tooltip>
  );
};

export default GoToNextJobButton;

const Container = styled('div')`
  position: relative;
`;

const ProgressWrap = styled('div')`
  position: absolute;
  height: 3px;
  bottom: 4px;
  left: 10px;
  right: 0;
  width: 24px;
  overflow: hidden;
`;
