import { useCallback } from 'react';

import cookie from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { useRecoilRefresher_UNSTABLE, useSetRecoilState } from 'recoil';

import { modalityKey } from 'src/http';
import { ClientError, ClientErrorCode } from 'src/http/client-error';
import { login as loginAPI } from 'src/services/me';
import authState from 'src/states/auth';
import { projectState } from 'src/states/project';

type Return = (id: string, password: string) => Promise<void>;

const useLogin = (): Return => {
  const setMe = useSetRecoilState(authState.me);
  const refreshProjects = useRecoilRefresher_UNSTABLE(projectState.projects);

  const history = useHistory();

  const login = useCallback(
    async (id: string, password: string) => {
      try {
        const me = await loginAPI(id, password);

        if (me.status !== 'SIGNED')
          throw new ClientError({
            code: ClientErrorCode.UNEXPECTED,
          });
        setMe(me);

        refreshProjects();

        if (me.user.permittedDatabases.length === 1) {
          cookie.set(modalityKey, me.user.permittedDatabases[0] || '');
          history.push('./workspace');
        } else {
          history.push('./database', {
            databases: me.user.permittedDatabases,
          });
        }
      } catch (error) {
        throw error;
      }
    },
    [history, refreshProjects, setMe]
  );

  return login;
};

export default useLogin;
