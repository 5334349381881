import { PropsWithChildren } from 'react';

import { Redirect, Route, RouteProps } from 'react-router-dom';

import { getAccessToken } from './http';

const PrivateRoute = ({
  children,
  ...rest
}: PropsWithChildren<RouteProps>): JSX.Element => {
  return (
    <Route
      {...rest}
      render={() => (getAccessToken() ? children : <Redirect to="/login" />)}
    />
  );
};

export default PrivateRoute;
