import { CSSProperties, MouseEventHandler } from 'react';

import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';

interface Props {
  hidden: boolean;
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  style?: CSSProperties;
}

const VisibilityButton = ({
  hidden,
  disabled,
  onClick,
  style,
}: Props): JSX.Element => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onClick(event);
  };
  return (
    <Container disabled={disabled} onClick={handleClick} style={style}>
      {hidden ? (
        <VisibilityOffOutlined fontSize="small" />
      ) : (
        <VisibilityOutlined fontSize="small" />
      )}
    </Container>
  );
};

export default VisibilityButton;

const Container = styled(ButtonBase)`
  padding: 1px;
  border-radius: 3px;
  &.Mui-disabled {
    opacity: 0.5;
  }
  transition: background-color 0.3s ease;
  &:hover {
    background-color: var(--ctl-background-color-lightest);
  }
`;
