import { authApi } from 'src/http';
import { RemoteUser, User } from 'src/interfaces/me';

const mapUser = (user: RemoteUser): User => ({
  ...user,
  id: user.id,
  isAdmin: user.is_admin,
});

export const getMe = async (): Promise<User> => {
  const { data } = await authApi.get<RemoteUser>('me');

  return mapUser(data);
};

export const getUsers = async (): Promise<User[]> => {
  const { data } = await authApi.get<RemoteUser[]>('users');
  return data.map(mapUser);
};
