import { atom } from 'recoil';

import { Control } from 'src/interfaces/ui';

const current = atom<Control>({
  key: 'controlState/current',
  default: 'none',
});

const invert = atom<boolean>({
  key: 'controlState/invert',
  default: false,
});

const flip = atom<boolean>({
  key: 'controlState/flip',
  default: false,
});

const resetTime = atom<number>({
  key: 'controlState/resetTime',
  default: 0,
});

const controlState = Object.freeze({
  current,
  invert,
  flip,
  resetTime,
});

export default controlState;
