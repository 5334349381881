import { HOSTS } from 'src/configs/hosts';
import { dataApi, getAccessToken, HTTP_METHODS } from 'src/http';
import { ClientError, ClientErrorCode } from 'src/http/client-error';
import { Label, LocalFinding } from 'src/interfaces';
import FindingUtils from 'src/utils/finding';

import { postLog } from './log';

type SyncAnnotationProp = {
  jobId: string | null;
  findings: LocalFinding[];
  labels: Label[];
};

/**
 *
 * @param param0
 * @returns
 */
export const syncAnnotation = async ({
  jobId,
  findings,
  labels,
}: SyncAnnotationProp): Promise<void> => {
  if (!jobId) {
    throw new ClientError({
      code: ClientErrorCode.INVALID_JOB_ID,
      message: `Given jobId is invalid : ${jobId}`,
    });
  }

  const annotationRequest: { findings: LocalFinding[]; labels: Label[] } = {
    findings: findings.map(FindingUtils.removeConfirmedField),
    labels,
  };

  const method = HTTP_METHODS.POST;
  const path = `${HOSTS.CTL_DATA_SERVER}/`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessToken()}`,
  };
  const body = JSON.stringify(annotationRequest);

  try {
    await dataApi.post(`jobs/${jobId}/annotation/`, annotationRequest);
  } catch (error) {
    const err = error as ClientError;
    postLog({
      level_name: 'DEBUG',
      method,
      path,
      header: headers,
      status_code: err.originalError?.response?.status,
      request_body: JSON.stringify(body),
      error_message: err.originalError?.message,
    });
    throw error;
  }
};
