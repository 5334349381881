import MUIDialog, { DialogProps } from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

import { TRANSITION_DURATION } from 'src/interfaces';

interface Props extends DialogProps {
  maxHeight?: number;
}

const StyledDialog = ({ maxHeight, ...props }: Props): JSX.Element => {
  return (
    <Dialog
      {...props}
      $maxHeight={maxHeight}
      transitionDuration={TRANSITION_DURATION}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    />
  );
};

const Dialog = styled(MUIDialog)<{ $maxHeight?: number }>(({ $maxHeight }) => ({
  '.MuiPaper-root': {
    maxHeight: $maxHeight ? `${$maxHeight}px` : 'initial',
    backgroundColor: 'var(--ctl-background-color-light)',
    backgroundImage: 'none',
  },
}));

export default StyledDialog;
