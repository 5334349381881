import { useRecoilValue } from 'recoil';

import LabelPanel from 'src/components/LabelPanel';
import ExpandableCategoryLabelPanel from 'src/components/LabelPanel/ExpandableCategoryLabelPanel';
import useSetDecisionLabel from 'src/hooks/tasks/useSetDecisionLabel';
import { taskState } from 'src/states/task';

const DecisionCategoryPanel = (): JSX.Element => {
  const decisionLabels = useRecoilValue(taskState.decisionLabels);
  const decisionCategories = useRecoilValue(taskState.decisionCategories);
  const handleChangeDecisionLabel = useSetDecisionLabel();

  return (
    <>
      {decisionCategories.map(({ text, children, id }) => (
        <ExpandableCategoryLabelPanel
          title={text}
          isCollapsible={true}
          isDraggable={false}
          key={`parent-${id}`}
        >
          <>
            {children.map(({ text, assets, id }) => (
              <LabelPanel
                title={text}
                assets={assets}
                labels={decisionLabels}
                onChangeLabel={handleChangeDecisionLabel}
                isCollapsible={false}
                isDraggable={false}
                key={`child-${id}`}
              ></LabelPanel>
            ))}
          </>
        </ExpandableCategoryLabelPanel>
      ))}
    </>
  );
};

export default DecisionCategoryPanel;
