import { useEffect } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { projectState } from 'src/states/project';
import { taskState } from 'src/states/task';

/**
 * This hook is for when user selected a finding
 * which is placed in another view while fullscreen mode
 * is activated.
 */
const useUpdateFullscreenIndex = (): void => {
  const currentFinding = useRecoilValue(taskState.currentFinding);
  const {
    claim: {
      viewer: { images },
    },
  } = useRecoilValue(projectState.current);
  const [fullscreenIndex, setFullscreenIndex] = useRecoilState(
    taskState.fullscreenIndex
  );
  const isDBTProject = useRecoilValue(projectState.isDBT);

  useEffect(() => {
    const handleFindingChanged = () => {
      if (fullscreenIndex === undefined) {
        return;
      }

      let viewerNames = images.map(({ name }) => name);
      const activeFullscreenViewName = viewerNames[fullscreenIndex];
      if (!!currentFinding?.image && isDBTProject) {
        const isCurrentFinding3D = currentFinding.image.includes('3D');
        viewerNames = viewerNames.filter(name =>
          name.includes(isCurrentFinding3D ? '3D' : '2D')
        );
      }

      if (
        !!currentFinding?.image &&
        activeFullscreenViewName !== currentFinding?.image
      ) {
        const newFullScreenIndex = viewerNames.indexOf(currentFinding?.image);
        setFullscreenIndex(newFullScreenIndex);
      }
    };

    handleFindingChanged();
  }, [
    currentFinding?.image,
    fullscreenIndex,
    images,
    isDBTProject,
    setFullscreenIndex,
  ]);
};

export default useUpdateFullscreenIndex;
