import { nanoid } from 'nanoid';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import useAlert from 'src/hooks/useAlert';
import { LocalFinding } from 'src/interfaces';
import { jobState } from 'src/states/job';
import { operationModeState } from 'src/states/operationMode';
import { projectState } from 'src/states/project';
import { taskState } from 'src/states/task';
import FindingUtils from 'src/utils/finding';

// Helper to choose a next finding index, max existing finding index + 1
const getNextFindingIndex = (findings: { index: number }[]): number => {
  const maxLocalFinding = Math.max(...findings.map(f => f.index), 0);
  return maxLocalFinding + 1;
};

const useAddFinding = (): ((value: LocalFinding) => void) => {
  const { open: openAlert } = useAlert();
  const setFindingIndex = useSetRecoilState(taskState.findingIndex);
  const setIsAnnotating = useSetRecoilState(jobState.isAnnotating);
  const [localFindings, setLocalFindings] = useRecoilState(
    taskState.localFindings
  );
  const {
    claim: { assets },
  } = useRecoilValue(projectState.current);
  const nextFindingIndex = getNextFindingIndex(localFindings);

  // Some cache for modes
  const operationMode = useRecoilValue(operationModeState.current);
  const isConfirmedProject = useRecoilValue(projectState.isConfirmed);

  const addFinding = (isEditable: boolean) => (targetFinding: LocalFinding) => {
    if (isConfirmedProject) {
      openAlert({
        message: `This project is confirmed so findings can't be added!`,
        type: 'error',
        autoHide: true,
      });
      return;
    }

    if (!isEditable) {
      openAlert({
        message: `Finding can't be changed in ${operationMode.text} mode!`,
        type: 'error',
        autoHide: true,
      });
      return;
    }

    setLocalFindings(prev => [
      ...prev,
      {
        ...targetFinding,
        index: nextFindingIndex,
        labels: FindingUtils.getDefaultLabels(targetFinding.shape, assets),
        group: nanoid(5),
      },
    ]);
    setFindingIndex(nextFindingIndex);
    setIsAnnotating(true);
  };

  return addFinding(Boolean(operationMode.isEditable));
};

export default useAddFinding;
